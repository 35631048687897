body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
textarea,
p,
blockquote,
th,
td,
hr,
button,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  margin: 0;
  padding: 0;
}
ul,
ol {
  list-style: none;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
body {
  word-wrap: break-word;
}
/* .content-wrapper > .tween-one-leaving,
.queue-anim-leaving {
  // position: absolute !important;
  // width: 100%;
} */
.video {
  max-width: 800px;
}
#react-content {
  min-height: 100%;
}
.text-wrapper-comp {
  width: 100%;
  height: calc(100vh - 64px);
  position: relative;
  text-align: center;
  overflow: hidden;
  background-size: cover;
  background-position: center;
}
.text-wrapper-comp-page {
  display: inline-block;
  position: absolute;
  top: 34%;
  margin: auto;
  left: 0;
  right: 0;
  font-size: 14px;
  color: #fff;
}
.text-wrapper-comp-page > .queue-anim-leaving {
  position: relative !important;
}
.text-wrapper-comp-page h3 {
  font-size: 52px;
  color: #fff;
}
.text-wrapper-comp-page h1 {
  color: #FFF;
  font-size: 68px;
}
.text-wrapper-comp .text-wrapper-comp-icon {
  bottom: 20px;
  font-size: 24px;
  position: absolute;
  left: 50%;
  margin-left: -12px;
  color: #fff;
}
@media screen and (max-width: 767px) {
  .text-wrapper-comp-page h3 {
    font-size: 32px;
    color: #fff;
  }
  .text-wrapper-comp-page h1 {
    color: #FFF;
    font-size: 40px;
  }
}
