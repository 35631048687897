body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
textarea,
p,
blockquote,
th,
td,
hr,
button,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  margin: 0;
  padding: 0;
}
ul,
ol {
  list-style: none;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
body {
  word-wrap: break-word;
}
/* .content-wrapper > .tween-one-leaving,
.queue-anim-leaving {
  // position: absolute !important;
  // width: 100%;
} */
.video {
  max-width: 800px;
}
#react-content {
  min-height: 100%;
}
.part0 {
  width: 100%;
  height: 100vh;
  position: relative;
  text-align: center;
  border-color: #666;
  background-color: #000;
}
.part0 .part0-text-wrapper {
  display: inline-block;
  position: absolute;
  top: 34%;
  margin: auto;
  left: 0;
  right: 0;
  font-size: 14px;
  color: #fff;
}
.part0 .part0-text-wrapper > .queue-anim-leaving {
  position: relative !important;
}
.part0 .part0-content {
  margin-bottom: 20px;
  word-wrap: break-word;
  min-height: 24px;
  color: #fff;
  background-image: url('https://website-public-resource-for-all-user.oss-cn-shanghai.aliyuncs.com/ai-website/public/color.jpeg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top center;
  background-clip: text;
  -webkit-background-clip: text;
  height: 520px;
}
.part0 .part0-content h1,
.part0 .part0-content h3 {
  color: transparent;
}
.part0 .part0-content h1 {
  font-size: 80px;
}
.part0 .part0-content h3 {
  font-size: 46px;
}
.part0 .part0-icon {
  bottom: 20px;
  font-size: 24px;
  position: absolute;
  left: 50%;
  margin-left: -12px;
  color: #fff;
}
@media screen and (max-width: 1124px) and (min-width: 767px) {
  .part0 .part0-content {
    height: 340px;
  }
  .part0 .part0-content h1 {
    font-size: 50px;
  }
}
@media screen and (max-width: 767px) {
  .part0 {
    background-attachment: inherit;
  }
  .part0 .part0-text-wrapper {
    width: 350px;
  }
  .part0 .part0-title {
    width: 90%;
    left: 0;
  }
  .part0 .part0-content h1 {
    margin-top: 12px;
    font-size: 46px;
    line-height: 1.2;
  }
  .part0 .part0-content h3 {
    font-size: 30px;
  }
}
.beauty-part1 {
  width: 100%;
  height: 782px;
  position: relative;
  text-align: center;
  background: #fff;
  display: flex;
  align-items: center;
  overflow: hidden;
}
.beauty-part1 .home-page {
  height: auto;
}
.beauty-part1 .beauty-part1-page {
  padding: 36px 0 0;
}
.beauty-part1-title,
.beauty-part1-title-sub {
  font-size: 90px;
  display: inline-block;
  line-height: 1.5;
}
.beauty-part1-title-sub {
  margin-left: 18px;
}
.beauty-part1 .color-0 {
  color: #000;
}
.beauty-part1-content {
  font-size: 40px;
  line-height: 20px;
  color: #000;
  margin: 8px auto 16px;
  margin-bottom: 36px;
}
.beauty-part1-a {
  font-size: 22px;
}
.beauty-part1-image,
.beauty-part1-image-mobile {
  width: 100%;
  overflow: hidden;
  margin: 0 auto;
}
@media screen and (max-width: 1124px) {
  .beauty-part1-title,
  .beauty-part1-title-sub {
    font-size: 48px;
  }
  .beauty-part1-title-sub {
    margin-left: 12px;
  }
  .beauty-part1-a {
    font-size: 16px;
  }
  .beauty-part1-content {
    font-size: 32px;
  }
}
@media screen and (max-width: 767px) {
  .beauty-part1 {
    height: auto;
  }
  .beauty-part1 .beauty-part1-page.home-page {
    padding: 64px 12px 48px;
  }
  .beauty-part1-title {
    font-size: 50px;
    line-height: 60px;
    margin-bottom: 10px;
  }
  .beauty-part1-title-sub {
    font-size: 20px;
    line-height: 20px;
    margin-left: 0px;
  }
  .beauty-part1-title,
  .beauty-part1-title-sub {
    display: block;
  }
  .beauty-part1 .color-0 {
    color: #000;
  }
  .beauty-part1-a {
    margin-top: 24px;
    margin-bottom: 24px;
    background-color: #fff;
    font-size: 20px;
    border: none;
    text-align: center;
  }
  .beauty-part1-content {
    font-size: 20px;
    margin-top: 16px;
  }
  .beauty-part1-image {
    width: 100%;
    transform: scale(1.2);
    margin-top: 24px;
  }
}
.beauty-part2-wrapper {
  height: 482px;
  background: #fff;
}
.beauty-part2-wrapper .beauty-part2 {
  width: 100%;
  height: 100%;
  position: relative;
  text-align: center;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5vh 0 5vh 0;
}
.beauty-part2-wrapper .beauty-part2-image {
  background-color: #fafafa;
  width: 55%;
  overflow: hidden;
}
.beauty-part2-wrapper .beauty-part2-text-wrapper {
  width: 360px;
  text-align: center;
}
.beauty-part2-wrapper .beauty-part2-title {
  font-size: 70px;
  color: #000;
}
.beauty-part2-wrapper .beauty-part2-content {
  font-size: 42px;
  font-weight: 500;
  line-height: 30px;
  color: #000;
}
.beauty-part2-wrapper .beauty-part2-intro {
  margin-top: 16px;
  font-size: 24px;
  color: #000;
}
.beauty-part2-wrapper .beauty-part2 a {
  display: block;
  margin-top: 24px;
  margin-bottom: 24px;
  background-color: #fff;
  font-size: 20px;
  border: none;
  text-align: center;
  color: #000;
}
@media screen and (max-width: 767px) {
  .beauty-part2-wrapper {
    height: 500px;
  }
  .beauty-part2-wrapper .beauty-part2 {
    overflow: hidden;
    padding: 12px;
    margin: auto;
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: center;
  }
  .beauty-part2-wrapper .beauty-part2-text-wrapper {
    margin-top: 24px;
    margin-left: 0px;
  }
  .beauty-part2-wrapper .beauty-part2-title {
    font-size: 50px;
    line-height: 60px;
    margin-bottom: 10px;
  }
  .beauty-part2-wrapper .beauty-part2-content {
    font-size: 20px;
    line-height: 20px;
  }
  .beauty-part2-wrapper .beauty-part2-intro {
    font-size: 20px;
  }
  .beauty-part2-wrapper .beauty-part2-image {
    width: 100%;
  }
}
.beauty-part3-wrapper {
  height: 482px;
  background: #fff;
}
.beauty-part3-wrapper .beauty-part3 {
  width: 100%;
  height: 100%;
  position: relative;
  text-align: center;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.beauty-part3-wrapper .beauty-part3-image {
  width: 55%;
  overflow: hidden;
}
.beauty-part3-wrapper .beauty-part3-text-wrapper {
  width: 360px;
  text-align: left;
}
.beauty-part3-wrapper .beauty-part3-title {
  font-size: 70px;
  color: #000;
}
.beauty-part3-wrapper .beauty-part3-content {
  font-size: 42px;
  font-weight: 500;
  line-height: 30px;
  color: #000;
}
.beauty-part3-wrapper .beauty-part3-intro {
  margin-top: 16px;
  font-size: 24px;
  color: #000;
}
.beauty-part3-wrapper .beauty-part3 a {
  display: block;
  margin-top: 24px;
  margin-bottom: 24px;
  background-color: #fff;
  font-size: 20px;
  border: none;
  text-align: center;
  color: #000;
}
@media screen and (max-width: 767px) {
  .beauty-part3-wrapper {
    height: 520px;
  }
  .beauty-part3-wrapper .beauty-part3 {
    overflow: hidden;
    padding: 12px;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .beauty-part3-wrapper .beauty-part3-text-wrapper {
    margin-top: 24px;
    text-align: center;
    margin-left: 0px;
  }
  .beauty-part3-wrapper .beauty-part3-title {
    font-size: 50px;
    line-height: 60px;
    margin-bottom: 10px;
  }
  .beauty-part3-wrapper .beauty-part3-content {
    font-size: 20px;
    line-height: 20px;
  }
  .beauty-part3-wrapper .beauty-part3-intro {
    font-size: 20px;
  }
  .beauty-part3-wrapper .beauty-part3-image {
    width: 100%;
    overflow: hidden;
  }
}
.part4 {
  width: 100%;
  height: 400px;
  position: relative;
  text-align: center;
  border-color: #666;
  background-color: #000;
}
.part4 .part4-text-wrapper {
  display: inline-block;
  position: absolute;
  top: 34%;
  margin: auto;
  left: 0;
  right: 0;
  font-size: 14px;
  color: #fff;
}
.part4 .part4-text-wrapper > .queue-anim-leaving {
  position: relative !important;
}
.part4 .part4-content {
  margin-bottom: 20px;
  word-wrap: break-word;
  min-height: 24px;
  color: #fff;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
  background-clip: text;
  -webkit-background-clip: text;
  height: 350px;
}
.part4 .part4-content h1,
.part4 .part4-content h3 {
  color: transparent;
}
.part4 .part4-content h1 {
  font-size: 50px;
}
.part4 .part4-content h3 {
  font-size: 50px;
}
.part4 .part4-icon {
  bottom: 20px;
  font-size: 24px;
  position: absolute;
  left: 50%;
  margin-left: -12px;
  color: #fff;
}
@media screen and (max-width: 1124px) and (min-width: 767px) {
  .part4 .part4-content {
    height: 340px;
  }
  .part4 .part4-content h1 {
    font-size: 50px;
  }
}
@media screen and (max-width: 767px) {
  .part4 {
    height: 260px;
    background-attachment: inherit;
  }
  .part4 .part4-text-wrapper {
    width: 90%;
  }
  .part4 .part4-title {
    width: 90%;
    left: 0;
  }
  .part4 .part4-content {
    height: 200px;
  }
  .part4 .part4-content h1 {
    font-size: 30px;
  }
  .part4 .part4-content h3 {
    font-size: 26px;
  }
}
