body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
textarea,
p,
blockquote,
th,
td,
hr,
button,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  margin: 0;
  padding: 0;
}
ul,
ol {
  list-style: none;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.banner-wrapper-comp {
  width: 100%;
  height: 782px;
  position: relative;
  text-align: center;
  background: #fff;
  display: flex;
  align-items: center;
  overflow: hidden;
}
.banner-wrapper-comp .home-page {
  height: auto;
}
.banner-wrapper-comp .banner-wrapper-comp-page {
  padding: 36px 0 0;
}
.banner-wrapper-comp-title,
.banner-wrapper-comp-title-2 {
  font-size: 90px;
  line-height: 1.5;
  display: inline-block;
}
.banner-wrapper-comp-title-2 {
  margin-left: 18px;
}
.banner-wrapper-comp .color-0 {
  color: #000;
}
.banner-wrapper-comp-content {
  font-size: 40px;
  line-height: 20px;
  color: #000;
  margin: 8px auto 16px;
  margin-bottom: 36px;
}
.banner-wrapper-comp-a {
  font-size: 22px;
}
.banner-wrapper-comp-image,
.banner-wrapper-comp-image-mobile {
  width: 100%;
  overflow: hidden;
  margin: 0 auto;
}
@media screen and (max-width: 1124px) {
  .banner-wrapper-comp-title {
    font-size: 48px;
  }
  .banner-wrapper-comp-a {
    font-size: 16px;
  }
  .banner-wrapper-comp-content {
    font-size: 32px;
  }
}
@media screen and (max-width: 767px) {
  .banner-wrapper-comp {
    height: auto;
  }
  .banner-wrapper-comp .color-0 {
    color: #000 !important;
  }
  .banner-wrapper-comp .banner-wrapper-comp-page.home-page {
    padding: 64px 12px 48px;
  }
  .banner-wrapper-comp-title {
    font-size: 50px;
    line-height: 60px;
    margin-bottom: 10px;
  }
  .banner-wrapper-comp-title,
  .banner-wrapper-comp-title-2 {
    display: block;
  }
  .banner-wrapper-comp-title-2 {
    font-size: 20px;
    line-height: 20px;
    margin-left: 0px;
  }
  .banner-wrapper-comp-a {
    margin-top: 24px;
    margin-bottom: 24px;
    background-color: #fff;
    font-size: 20px;
    border: none;
    text-align: center;
  }
  .banner-wrapper-comp-content {
    font-size: 20px;
    margin-top: 16px;
  }
  .banner-wrapper-comp-image {
    width: 100%;
    margin: 24px 0;
  }
}
