body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
textarea,
p,
blockquote,
th,
td,
hr,
button,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  margin: 0;
  padding: 0;
}
ul,
ol {
  list-style: none;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-popover {
  position: fixed;
}
.ant-popover-title {
  color: rgb(46 46 46);
  font-size: 1rem;
  line-height: 1.5rem;
}
.ant-popover-inner-content {
  color: rgb(46 46 46);
  font-size: 1.125rem;
  line-height: 1.75rem;
  cursor: pointer;
}
