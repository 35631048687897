/* FloatingWindow.css */
.floating-window {
  z-index: 10000;
  position: fixed;
  /*background-color: red;*/
  width: 300px;
  /* 根据需要调整 */
  height: 180px;
  /* 根据需要调整 */
  background-color: white;
  /*border: 1px solid #ccc;*/
  /*box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);*/
  border-radius: 5px;
  display: flex;
  align-items: flex-start;
  /*justify-content: center;*/
  flex-wrap: wrap;
  cursor: pointer;
  /* 鼠标悬停时显示为可点击 */
}
.floating-window-content {
  flex-grow: 1;
  display: flex;
  align-items: center;
}
.close-button {
  position: fixed;
  width: 15px;
  height: 15px;
  border-radius: 5px 0 0 0 ;
  color: #000;
  padding-left: 5px;
  /*background-color: #abafab;*/
  /*margin-left: auto;*/
}
.close-button:hover {
  color: #333;
}
