body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
textarea,
p,
blockquote,
th,
td,
hr,
button,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  margin: 0;
  padding: 0;
}
ul,
ol {
  list-style: none;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.case-wrapper-comp-wrapper {
  overflow: hidden;
  position: relative;
  will-change: transform;
  background-color: #fafafa;
}
.case-wrapper-comp-wrapper .box-shadow-d {
  box-shadow: 0px 10px 10px #cfcfcf;
  padding: 20px 0;
  width: 300px;
  margin: auto;
  height: 278px;
}
.case-wrapper-comp-wrapper .center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.case-wrapper-comp-wrapper .center p {
  font-size: 28px;
  color: #000;
}
.case-wrapper-comp-wrapper .case-wrapper-comp {
  max-width: 1200px;
  margin: auto;
  overflow: hidden;
  height: 100%;
  padding: 64px 24px;
}
.case-wrapper-comp-wrapper .case-wrapper-comp > .title-wrapper {
  margin: 0 auto 48px;
  text-align: center;
}
.case-wrapper-comp-wrapper .case-wrapper-comp .block-wrapper {
  position: relative;
  height: 100%;
  overflow: hidden;
  padding: 0 0;
}
.case-wrapper-comp-wrapper .case-wrapper-comp .block-wrapper .block {
  display: inline-block;
  text-align: center;
  margin-bottom: 48px;
  position: relative;
}
.case-wrapper-comp-wrapper .case-wrapper-comp .block-wrapper .block.queue-anim-leaving {
  position: relative !important;
}
.case-wrapper-comp-wrapper .case-wrapper-comp .block-wrapper .block .inner-title {
  position: absolute;
  top: 90px;
  font-size: 30px;
  font-weight: 500;
  left: 0;
  right: 0;
  color: #fff;
}
.case-wrapper-comp-wrapper .case-wrapper-comp-image,
.case-wrapper-comp-wrapper .case-wrapper-comp-title {
  width: 280px;
  margin: auto;
  line-height: 1.5;
}
.case-wrapper-comp-wrapper .case-wrapper-comp-image {
  position: relative;
  color: #404040;
  background-color: #fff;
  height: 170px;
}
.case-wrapper-comp-wrapper .case-wrapper-comp-image img {
  width: 100%;
  height: 100%;
  opacity: 0.86;
}
.case-wrapper-comp-wrapper .case-wrapper-comp-title {
  font-size: 18px;
  margin: 24px 0 0 0;
  text-align: center;
  padding-left: 16px;
  font-weight: 400;
}
.case-wrapper-comp-wrapper .case-wrapper-comp .title-1 {
  font-size: 40px;
  margin-top: 80px;
}
@media screen and (max-width: 767px) {
  .case-wrapper-comp-wrapper {
    min-height: 742px;
  }
}
