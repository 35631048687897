body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
textarea,
p,
blockquote,
th,
td,
hr,
button,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  margin: 0;
  padding: 0;
}
ul,
ol {
  list-style: none;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
body {
  word-wrap: break-word;
}
/* .content-wrapper > .tween-one-leaving,
.queue-anim-leaving {
  // position: absolute !important;
  // width: 100%;
} */
.video {
  max-width: 800px;
}
#react-content {
  min-height: 100%;
}
.lipstick-part0-wrapper {
  height: 782px;
  margin-top: 64px;
  background: #fff;
}
.lipstick-part0-wrapper .lipstick-part0 {
  width: 100%;
  height: 100%;
  position: relative;
  text-align: center;
  overflow: hidden;
}
.lipstick-part0-wrapper .lipstick-part0 .title-h1,
.lipstick-part0-wrapper .lipstick-part0 .title-h2 {
  display: inline-block;
  line-height: 1.5;
  font-size: 90px;
  color: #ae285c;
}
.lipstick-part0-wrapper .lipstick-part0 .title-h2 {
  margin-left: 18px;
}
.lipstick-part0-wrapper .lipstick-part0 .title-content {
  font-size: 40px;
  color: #000;
}
.lipstick-part0-wrapper .lipstick-part0 button {
  margin-top: 22px;
  background-color: #d21975;
  color: #fff;
}
.lipstick-part0-wrapper .lipstick-part0-image,
.lipstick-part0-wrapper .lipstick-part0-image-mobile {
  width: 100%;
  max-height: 60%;
  max-width: 1200px;
  overflow: hidden;
  margin: auto;
}
.lipstick-part0-wrapper .lipstick-part0-icon {
  bottom: 20px;
  font-size: 24px;
  position: absolute;
  left: 50%;
  margin-left: -12px;
  color: #000;
}
@media screen and (max-width: 1124px) {
  .lipstick-part0-wrapper {
    height: 682px;
  }
  .lipstick-part0-wrapper .lipstick-part0 .title-h1,
  .lipstick-part0-wrapper .lipstick-part0 .title-h2 {
    line-height: 1;
    font-size: 48px;
    margin-bottom: 24px;
  }
  .lipstick-part0-wrapper .lipstick-part0 .title-content {
    font-size: 32px;
  }
  .lipstick-part0-wrapper .lipstick-part0 button {
    font-size: 16px;
  }
}
@media screen and (max-width: 767px) {
  .lipstick-part0-wrapper {
    min-height: 300px;
    height: auto;
    margin-top: 24px;
  }
  .lipstick-part0-wrapper .lipstick-part0 {
    overflow: hidden;
    padding: 24px 12px;
    margin: auto;
  }
  .lipstick-part0-wrapper .lipstick-part0 .title-h1,
  .lipstick-part0-wrapper .lipstick-part0 .title-h2 {
    display: block;
    font-size: 50px;
    line-height: 1;
    margin-bottom: 0;
    color: #ae285c;
  }
  .lipstick-part0-wrapper .lipstick-part0 .title-h2 {
    margin: 12px auto;
    font-size: 24px;
  }
  .lipstick-part0-wrapper .lipstick-part0 .title-content {
    font-size: 1.4em;
    margin-bottom: 28px;
  }
  .lipstick-part0-wrapper .lipstick-part0 button {
    margin-top: 12px;
  }
  .lipstick-part0-wrapper .lipstick-part0-image {
    transform: scale(1.2) !important;
    margin: 24px 0;
  }
}
.lipstick-part1-wrapper {
  width: 100%;
  height: 782px;
  position: relative;
  text-align: center;
  background: #fff;
  overflow: hidden;
  background-image: url("https://website-public-resource-for-all-user.oss-cn-shanghai.aliyuncs.com/ai-website/public/lipstick-img-1.png");
  background-size: cover;
  background-position: center;
}
.lipstick-part1-wrapper .part1-page {
  display: inline-block;
  position: absolute;
  top: 34%;
  margin: auto;
  left: 0;
  right: 0;
  font-size: 14px;
  color: #fff;
}
.lipstick-part1-wrapper .part1-page > .queue-anim-leaving {
  position: relative !important;
}
.lipstick-part1-wrapper .part1-page h3 {
  font-size: 68px;
  color: #fff;
}
.lipstick-part1-wrapper .part1-page h1 {
  color: #FFF;
  font-size: 82px;
}
@media screen and (max-width: 1124px) {
  .lipstick-part1-wrapper .part1-page h3 {
    font-size: 50px;
  }
  .lipstick-part1-wrapper .part1-page h1 {
    font-size: 60px;
  }
}
@media screen and (max-width: 767px) {
  .lipstick-part1-wrapper {
    height: 400px;
  }
  .lipstick-part1-wrapper .part1-page h3 {
    font-size: 24px;
  }
  .lipstick-part1-wrapper .part1-page h1 {
    font-size: 28px;
  }
}
.lipstick-part2-wrapper {
  width: 100%;
  height: 682px;
  position: relative;
  text-align: center;
  background: #000;
  overflow: hidden;
}
.lipstick-part2-wrapper .lipstick-part2 {
  width: 100%;
  position: relative;
  text-align: center;
  overflow: hidden;
  padding: 5vh 0 5vh 0;
}
.lipstick-part2-wrapper .lipstick-part2 > .queue-anim-leaving {
  position: relative !important;
}
.lipstick-part2-wrapper .lipstick-part2 h3 {
  font-size: 40px;
  color: #fff;
}
.lipstick-part2-wrapper .lipstick-part2 h1 {
  color: #FFF;
  font-size: 60px;
}
.lipstick-part2-wrapper .lipstick-part2-imgs {
  display: flex;
}
.lipstick-part2-wrapper .lipstick-part2-image,
.lipstick-part2-wrapper .lipstick-part2-image-mobile {
  width: 100%;
  max-height: 60%;
  max-width: 1500px;
  overflow: hidden;
  margin: auto;
}
@media screen and (max-width: 1124px) {
  .lipstick-part2-wrapper {
    height: auto;
  }
  .lipstick-part2-wrapper .lipstick-part2-imgs {
    flex-wrap: wrap;
    justify-content: center;
  }
  .lipstick-part2-wrapper .lipstick-part2-imgs img {
    max-height: 300px;
  }
}
@media screen and (max-width: 767px) {
  .lipstick-part2-wrapper {
    height: 600px;
  }
  .lipstick-part2-wrapper .lipstick-part2 {
    padding-bottom: 36px;
  }
  .lipstick-part2-wrapper .lipstick-part2-imgs {
    flex-wrap: wrap;
  }
  .lipstick-part2-wrapper .lipstick-part2-imgs img {
    max-height: 200px;
  }
  .lipstick-part2-wrapper .lipstick-part2 h3 {
    font-size: 22px;
    color: #fff;
  }
  .lipstick-part2-wrapper .lipstick-part2 h1 {
    color: #FFF;
    font-size: 50px;
  }
}
.lipstick-part3-wrapper {
  width: 100%;
  height: auto;
  position: relative;
  text-align: center;
  background: #000;
  overflow: hidden;
}
.lipstick-part3-wrapper .lipstick-part3 {
  width: 100%;
  position: relative;
  text-align: center;
  overflow: hidden;
  padding: 5vh 0 0 0;
}
.lipstick-part3-wrapper .lipstick-part3 > .queue-anim-leaving {
  position: relative !important;
}
.lipstick-part3-wrapper .lipstick-part3 h3 {
  font-size: 40px;
  color: #fff;
}
.lipstick-part3-wrapper .lipstick-part3 h1 {
  color: #FFF;
  font-size: 60px;
}
.lipstick-part3-wrapper .lipstick-part3-imgs {
  display: flex;
  margin-top: 36px;
}
.lipstick-part3-wrapper .lipstick-part3-imgs img {
  object-fit: cover;
}
.lipstick-part3-wrapper .lipstick-part3-image,
.lipstick-part3-wrapper .lipstick-part3-image-mobile {
  width: 100%;
  max-height: 60%;
  max-width: 1500px;
  overflow: hidden;
  margin: auto;
}
@media screen and (max-width: 767px) {
  .lipstick-part3-wrapper {
    padding-bottom: 48px;
  }
  .lipstick-part3-wrapper .lipstick-part3 {
    padding-bottom: 36px;
  }
  .lipstick-part3-wrapper .lipstick-part3 h3 {
    font-size: 22px;
    color: #fff;
  }
  .lipstick-part3-wrapper .lipstick-part3 h1 {
    color: #FFF;
    font-size: 30px;
  }
}
.lipstick-part4-wrapper {
  height: 664px;
  background: #fafafa;
}
.lipstick-part4-wrapper .home-page {
  padding: 36px 0;
}
.lipstick-part4-wrapper .lipstick-part4 {
  height: 100%;
  overflow: hidden;
}
.lipstick-part4-wrapper .lipstick-part4 .title-h1 {
  font-size: 54px;
}
.lipstick-part4-wrapper .lipstick-part4 .title-content {
  text-align: center;
  font-size: 38px;
}
.lipstick-part4-wrapper .lipstick-part4-block-wrapper {
  position: relative;
}
.lipstick-part4-wrapper .lipstick-part4-block-wrapper .lipstick-part4-block {
  display: inline-block;
  padding: 48px 24px;
  vertical-align: top;
}
.lipstick-part4-wrapper .lipstick-part4-block-wrapper .lipstick-part4-block .lipstick-part4-icon {
  display: inline-block;
  width: 15%;
  vertical-align: top;
}
.lipstick-part4-wrapper .lipstick-part4-block-wrapper .lipstick-part4-block .lipstick-part4-text {
  width: 85%;
  display: inline-block;
  padding-left: 8%;
}
.lipstick-part4-wrapper .lipstick-part4-block-wrapper .lipstick-part4-block.clear-both {
  clear: both;
}
@media screen and (max-width: 767px) {
  .lipstick-part4-wrapper {
    height: auto;
  }
  .lipstick-part4-wrapper .lipstick-part4 {
    padding: 24px 12px;
  }
  .lipstick-part4-wrapper .lipstick-part4 .title-wrapper {
    margin: 0 auto 24px;
  }
  .lipstick-part4-wrapper .lipstick-part4 .title-h1 {
    font-size: 32px;
  }
  .lipstick-part4-wrapper .lipstick-part4 .title-content {
    text-align: center;
    font-size: 24px;
  }
  .lipstick-part4-wrapper .lipstick-part4-block-wrapper {
    margin: 20px auto;
    height: auto;
  }
  .lipstick-part4-wrapper .lipstick-part4-block-wrapper .lipstick-part4-block {
    padding: 24px;
  }
  .lipstick-part4-wrapper .lipstick-part4-block-wrapper .lipstick-part4-block .lipstick-part4-title {
    font-size: 20px;
  }
  .lipstick-part4-wrapper .lipstick-part4-block-wrapper .lipstick-part4-block.queue-anim-leaving {
    position: relative !important;
  }
}
.lipstick-part5-wrapper {
  height: 566px;
  background: #fff;
}
.lipstick-part5-wrapper .home-page {
  padding: 24px;
}
.lipstick-part5-wrapper .lipstick-part5 {
  width: 100%;
  height: 100%;
  position: relative;
  text-align: center;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.lipstick-part5-wrapper .lipstick-part5-image {
  background-color: #fff;
  height: 100%;
  width: 45%;
  overflow: hidden;
}
.lipstick-part5-wrapper .lipstick-part5-image div {
  height: 100%;
  width: 100%;
}
.lipstick-part5-wrapper .lipstick-part5-image div img {
  height: 100%;
  width: 100%;
  object-fit: scale-down;
}
.lipstick-part5-wrapper .lipstick-part5-text-wrapper {
  width: 360px;
  text-align: left;
}
.lipstick-part5-wrapper .lipstick-part5-title {
  font-size: 60px;
  color: #000;
}
.lipstick-part5-wrapper .lipstick-part5-content {
  font-size: 42px;
  font-weight: 500;
  line-height: 30px;
  color: #000;
}
.lipstick-part5-wrapper .lipstick-part5-intro {
  margin-top: 16px;
  font-size: 24px;
  text-align: left;
  color: #000;
}
.lipstick-part5-wrapper .lipstick-part5 a {
  display: block;
  margin-top: 24px;
  margin-bottom: 24px;
  background-color: #fff;
  font-size: 20px;
  border: none;
  text-align: center;
  color: #000;
}
@media screen and (max-width: 767px) {
  .lipstick-part5-wrapper {
    height: auto;
  }
  .lipstick-part5-wrapper .home-page {
    padding: 0;
  }
  .lipstick-part5-wrapper .lipstick-part5 {
    overflow: hidden;
    padding: 24px 12px;
    margin: auto;
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: center;
  }
  .lipstick-part5-wrapper .lipstick-part5-image {
    height: auto;
    padding: 12px 24px;
    width: 100%;
  }
  .lipstick-part5-wrapper .lipstick-part5-text-wrapper {
    margin: 0;
    margin-bottom: 24px;
  }
  .lipstick-part5-wrapper .lipstick-part5-title {
    font-size: 50px;
    line-height: 60px;
    text-align: center;
    margin-bottom: 10px;
  }
  .lipstick-part5-wrapper .lipstick-part5-content {
    font-size: 20px;
    line-height: 20px;
  }
  .lipstick-part5-wrapper .lipstick-part5-intro {
    font-size: 20px;
    text-align: center;
  }
}
.lipstick-part6-wrapper {
  height: 566px;
  background: #fff;
}
.lipstick-part6-wrapper .home-page {
  padding: 24px;
}
.lipstick-part6-wrapper .lipstick-part6 {
  width: 100%;
  height: 100%;
  position: relative;
  text-align: center;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.lipstick-part6-wrapper .lipstick-part6-image {
  background-color: #fff;
  width: 30%;
  height: 100%;
  overflow: hidden;
}
.lipstick-part6-wrapper .lipstick-part6-image div {
  height: 100%;
  width: 100%;
}
.lipstick-part6-wrapper .lipstick-part6-image div img {
  height: 100%;
  width: 100%;
  object-fit: scale-down;
}
.lipstick-part6-wrapper .lipstick-part6-text-wrapper {
  width: 600px;
  text-align: center;
}
.lipstick-part6-wrapper .lipstick-part6-title {
  font-size: 70px;
  color: #000;
}
.lipstick-part6-wrapper .lipstick-part6-content {
  font-size: 42px;
  font-weight: 500;
  line-height: 30px;
  color: #000;
}
.lipstick-part6-wrapper .lipstick-part6-intro {
  margin-top: 16px;
  font-size: 50px;
  text-align: left;
  color: #000;
}
.lipstick-part6-wrapper .lipstick-part6 a {
  display: block;
  margin-top: 24px;
  margin-bottom: 24px;
  background-color: #fff;
  font-size: 20px;
  border: none;
  text-align: center;
  color: #000;
}
@media screen and (max-width: 1124px) {
  .lipstick-part6-wrapper .lipstick-part6-intro {
    font-size: 24px;
  }
}
@media screen and (max-width: 767px) {
  .lipstick-part6-wrapper {
    height: auto;
  }
  .lipstick-part6-wrapper .home-page {
    padding: 0;
  }
  .lipstick-part6-wrapper .lipstick-part6 {
    padding: 24px 12px;
    overflow: hidden;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .lipstick-part6-wrapper .lipstick-part6-image {
    height: auto;
    width: 100%;
    padding: 12px 24px;
  }
  .lipstick-part6-wrapper .lipstick-part6-text-wrapper {
    margin: 0;
    width: 100%;
  }
  .lipstick-part6-wrapper .lipstick-part6-title {
    font-size: 50px;
    line-height: 60px;
    margin-bottom: 10px;
  }
  .lipstick-part6-wrapper .lipstick-part6-content {
    font-size: 20px;
    line-height: 20px;
  }
  .lipstick-part6-wrapper .lipstick-part6-intro {
    font-size: 24px;
    text-align: center;
  }
}
.lipstick-part7-wrapper {
  height: 460px;
  background: #fafafa;
}
.lipstick-part7-wrapper .home-page {
  padding: 0;
}
.lipstick-part7-wrapper .lipstick-part7 > p {
  text-align: center;
}
.lipstick-part7-wrapper .lipstick-part7-title-h1 {
  margin-bottom: 12px;
}
.lipstick-part7-wrapper .lipstick-part7 button {
  background-color: #000;
  color: #fff;
}
.lipstick-part7-wrapper .lipstick-part7-table-name-block {
  text-align: center;
  color: #666;
  width: 100%;
}
.lipstick-part7-wrapper .lipstick-part7-table-name {
  font-size: 24px;
}
.lipstick-part7-wrapper .lipstick-part7-table-money {
  font-size: 16px;
  margin: 8px 0 16px;
}
.lipstick-part7-wrapper .lipstick-part7-table-content {
  text-align: center;
  color: #666;
}
.lipstick-part7-wrapper .lipstick-part7-table-content-name {
  color: #666;
  text-align: center;
}
.lipstick-part7-wrapper.home-page-wrapper .lipstick-part7-title-wrapper {
  margin: 64px auto;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .lipstick-part7-wrapper {
    height: auto;
    background-color: #fff;
  }
  .lipstick-part7-wrapper .lipstick-part7 {
    padding: 24px 12px;
  }
  .lipstick-part7-wrapper .lipstick-part7-title-wrapper {
    margin: auto auto 24px !important;
  }
  .lipstick-part7-wrapper .lipstick-part7-wrapper {
    padding-bottom: 0;
  }
  .lipstick-part7-wrapper .lipstick-part7-table {
    margin-bottom: 24px;
  }
}
.lipstick-part8-wrapper {
  height: 782px;
  background: #fff;
}
.lipstick-part8-wrapper .lipstick-part8 {
  width: 100%;
  height: 100%;
  position: relative;
  text-align: center;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5vh 0 5vh 0;
}
.lipstick-part8-wrapper .lipstick-part8 .title-h1 {
  font-size: 50px;
  color: #000;
}
.lipstick-part8-wrapper .lipstick-part8 .title-content {
  font-size: 24px;
  color: #000;
}
.lipstick-part8-wrapper .lipstick-part8 button {
  margin-top: 24px;
  background-color: #d21975;
  color: #fff;
}
.lipstick-part8-wrapper .lipstick-part8-image,
.lipstick-part8-wrapper .lipstick-part8-image-mobile {
  width: 100%;
  height: 100%;
  max-width: 550px;
  overflow: hidden;
}
.lipstick-part8-wrapper .lipstick-part8-image div,
.lipstick-part8-wrapper .lipstick-part8-image-mobile div {
  height: 100%;
  width: 100%;
}
.lipstick-part8-wrapper .lipstick-part8-image div img,
.lipstick-part8-wrapper .lipstick-part8-image-mobile div img {
  height: 100%;
  width: 100%;
  object-fit: scale-down;
}
.lipstick-part8-wrapper .lipstick-part8-icon {
  bottom: 20px;
  font-size: 24px;
  position: absolute;
  left: 50%;
  margin-left: -12px;
  color: #000;
}
@media screen and (max-width: 1124px) {
  .lipstick-part8-wrapper {
    height: 1282px;
  }
  .lipstick-part8-wrapper .lipstick-part8 {
    flex-wrap: wrap;
  }
  .lipstick-part8-wrapper .lipstick-part8-image,
  .lipstick-part8-wrapper .lipstick-part8 .title-wrapper {
    margin: auto;
  }
  .lipstick-part8-wrapper .lipstick-part8-image {
    height: auto;
  }
}
@media screen and (max-width: 767px) {
  .lipstick-part8-wrapper {
    height: auto;
  }
  .lipstick-part8-wrapper .lipstick-part8 {
    padding: 24px 12px;
    overflow: hidden;
    margin: auto;
    display: flex;
    flex-wrap: wrap-reverse;
  }
  .lipstick-part8-wrapper .lipstick-part8-image {
    height: auto;
    max-width: fit-content;
    width: 100%;
    margin-top: 24px;
  }
  .lipstick-part8-wrapper .lipstick-part8-text-wrapper {
    margin-top: 24px;
    margin-left: 0px;
  }
  .lipstick-part8-wrapper .lipstick-part8 .title-h1 {
    font-size: 32px;
    line-height: 42px;
    color: #000;
  }
  .lipstick-part8-wrapper .lipstick-part8 .content {
    font-size: 20px;
    line-height: 20px;
  }
  .lipstick-part8-wrapper .lipstick-part8-intro {
    font-size: 20px;
  }
}
.lipstick-part9-wrapper {
  overflow: hidden;
  position: relative;
  will-change: transform;
  background-color: #fafafa;
}
.lipstick-part9-wrapper .box-shadow-d {
  box-shadow: 0px 10px 10px #cfcfcf;
  padding: 20px 0;
  width: 300px;
  margin: auto;
  height: 278px;
}
.lipstick-part9-wrapper .center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.lipstick-part9-wrapper .center p {
  font-size: 28px;
  color: #000;
}
.lipstick-part9-wrapper .lipstick-part9 {
  max-width: 1200px;
  margin: auto;
  overflow: hidden;
  height: 100%;
  padding: 64px 24px;
}
.lipstick-part9-wrapper .lipstick-part9 > .title-wrapper {
  margin: 0 auto 48px;
  text-align: center;
}
.lipstick-part9-wrapper .lipstick-part9 .block-wrapper {
  position: relative;
  height: 100%;
  overflow: hidden;
  padding: 0 0;
}
.lipstick-part9-wrapper .lipstick-part9 .block-wrapper .block {
  display: inline-block;
  text-align: center;
  margin-bottom: 48px;
  position: relative;
}
.lipstick-part9-wrapper .lipstick-part9 .block-wrapper .block.queue-anim-leaving {
  position: relative !important;
}
.lipstick-part9-wrapper .lipstick-part9 .block-wrapper .block .inner-title {
  position: absolute;
  top: 90px;
  font-size: 30px;
  font-weight: 500;
  left: 0;
  right: 0;
  color: #fff;
}
.lipstick-part9-wrapper .lipstick-part9-image,
.lipstick-part9-wrapper .lipstick-part9-title {
  width: 280px;
  margin: auto;
  line-height: 1.5;
}
.lipstick-part9-wrapper .lipstick-part9-image {
  position: relative;
  color: #404040;
  background-color: #fff;
}
.lipstick-part9-wrapper .lipstick-part9-image img {
  width: 100%;
  opacity: 0.86;
}
.lipstick-part9-wrapper .lipstick-part9-title {
  font-size: 18px;
  margin: 24px 0 0 0;
  text-align: center;
  padding-left: 16px;
  font-weight: 400;
}
.lipstick-part9-wrapper .lipstick-part9 .title-1 {
  font-size: 40px;
  margin-top: 80px;
}
@media screen and (max-width: 767px) {
  .lipstick-part9-wrapper {
    min-height: 742px;
  }
}
.lipstick-wrapper {
  padding-top: 64px;
}
