body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
textarea,
p,
blockquote,
th,
td,
hr,
button,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  margin: 0;
  padding: 0;
}
ul,
ol {
  list-style: none;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.text-wrapper-comp-wrapper {
  height: 460px;
  background: #fafafa;
}
.text-wrapper-comp-wrapper .home-page {
  padding: 0;
}
.text-wrapper-comp-wrapper .text-wrapper-comp > p {
  text-align: center;
}
.text-wrapper-comp-wrapper .text-wrapper-comp-title-h1 {
  margin-bottom: 12px;
}
.text-wrapper-comp-wrapper .text-wrapper-comp button {
  background-color: #000;
  color: #fff;
}
.text-wrapper-comp-wrapper .text-wrapper-comp-table-name-block {
  text-align: center;
  color: #666;
  width: 100%;
}
.text-wrapper-comp-wrapper .text-wrapper-comp-table-name {
  font-size: 24px;
}
.text-wrapper-comp-wrapper .text-wrapper-comp-table-money {
  font-size: 16px;
  margin: 8px 0 16px;
}
.text-wrapper-comp-wrapper .text-wrapper-comp-table-content {
  text-align: center;
  color: #666;
}
.text-wrapper-comp-wrapper .text-wrapper-comp-table-content-name {
  color: #666;
  text-align: center;
}
.text-wrapper-comp-wrapper.home-page-wrapper .text-wrapper-comp-title-wrapper {
  margin: 64px auto;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .text-wrapper-comp-wrapper {
    height: auto;
    background-color: #fff;
  }
  .text-wrapper-comp-wrapper .text-wrapper-comp {
    padding: 24px 12px;
    padding-bottom: 64px;
  }
  .text-wrapper-comp-wrapper .text-wrapper-comp-title-wrapper {
    margin: auto auto 24px !important;
  }
  .text-wrapper-comp-wrapper .text-wrapper-comp-wrapper {
    padding-bottom: 0;
  }
  .text-wrapper-comp-wrapper .text-wrapper-comp-table {
    margin-bottom: 24px;
  }
}
