body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
textarea,
p,
blockquote,
th,
td,
hr,
button,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  margin: 0;
  padding: 0;
}
ul,
ol {
  list-style: none;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.nuclear-part1-wrapper {
  width: 100%;
  height: 100vh;
  position: relative;
  text-align: center;
  overflow: hidden;
  background-image: url("https://website-public-resource-for-all-user.oss-cn-shanghai.aliyuncs.com/ai-website/public/energy-1.png");
  background-size: cover;
  background-position: center;
}
.nuclear-part1-wrapper .nuclear-part1 {
  width: 100%;
  height: 100%;
  position: relative;
  text-align: center;
  overflow: hidden;
  padding: 5vh 0;
}
.nuclear-part1-wrapper .nuclear-part1 .title {
  margin-top: 5%;
  font-size: 90px;
  color: #fff;
  line-height: 1;
  margin-bottom: 12px;
}
.nuclear-part1-wrapper .nuclear-part1 .sub-title {
  font-size: 60px;
  line-height: 58px;
  color: #fff;
  font-weight: 500;
}
.nuclear-part1-wrapper .nuclear-part1 .title-content {
  font-size: 34px;
  color: #fff;
}
.nuclear-part1-wrapper .nuclear-part1 button {
  margin-top: 16px;
  background-color: #000;
  border: none;
  color: #fff;
}
.nuclear-part1-wrapper .nuclear-part1-image,
.nuclear-part1-wrapper .nuclear-part1-image-mobile {
  width: 100%;
  max-height: 60%;
  max-width: 1200px;
  overflow: hidden;
  margin: auto;
}
.nuclear-part1-wrapper .nuclear-part1-icon {
  bottom: 20px;
  font-size: 24px;
  position: absolute;
  left: 50%;
  margin-left: -12px;
  color: #000;
}
@media screen and (max-width: 1124px) {
  .nuclear-part1-wrapper {
    height: 500px;
  }
  .nuclear-part1-wrapper .nuclear-part1 {
    overflow: hidden;
    width: 90%;
    margin: auto;
  }
  .nuclear-part1-wrapper .nuclear-part1 .title-h1 {
    font-size: 60px;
    line-height: 60px;
    color: #ae285c;
    margin-bottom: 40px;
  }
  .nuclear-part1-wrapper .nuclear-part1 .title-content {
    font-size: 20px;
  }
  .nuclear-part1-wrapper .nuclear-part1 .sub-title {
    font-size: 24px;
    margin-bottom: 16px;
  }
}
@media screen and (max-width: 735px) {
  .nuclear-part1-wrapper .nuclear-part1 .title {
    margin-top: 64px;
    font-size: 60px;
    line-height: 60px;
  }
  .nuclear-part1-wrapper .nuclear-part1 .title-content {
    font-size: 20px;
  }
  .nuclear-part1-wrapper .nuclear-part1 .sub-title {
    font-size: 24px;
    margin-bottom: 16px;
    line-height: 1;
  }
}
.nuclear-part2-wrapper {
  background: #fff;
}
.nuclear-part2-wrapper .title-wrapper {
  margin: 64px auto;
  text-align: center;
}
.nuclear-part2-wrapper .title-wrapper h1 {
  color: #000;
  font-size: 48px;
}
.nuclear-part2-wrapper .nuclear-part2 {
  width: 100%;
  height: 80%;
  max-width: 1200px;
  position: relative;
  text-align: center;
  overflow: hidden;
  display: flex;
  justify-content: center;
  padding: 0 0 10vh 0;
}
.nuclear-part2-wrapper .nuclear-part2-text-wrapper {
  text-align: left;
  padding-left: 48px;
}
.nuclear-part2-wrapper .nuclear-part2-title {
  font-size: 22px;
  color: #000;
}
.nuclear-part2-wrapper .nuclear-part2-title-bottom {
  padding-top: 48px;
}
.nuclear-part2-wrapper .nuclear-part2-content {
  margin-bottom: 24px;
}
.nuclear-part2-wrapper .nuclear-part2-content::before {
  content: '';
  display: inline-block;
  width: 10px;
  height: 10px;
  background: #000;
  border-radius: 50%;
  margin-bottom: 4px;
  margin-right: 6px;
}
.nuclear-part2-wrapper .nuclear-part2-content,
.nuclear-part2-wrapper .nuclear-part2-intro {
  font-size: 20px;
  color: #000;
}
.nuclear-part2-wrapper .nuclear-part2-intro {
  line-height: 36px;
}
.nuclear-part2-wrapper .nuclear-part2-image,
.nuclear-part2-wrapper .nuclear-part2-image-mobile {
  width: 100%;
  max-height: 50%;
  max-width: 800px;
  overflow: hidden;
  margin: auto;
}
@media screen and (max-width: 767px) {
  .nuclear-part2-wrapper {
    min-height: 350px;
    height: auto;
  }
  .nuclear-part2-wrapper .nuclear-part2 {
    overflow: hidden;
    width: 90%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
  }
  .nuclear-part2-wrapper .nuclear-part2-text-wrapper {
    margin: auto;
    margin-top: 48px;
  }
  .nuclear-part2-wrapper .nuclear-part2-title-bottom {
    padding-top: 12px;
  }
}
.nuclear-part3-wrapper {
  width: 100%;
  position: relative;
  text-align: center;
  background: #FFF;
  overflow: hidden;
}
.nuclear-part3-wrapper .nuclear-part3 {
  width: 100%;
  position: relative;
  text-align: center;
  overflow: hidden;
  padding: 5vh 0 5vh 0;
}
.nuclear-part3-wrapper .nuclear-part3 > .queue-anim-leaving {
  position: relative !important;
}
.nuclear-part3-wrapper .nuclear-part3 h1 {
  color: #000;
  font-size: 48px;
}
.nuclear-part3-wrapper .nuclear-part3-image,
.nuclear-part3-wrapper .nuclear-part3-image-mobile {
  width: 100%;
  max-height: 60%;
  max-width: 1500px;
  overflow: hidden;
  margin: auto;
}
@media screen and (max-width: 767px) {
  .nuclear-part3-wrapper {
    height: auto;
  }
  .nuclear-part3-wrapper .nuclear-part3 h1 {
    font-size: 32px;
  }
}
