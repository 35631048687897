body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
textarea,
p,
blockquote,
th,
td,
hr,
button,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  margin: 0;
  padding: 0;
}
ul,
ol {
  list-style: none;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
body {
  word-wrap: break-word;
}
/* .content-wrapper > .tween-one-leaving,
.queue-anim-leaving {
  // position: absolute !important;
  // width: 100%;
} */
.video {
  max-width: 800px;
}
#react-content {
  min-height: 100%;
}
.energy-part3-wrapper {
  height: 482px;
  background: #fff;
}
.energy-part3-wrapper .energy-part3 {
  width: 100%;
  height: 100%;
  position: relative;
  text-align: center;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5vh 0 5vh 0;
}
.energy-part3-wrapper .energy-part3-image {
  background-color: #fff;
  width: 55%;
  overflow: hidden;
  height: 100%;
}
.energy-part3-wrapper .energy-part3-image div,
.energy-part3-wrapper .energy-part3-image img {
  width: 100%;
  height: 100%;
}
.energy-part3-wrapper .energy-part3-text-wrapper {
  width: 420px;
  text-align: center;
}
.energy-part3-wrapper .energy-part3-title {
  font-size: 70px;
  color: #000;
}
.energy-part3-wrapper .energy-part3-content {
  font-size: 32px;
  font-weight: 500;
  line-height: 30px;
  color: #000;
}
.energy-part3-wrapper .energy-part3-intro {
  margin-top: 16px;
  font-size: 24px;
  color: #000;
}
.energy-part3-wrapper .energy-part3 a {
  display: block;
  margin-top: 24px;
  margin-bottom: 24px;
  background-color: #fff;
  font-size: 20px;
  border: none;
  text-align: center;
  color: #000;
}
@media screen and (max-width: 767px) {
  .energy-part3-wrapper {
    height: auto;
  }
  .energy-part3-wrapper .energy-part3 {
    overflow: hidden;
    padding: 0px 12px;
    margin: auto;
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: center;
  }
  .energy-part3-wrapper .energy-part3-text-wrapper {
    margin-top: 24px;
    margin-left: 0px;
  }
  .energy-part3-wrapper .energy-part3-title {
    font-size: 50px;
    line-height: 60px;
    margin-bottom: 10px;
  }
  .energy-part3-wrapper .energy-part3-content {
    font-size: 20px;
    line-height: 20px;
  }
  .energy-part3-wrapper .energy-part3-intro {
    font-size: 20px;
  }
  .energy-part3-wrapper .energy-part3-image {
    width: 100%;
  }
}
.energy-part4-wrapper {
  height: 482px;
  background: #fff;
}
.energy-part4-wrapper .energy-part4 {
  width: 100%;
  height: 100%;
  position: relative;
  text-align: center;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.energy-part4-wrapper .energy-part4-image {
  width: 55%;
  overflow: hidden;
}
.energy-part4-wrapper .energy-part4-text-wrapper {
  width: 360px;
  text-align: left;
}
.energy-part4-wrapper .energy-part4-title {
  font-size: 70px;
  color: #000;
}
.energy-part4-wrapper .energy-part4-content {
  font-size: 42px;
  font-weight: 500;
  line-height: 30px;
  color: #000;
}
.energy-part4-wrapper .energy-part4-intro {
  margin-top: 16px;
  font-size: 24px;
  color: #000;
}
.energy-part4-wrapper .energy-part4 a {
  display: block;
  margin-top: 24px;
  margin-bottom: 24px;
  background-color: #fff;
  font-size: 20px;
  border: none;
  text-align: center;
  color: #000;
}
@media screen and (max-width: 767px) {
  .energy-part4-wrapper {
    height: auto;
  }
  .energy-part4-wrapper .energy-part4 {
    overflow: hidden;
    padding: 24px 12px 64px;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .energy-part4-wrapper .energy-part4-text-wrapper {
    margin-top: 24px;
    text-align: center;
    margin-left: 0px;
  }
  .energy-part4-wrapper .energy-part4-title {
    font-size: 50px;
    line-height: 60px;
    margin-bottom: 10px;
  }
  .energy-part4-wrapper .energy-part4-content {
    font-size: 20px;
    line-height: 20px;
  }
  .energy-part4-wrapper .energy-part4-intro {
    font-size: 20px;
  }
  .energy-part4-wrapper .energy-part4-image {
    width: 100%;
  }
}
.energy-part5-wrapper {
  min-height: 446px;
  overflow: hidden;
}
.energy-part5-wrapper .box-shadow-d {
  box-shadow: 0px 10px 10px #cfcfcf;
  padding: 16px 16px;
  width: 332px;
  margin: auto;
  height: 370px;
}
.energy-part5-wrapper .energy-part5 {
  overflow: hidden;
  height: 100%;
  padding: 64px 24px;
}
.energy-part5-wrapper .energy-part5 > .title-wrapper {
  margin: 0 auto 48px;
}
.energy-part5-wrapper .energy-part5 .block-wrapper {
  position: relative;
  height: 100%;
  overflow: hidden;
  padding: 0 0;
  display: flex;
  justify-content: center;
}
.energy-part5-wrapper .energy-part5 .block-wrapper .block {
  display: inline-block;
  text-align: center;
  margin-bottom: 48px;
  position: relative;
}
.energy-part5-wrapper .energy-part5 .block-wrapper .block.queue-anim-leaving {
  position: relative !important;
}
.energy-part5-wrapper .energy-part5-inner-title {
  top: 70px;
  font-size: 40px;
}
.energy-part5-wrapper .energy-part5-inner-title,
.energy-part5-wrapper .energy-part5-inner-title-1,
.energy-part5-wrapper .energy-part5-inner-title-2 {
  position: absolute;
  font-weight: 500;
  left: 0;
  right: 0;
  color: #fff;
}
.energy-part5-wrapper .energy-part5-inner-title-1 {
  top: 116px;
  font-size: 28px;
}
.energy-part5-wrapper .energy-part5-inner-title-2 {
  padding: 0 32px;
  top: 126px;
  font-size: 16px;
  line-height: 16px;
}
.energy-part5-wrapper .energy-part5-image,
.energy-part5-wrapper .energy-part5-title,
.energy-part5-wrapper .energy-part5-content {
  width: 300px;
  margin: auto;
  line-height: 1.5;
  text-align: center;
}
.energy-part5-wrapper .energy-part5-image {
  position: relative;
  color: #404040;
  background-color: #000;
}
.energy-part5-wrapper .energy-part5-image img {
  width: 100%;
  height: 200px;
  opacity: 0.86;
}
.energy-part5-wrapper .energy-part5-title {
  font-size: 18px;
  margin: 24px 0;
}
.energy-part5-wrapper .energy-part5-content {
  padding: 0 16px;
  font-size: 16px;
  color: #000;
  padding-bottom: 24px;
}
@media screen and (max-width: 767px) {
  .energy-part5-wrapper .block-wrapper {
    display: flex;
    flex-wrap: wrap;
  }
}
.energy-part6-wrapper {
  width: 100%;
  height: 700px;
  position: relative;
  text-align: center;
  overflow: hidden;
  background-size: cover;
  background-position: center;
}
.energy-part6-wrapper .energy-part6-page {
  display: inline-block;
  position: absolute;
  top: 14%;
  margin: auto;
  left: 0;
  right: 0;
  font-size: 14px;
  color: #fff;
}
.energy-part6-wrapper .energy-part6-page > .queue-anim-leaving {
  position: relative !important;
}
.energy-part6-wrapper .energy-part6-page h1 {
  color: #FFF;
  font-size: 60px;
}
@media screen and (max-width: 1124px) {
  .energy-part6-wrapper {
    height: 200px;
  }
  .energy-part6-wrapper .energy-part6-page h1 {
    color: #FFF;
    font-size: 20px;
  }
}
.energy-part7-wrapper {
  width: 100%;
  height: 100vh;
  position: relative;
  text-align: center;
  overflow: hidden;
  background-image: url("https://website-public-resource-for-all-user.oss-cn-shanghai.aliyuncs.com/ai-website/public/energy-1.png");
  background-size: cover;
  background-position: center;
}
.energy-part7-wrapper .energy-part7 {
  width: 100%;
  height: 100%;
  position: relative;
  text-align: center;
  overflow: hidden;
  padding: 5vh 0;
}
.energy-part7-wrapper .energy-part7 .title {
  margin-top: 5%;
  font-size: 60px;
  color: #fff;
  line-height: 60px;
}
.energy-part7-wrapper .energy-part7 .sub-title {
  font-size: 40px;
  color: #fff;
  font-weight: 500;
}
.energy-part7-wrapper .energy-part7 .title-content {
  font-size: 34px;
  color: #fff;
}
.energy-part7-wrapper .energy-part7 button {
  margin-top: 16px;
  background-color: #000;
  border: none;
  color: #fff;
}
.energy-part7-wrapper .energy-part7-image,
.energy-part7-wrapper .energy-part7-image-mobile {
  width: 100%;
  max-height: 60%;
  max-width: 1200px;
  overflow: hidden;
  margin: auto;
}
.energy-part7-wrapper .energy-part7-icon {
  bottom: 20px;
  font-size: 24px;
  position: absolute;
  left: 50%;
  margin-left: -12px;
  color: #000;
}
@media screen and (max-width: 1124px) {
  .energy-part7-wrapper {
    height: 500px;
  }
  .energy-part7-wrapper .energy-part7 {
    overflow: hidden;
    width: 90%;
    margin: auto;
  }
  .energy-part7-wrapper .energy-part7 .title-h1 {
    font-size: 60px;
    line-height: 60px;
    color: #ae285c;
    margin-bottom: 40px;
  }
  .energy-part7-wrapper .energy-part7 .title-content {
    font-size: 20px;
  }
  .energy-part7-wrapper .energy-part7 .sub-title {
    font-size: 24px;
    margin-bottom: 16px;
  }
}
.energy-part8-wrapper {
  background: #fff;
}
.energy-part8-wrapper .title-wrapper {
  margin: 64px auto;
  text-align: center;
}
.energy-part8-wrapper .title-wrapper h1 {
  color: #000;
  font-size: 48px;
}
.energy-part8-wrapper .energy-part8 {
  width: 100%;
  height: 80%;
  max-width: 1200px;
  position: relative;
  text-align: center;
  overflow: hidden;
  display: flex;
  justify-content: center;
  padding: 0 0 10vh 0;
}
.energy-part8-wrapper .energy-part8-text-wrapper {
  text-align: left;
  padding-left: 48px;
}
.energy-part8-wrapper .energy-part8-title {
  font-size: 22px;
  color: #000;
}
.energy-part8-wrapper .energy-part8-title-bottom {
  padding-top: 48px;
}
.energy-part8-wrapper .energy-part8-content {
  margin-bottom: 24px;
}
.energy-part8-wrapper .energy-part8-content::before {
  content: '';
  display: inline-block;
  width: 10px;
  height: 10px;
  background: #000;
  border-radius: 50%;
  margin-bottom: 4px;
  margin-right: 6px;
}
.energy-part8-wrapper .energy-part8-content,
.energy-part8-wrapper .energy-part8-intro {
  font-size: 20px;
  color: #000;
}
.energy-part8-wrapper .energy-part8-intro {
  line-height: 36px;
}
.energy-part8-wrapper .energy-part8-image,
.energy-part8-wrapper .energy-part8-image-mobile {
  width: 100%;
  max-height: 50%;
  max-width: 800px;
  overflow: hidden;
  margin: auto;
}
@media screen and (max-width: 767px) {
  .energy-part8-wrapper {
    min-height: 350px;
  }
  .energy-part8-wrapper .energy-part8 {
    overflow: hidden;
    width: 90%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
  }
  .energy-part8-wrapper .energy-part8-text-wrapper {
    margin: auto;
    margin-top: 48px;
  }
  .energy-part8-wrapper .energy-part8-title-bottom {
    padding-top: 12px;
  }
}
.energy-part9-wrapper {
  width: 100%;
  height: 882px;
  position: relative;
  text-align: center;
  background: #FFF;
  overflow: hidden;
}
.energy-part9-wrapper .energy-part9 {
  width: 100%;
  position: relative;
  text-align: center;
  overflow: hidden;
  padding: 5vh 0 5vh 0;
}
.energy-part9-wrapper .energy-part9 > .queue-anim-leaving {
  position: relative !important;
}
.energy-part9-wrapper .energy-part9 h1 {
  color: #000;
  font-size: 48px;
}
.energy-part9-wrapper .energy-part9-image,
.energy-part9-wrapper .energy-part9-image-mobile {
  width: 100%;
  max-height: 60%;
  max-width: 1500px;
  overflow: hidden;
  margin: auto;
}
@media screen and (max-width: 767px) {
  .energy-part9-wrapper {
    height: 500px;
  }
  .energy-part9-wrapper .energy-part9 h1 {
    font-size: 32px;
  }
}
.energy-wrapper {
  padding-top: 64px;
}
