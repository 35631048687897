body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
textarea,
p,
blockquote,
th,
td,
hr,
button,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  margin: 0;
  padding: 0;
}
ul,
ol {
  list-style: none;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.footer1-wrapper {
  background: #000;
  overflow: hidden;
  position: relative;
  min-height: 360px;
  color: #999;
}
.footer1-wrapper .footer1 .home-page {
  padding: 24px;
  max-width: 1800px;
}
.footer1-wrapper .footer1 .backup-num {
  text-align: center;
  font-size: 16px;
  padding-bottom: 24px;
}
.footer1-wrapper .block {
  padding: 0 32px;
}
.footer1-wrapper .block .logo {
  max-width: 300px;
}
.footer1-wrapper .block .slogan {
  text-align: center;
  font-size: 14px;
  margin-top: -20px;
}
.footer1-wrapper .block .qrcode {
  display: flex;
}
.footer1-wrapper .block .qrcode :first-child {
  margin-right: 24px;
}
.footer1-wrapper .block > h2 {
  margin-bottom: 24px;
  color: #ccc;
}
.footer1-wrapper .block a {
  color: #999;
  margin-bottom: 12px;
  float: left;
  clear: both;
}
.footer1-wrapper .block a:hover {
  color: #1890ff;
}
.footer1-wrapper .copyright-wrapper {
  width: 100%;
  border-top: 1px solid rgba(233, 233, 233, 0.1);
}
.footer1-wrapper .copyright-wrapper .home-page {
  padding: 0 24px;
  overflow: hidden;
}
.footer1-wrapper .copyright-wrapper .copyright {
  height: 40px;
  text-align: center;
  line-height: 40px;
}
@media screen and (max-width: 767px) {
  .footer1 {
    min-height: 550px;
  }
  .footer1-wrapper .footer1 .home-page {
    padding: 64px 24px 32px;
  }
  .footer1 .logo {
    margin: 0 auto 24px;
  }
  .footer1 .block {
    text-align: center;
    margin-bottom: 32px;
    padding: 0;
  }
  .footer1 > ul {
    width: 90%;
    margin: 20px auto 0;
    padding: 10px 0;
  }
  .footer1 > ul > li {
    width: 100%;
  }
  .footer1 > ul > li h2 {
    margin-bottom: 10px;
  }
  .footer1 > ul > li li {
    display: inline-block;
    margin-right: 10px;
  }
  .footer1 .copyright-wrapper .home-page {
    padding: 0;
  }
  .footer1 .copyright-wrapper .home-page .copyright {
    font-size: 12px;
  }
  .footer1 .copyright span {
    width: 90%;
  }
}
