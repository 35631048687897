body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
textarea,
p,
blockquote,
th,
td,
hr,
button,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  margin: 0;
  padding: 0;
}
ul,
ol {
  list-style: none;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
body {
  word-wrap: break-word;
}
.video {
  max-width: 800px;
}
#react-content {
  min-height: 100%;
}
.jetcode-part1-wrapper {
  height: 782px;
  margin-top: 64px;
  background: #fff;
}
.jetcode-part1-wrapper .jetcode-part1 {
  width: 100%;
  height: 100%;
  position: relative;
  text-align: center;
  overflow: hidden;
}
.jetcode-part1-wrapper .jetcode-part1 .title-h1,
.jetcode-part1-wrapper .jetcode-part1 .title-h2 {
  line-height: 1.5;
  font-size: 90px;
  color: #000;
  display: inline-block;
}
.jetcode-part1-wrapper .jetcode-part1 .title-h2 {
  margin-left: 18px;
}
.jetcode-part1-wrapper .jetcode-part1 .title-content {
  font-size: 40px;
  color: #000;
}
.jetcode-part1-wrapper .jetcode-part1 button {
  margin-top: 22px;
  background-color: #000;
  color: #fff;
}
.jetcode-part1-wrapper .jetcode-part1-image,
.jetcode-part1-wrapper .jetcode-part1-image-mobile {
  width: 100%;
  max-height: 60%;
  max-width: 1200px;
  overflow: hidden;
  margin: auto;
}
.jetcode-part1-wrapper .jetcode-part1-icon {
  bottom: 20px;
  font-size: 24px;
  position: absolute;
  left: 50%;
  margin-left: -12px;
  color: #000;
}
@media screen and (max-width: 1124px) {
  .jetcode-part1-wrapper {
    height: 682px;
  }
  .jetcode-part1-wrapper .jetcode-part1 .title-h1 {
    font-size: 48px;
  }
  .jetcode-part1-wrapper .jetcode-part1 .title-content {
    font-size: 32px;
  }
  .jetcode-part1-wrapper .jetcode-part1 button {
    font-size: 16px;
  }
}
@media screen and (max-width: 767px) {
  .jetcode-part1-wrapper {
    min-height: 300px;
    height: auto;
    margin-top: 24px;
  }
  .jetcode-part1-wrapper .jetcode-part1 {
    overflow: hidden;
    padding: 24px 12px;
    margin: auto;
  }
  .jetcode-part1-wrapper .jetcode-part1 .title-h1,
  .jetcode-part1-wrapper .jetcode-part1 .title-h2 {
    display: block;
    font-size: 50px;
    line-height: 1;
    margin-bottom: 0;
  }
  .jetcode-part1-wrapper .jetcode-part1 .title-h2 {
    font-size: 24px;
    margin: 12px auto;
  }
  .jetcode-part1-wrapper .jetcode-part1 .title-content {
    font-size: 1.4em;
    margin-bottom: 28px;
  }
  .jetcode-part1-wrapper .jetcode-part1 button {
    margin-top: 12px;
  }
  .jetcode-part1-wrapper .jetcode-part1-image {
    margin: 24px 0;
  }
}
.jetcode-part2-wrapper {
  width: 100%;
  height: 720px;
  position: relative;
  text-align: center;
  background: #000;
  overflow: hidden;
}
.jetcode-part2-wrapper .home-page {
  padding: 0;
}
.jetcode-part2-wrapper .jetcode-part2 {
  width: 100%;
  position: relative;
  text-align: center;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.jetcode-part2-wrapper .jetcode-part2 > .queue-anim-leaving {
  position: relative !important;
}
.jetcode-part2-wrapper .jetcode-part2 h3 {
  font-size: 40px;
  color: #fff;
  margin-bottom: 24px;
}
.jetcode-part2-wrapper .jetcode-part2 h1 {
  color: #fff;
  font-size: 60px;
}
.jetcode-part2-wrapper .jetcode-part2-image,
.jetcode-part2-wrapper .jetcode-part2-image-mobile {
  position: relative;
  width: 100%;
  max-height: 60%;
  max-width: 1500px;
  overflow: hidden;
  margin: auto;
}
.jetcode-part2-wrapper .jetcode-part2-label {
  position: absolute;
  bottom: 24px;
  left: 0;
  right: 0;
  text-align: center;
  color: #fff;
  font-size: 12px !important;
}
@media screen and (max-width: 1124px) {
  .jetcode-part2-wrapper .jetcode-part2 img {
    max-height: 300px;
  }
}
@media screen and (max-width: 767px) {
  .jetcode-part2-wrapper {
    height: auto;
  }
  .jetcode-part2-wrapper .jetcode-part2 {
    padding: 24px 12px;
  }
  .jetcode-part2-wrapper .jetcode-part2 img {
    max-height: 200px;
  }
  .jetcode-part2-wrapper .jetcode-part2 h3 {
    font-size: 18px;
    color: #fff;
  }
  .jetcode-part2-wrapper .jetcode-part2 h1 {
    color: #FFF;
    font-size: 38px;
  }
  .jetcode-part2-wrapper .jetcode-part2-label {
    bottom: 12px;
  }
}
.jetcode-part3-wrapper {
  width: 100%;
  height: 882px;
  position: relative;
  text-align: center;
  background: #fff;
  overflow: hidden;
}
.jetcode-part3-wrapper .jetcode-part3 {
  width: 100%;
  position: relative;
  text-align: center;
  overflow: hidden;
  padding: 5vh 0 5vh 0;
}
.jetcode-part3-wrapper .jetcode-part3 > .queue-anim-leaving {
  position: relative !important;
}
.jetcode-part3-wrapper .jetcode-part3 h3 {
  font-size: 40px;
  color: #000;
  margin-bottom: 24px;
}
.jetcode-part3-wrapper .jetcode-part3 h1 {
  color: #000;
  font-size: 60px;
}
.jetcode-part3-wrapper .jetcode-part3-image,
.jetcode-part3-wrapper .jetcode-part3-image-mobile {
  position: relative;
  width: 100%;
  max-height: 60%;
  max-width: 1500px;
  overflow: hidden;
  margin: auto;
}
.jetcode-part3-wrapper .jetcode-part3-label {
  position: absolute;
  bottom: 24px;
  left: 0;
  right: 0;
  text-align: center;
  color: #fff;
  font-size: 12px !important;
}
@media screen and (max-width: 1124px) {
  .jetcode-part3-wrapper {
    height: 582px;
  }
  .jetcode-part3-wrapper .jetcode-part3-image,
  .jetcode-part3-wrapper .jetcode-part3-image-mobile {
    max-width: 600px;
  }
  .jetcode-part3-wrapper .jetcode-part3 img {
    max-height: 300px;
  }
}
@media screen and (max-width: 767px) {
  .jetcode-part3-wrapper {
    height: auto;
  }
  .jetcode-part3-wrapper .jetcode-part3 {
    padding: 24px 12px;
  }
  .jetcode-part3-wrapper .jetcode-part3 h3 {
    font-size: 20px;
    margin-bottom: 0;
  }
  .jetcode-part3-wrapper .jetcode-part3 h1 {
    font-size: 28px;
  }
  .jetcode-part3-wrapper .jetcode-part3-label {
    bottom: 12px;
  }
}
.jetcode-part4-wrapper {
  width: 100%;
  height: auto;
  position: relative;
  text-align: center;
  background: #fff;
  overflow: hidden;
}
.jetcode-part4-wrapper .jetcode-part4 {
  width: 100%;
  position: relative;
  text-align: center;
  overflow: hidden;
  padding: 5vh 0 5vh 0;
}
.jetcode-part4-wrapper .jetcode-part4 > .queue-anim-leaving {
  position: relative !important;
}
.jetcode-part4-wrapper .jetcode-part4 h3 {
  font-size: 40px;
  color: #000;
  margin-bottom: 24px;
}
.jetcode-part4-wrapper .jetcode-part4 h1 {
  color: #000;
  font-size: 60px;
}
.jetcode-part4-wrapper .jetcode-part4-image,
.jetcode-part4-wrapper .jetcode-part4-image-mobile {
  position: relative;
  width: 100%;
  max-height: 60%;
  max-width: 1500px;
  overflow: hidden;
  margin: auto;
}
.jetcode-part4-wrapper .jetcode-part4-label {
  position: absolute;
  bottom: 24px;
  left: 0;
  right: 0;
  text-align: center;
  color: #fff;
  font-size: 12px !important;
}
@media screen and (max-width: 1124px) {
  .jetcode-part4-wrapper {
    height: 582px;
  }
  .jetcode-part4-wrapper .jetcode-part4-image,
  .jetcode-part4-wrapper .jetcode-part4-image-mobile {
    max-width: 600px;
  }
  .jetcode-part4-wrapper .jetcode-part4 img {
    max-height: 300px;
  }
}
@media screen and (max-width: 767px) {
  .jetcode-part4-wrapper {
    height: auto;
    background: #fff;
  }
  .jetcode-part4-wrapper .jetcode-part4 {
    padding: 24px 12px;
  }
  .jetcode-part4-wrapper .jetcode-part4 h3 {
    font-size: 20px;
    color: #000;
    margin-bottom: 0;
  }
  .jetcode-part4-wrapper .jetcode-part4 h1 {
    font-size: 28px;
    color: #000;
  }
  .jetcode-part4-wrapper .jetcode-part4-label {
    bottom: 12px;
  }
}
.jetcode-part5-wrapper {
  height: 664px;
  background: #fafafa;
}
.jetcode-part5-wrapper .home-page {
  padding: 36px 0;
}
.jetcode-part5-wrapper .jetcode-part5 {
  height: 100%;
  overflow: hidden;
}
.jetcode-part5-wrapper .jetcode-part5 .title-h1 {
  font-size: 54px;
}
.jetcode-part5-wrapper .jetcode-part5 .title-content {
  text-align: center;
  font-size: 38px;
}
.jetcode-part5-wrapper .jetcode-part5-block-wrapper {
  position: relative;
}
.jetcode-part5-wrapper .jetcode-part5-block-wrapper .jetcode-part5-block {
  display: inline-block;
  padding: 48px 24px;
  vertical-align: top;
}
.jetcode-part5-wrapper .jetcode-part5-block-wrapper .jetcode-part5-block .jetcode-part5-icon {
  display: inline-block;
  width: 15%;
  vertical-align: top;
}
.jetcode-part5-wrapper .jetcode-part5-block-wrapper .jetcode-part5-block .jetcode-part5-text {
  width: 85%;
  display: inline-block;
  padding-left: 8%;
}
.jetcode-part5-wrapper .jetcode-part5-block-wrapper .jetcode-part5-block.clear-both {
  clear: both;
}
@media screen and (max-width: 767px) {
  .jetcode-part5-wrapper {
    height: auto;
  }
  .jetcode-part5-wrapper .jetcode-part5 {
    padding: 24px 12px;
  }
  .jetcode-part5-wrapper .jetcode-part5 .title-wrapper {
    margin: 0 auto 24px;
  }
  .jetcode-part5-wrapper .jetcode-part5 .title-h1 {
    font-size: 32px;
  }
  .jetcode-part5-wrapper .jetcode-part5 .title-content {
    text-align: center;
    font-size: 24px;
  }
  .jetcode-part5-wrapper .jetcode-part5-block-wrapper {
    margin: 20px auto;
    height: auto;
  }
  .jetcode-part5-wrapper .jetcode-part5-block-wrapper .jetcode-part5-block {
    padding: 24px;
  }
  .jetcode-part5-wrapper .jetcode-part5-block-wrapper .jetcode-part5-block .jetcode-part5-title {
    font-size: 20px;
  }
  .jetcode-part5-wrapper .jetcode-part5-block-wrapper .jetcode-part5-block.queue-anim-leaving {
    position: relative !important;
  }
}
.jetcode-part6-wrapper {
  height: 566px;
  background: #fff;
}
.jetcode-part6-wrapper .home-page {
  padding: 24px;
}
.jetcode-part6-wrapper .jetcode-part6 {
  width: 100%;
  height: 100%;
  position: relative;
  text-align: center;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.jetcode-part6-wrapper .jetcode-part6-image {
  background-color: #fff;
  height: 100%;
  width: 45%;
  overflow: hidden;
}
.jetcode-part6-wrapper .jetcode-part6-image div {
  height: 100%;
  width: 100%;
}
.jetcode-part6-wrapper .jetcode-part6-image div img {
  height: 100%;
  width: 100%;
  object-fit: scale-down;
}
.jetcode-part6-wrapper .jetcode-part6-text-wrapper {
  width: 410px;
  text-align: center;
}
.jetcode-part6-wrapper .jetcode-part6-title {
  font-size: 26px;
  color: #000;
}
.jetcode-part6-wrapper .jetcode-part6-content {
  font-size: 42px;
  font-weight: 500;
  line-height: 30px;
  color: #000;
}
.jetcode-part6-wrapper .jetcode-part6-intro {
  margin-top: 16px;
  font-size: 24px;
  text-align: left;
  color: #000;
}
.jetcode-part6-wrapper .jetcode-part6 a {
  display: block;
  margin-top: 24px;
  margin-bottom: 24px;
  background-color: #fff;
  font-size: 20px;
  border: none;
  text-align: center;
  color: #000;
}
@media screen and (max-width: 767px) {
  .jetcode-part6-wrapper {
    height: auto;
  }
  .jetcode-part6-wrapper .home-page {
    padding: 0;
  }
  .jetcode-part6-wrapper .jetcode-part6 {
    padding: 24px 12px;
    overflow: hidden;
    margin: auto;
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: center;
  }
  .jetcode-part6-wrapper .jetcode-part6-image {
    height: auto;
    padding: 12px 24px;
    width: 100%;
    margin-top: 24px;
  }
  .jetcode-part6-wrapper .jetcode-part6-text-wrapper {
    margin: 0;
  }
  .jetcode-part6-wrapper .jetcode-part6-title {
    font-size: 24px;
    line-height: 60px;
  }
  .jetcode-part6-wrapper .jetcode-part6-intro {
    text-align: center;
    font-size: 20px;
    margin-top: 0;
  }
}
.jetcode-part7-wrapper {
  height: 566px;
  background: #fff;
}
.jetcode-part7-wrapper .home-page {
  padding: 24px;
}
.jetcode-part7-wrapper .jetcode-part7 {
  width: 100%;
  height: 100%;
  position: relative;
  text-align: center;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.jetcode-part7-wrapper .jetcode-part7-image {
  background-color: #fff;
  width: 30%;
  height: 100%;
  overflow: hidden;
}
.jetcode-part7-wrapper .jetcode-part7-image div {
  height: 100%;
  width: 100%;
}
.jetcode-part7-wrapper .jetcode-part7-image div img {
  height: 100%;
  width: 100%;
  object-fit: scale-down;
}
.jetcode-part7-wrapper .jetcode-part7-text-wrapper {
  width: 610px;
  text-align: center;
}
.jetcode-part7-wrapper .jetcode-part7-title {
  font-size: 70px;
  color: #000;
}
.jetcode-part7-wrapper .jetcode-part7-content {
  font-size: 42px;
  font-weight: 500;
  line-height: 30px;
  color: #000;
}
.jetcode-part7-wrapper .jetcode-part7-intro {
  margin-top: 16px;
  font-size: 40px;
  text-align: left;
  color: #000;
}
.jetcode-part7-wrapper .jetcode-part7 a {
  display: block;
  margin-top: 24px;
  margin-bottom: 24px;
  background-color: #fff;
  font-size: 20px;
  border: none;
  text-align: center;
  color: #000;
}
@media screen and (max-width: 767px) {
  .jetcode-part7-wrapper {
    height: auto;
  }
  .jetcode-part7-wrapper .home-page {
    padding: 0;
  }
  .jetcode-part7-wrapper .jetcode-part7 {
    overflow: hidden;
    padding: 24px 12px;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .jetcode-part7-wrapper .jetcode-part7-image {
    height: auto;
    width: 100%;
    padding: 12px 24px;
  }
  .jetcode-part7-wrapper .jetcode-part7-title {
    font-size: 50px;
    line-height: 60px;
    margin-bottom: 10px;
  }
  .jetcode-part7-wrapper .jetcode-part7-content {
    font-size: 20px;
    line-height: 20px;
  }
  .jetcode-part7-wrapper .jetcode-part7-intro {
    margin: 12px 0;
    font-size: 24px;
    text-align: center;
  }
}
.jetcode-part8-wrapper {
  height: 460px;
  background: #fafafa;
}
.jetcode-part8-wrapper .home-page {
  padding: 0;
}
.jetcode-part8-wrapper .jetcode-part8 > p {
  text-align: center;
}
.jetcode-part8-wrapper .jetcode-part8-title-h1 {
  margin-bottom: 12px;
}
.jetcode-part8-wrapper .jetcode-part8 button {
  background-color: #000;
  color: #fff;
}
.jetcode-part8-wrapper .jetcode-part8-table-name-block {
  text-align: center;
  color: #666;
  width: 100%;
}
.jetcode-part8-wrapper .jetcode-part8-table-name {
  font-size: 24px;
}
.jetcode-part8-wrapper .jetcode-part8-table-money {
  font-size: 16px;
  margin: 8px 0 16px;
}
.jetcode-part8-wrapper .jetcode-part8-table-content {
  text-align: center;
  color: #666;
}
.jetcode-part8-wrapper .jetcode-part8-table-content-name {
  color: #666;
  text-align: center;
}
.jetcode-part8-wrapper.home-page-wrapper .jetcode-part8-title-wrapper {
  margin: 64px auto;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .jetcode-part8-wrapper {
    height: auto;
    background-color: #fff;
  }
  .jetcode-part8-wrapper .jetcode-part8 {
    padding: 24px 12px;
  }
  .jetcode-part8-wrapper .jetcode-part8-title-wrapper {
    margin: auto auto 24px !important;
  }
  .jetcode-part8-wrapper .jetcode-part8-wrapper {
    padding-bottom: 0;
  }
  .jetcode-part8-wrapper .jetcode-part8-table {
    margin-bottom: 24px;
  }
}
.energy-wrapper {
  padding-top: 64px;
}
