body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
textarea,
p,
blockquote,
th,
td,
hr,
button,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  margin: 0;
  padding: 0;
}
ul,
ol {
  list-style: none;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
body {
  word-wrap: break-word;
}
.video {
  max-width: 800px;
}
#react-content {
  min-height: 100%;
}
.security-part1-wrapper {
  height: 782px;
  margin-top: 64px;
  background: #fff;
}
.security-part1-wrapper .security-part1 {
  width: 100%;
  height: 100%;
  position: relative;
  text-align: center;
  overflow: hidden;
}
.security-part1-wrapper .security-part1 .title-h1 {
  line-height: 1.5;
  font-size: 90px;
  color: #000;
}
.security-part1-wrapper .security-part1 .title-content {
  font-size: 40px;
  color: #000;
}
.security-part1-wrapper .security-part1 button {
  margin-top: 22px;
  background-color: #000;
  color: #fff;
}
.security-part1-wrapper .security-part1-image,
.security-part1-wrapper .security-part1-image-mobile {
  width: 100%;
  max-height: 500px;
  max-width: 1200px;
  overflow: hidden;
  margin: auto;
}
.security-part1-wrapper .security-part1-image div,
.security-part1-wrapper .security-part1-image-mobile div,
.security-part1-wrapper .security-part1-image img,
.security-part1-wrapper .security-part1-image-mobile img {
  height: 100%;
  width: 100%;
  max-height: 500px;
}
.security-part1-wrapper .security-part1-icon {
  bottom: 20px;
  font-size: 24px;
  position: absolute;
  left: 50%;
  margin-left: -12px;
  color: #000;
}
@media screen and (max-width: 1124px) {
  .security-part1-wrapper {
    height: 682px;
  }
  .security-part1-wrapper .security-part1 .title-h1 {
    font-size: 48px;
  }
  .security-part1-wrapper .security-part1 .title-content {
    font-size: 32px;
  }
  .security-part1-wrapper .security-part1 button {
    font-size: 16px;
  }
}
@media screen and (max-width: 767px) {
  .security-part1-wrapper {
    min-height: 300px;
    height: auto;
  }
  .security-part1-wrapper .security-part1 {
    overflow: hidden;
    width: 90%;
    margin: auto;
  }
  .security-part1-wrapper .security-part1 .title-h1 {
    font-size: 2em;
    line-height: 1.2;
  }
  .security-part1-wrapper .security-part1 .title-content {
    font-size: 20px;
    margin: 12px auto;
  }
  .security-part1-wrapper .security-part1 button {
    margin-top: 12px;
  }
}
.security-part2-wrapper {
  width: 100%;
  height: auto;
  position: relative;
  text-align: center;
  background: #fff;
  overflow: hidden;
}
.security-part2-wrapper .security-part2 {
  width: 100%;
  position: relative;
  text-align: center;
  overflow: hidden;
  padding: 24px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.security-part2-wrapper .security-part2-title {
  font-size: 32px;
  color: #000;
  font-weight: 500;
  text-align: center;
}
.security-part2-wrapper .security-part2-block-wrapper {
  width: 100%;
}
.security-part2-wrapper .security-part2-block-title {
  font-size: 22px;
  color: #000;
  font-weight: 500;
  text-align: center;
}
.security-part2-wrapper .security-part2-block-content {
  padding: 12px;
  margin-top: 24px;
  margin-bottom: 24px;
  font-size: 16px;
  color: #000;
  text-align: center;
  height: 96px;
}
.security-part2-wrapper .security-part2-image-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 24px;
}
.security-part2-wrapper .security-part2 .content-title {
  font-weight: 700;
}
.security-part2-wrapper .security-part2-content {
  font-size: 20px;
  text-align: left;
  color: #000;
  margin-top: 24px;
  padding: 24px;
}
@media screen and (max-width: 1124px) {
  .security-part2-wrapper .security-part2 img {
    max-height: 300px;
  }
}
@media screen and (max-width: 767px) {
  .security-part2-wrapper {
    height: auto;
  }
  .security-part2-wrapper .security-part2 {
    padding: 48px 12px;
    padding-bottom: 12px;
  }
  .security-part2-wrapper .security-part2-block-content {
    margin: 8px 0;
    padding: 0 6px;
  }
}
.security-part3-wrapper {
  height: auto;
  background: #fff;
}
.security-part3-wrapper .title-wrapper > h1,
.security-part3-wrapper .home-page-wrapper > h1 {
  color: #000;
}
.security-part3-wrapper .security-part3 {
  height: 100%;
  overflow: hidden;
  padding: 24px;
}
.security-part3-wrapper .security-part3 .title-content {
  text-align: center;
}
.security-part3-wrapper .security-part3-block-wrapper {
  position: relative;
}
.security-part3-wrapper .security-part3-block-wrapper .security-part3-block {
  display: inline-block;
  padding: 48px 24px;
  vertical-align: top;
}
.security-part3-wrapper .security-part3-block-wrapper .security-part3-block .security-part3-icon {
  display: inline-block;
  width: 15%;
  vertical-align: top;
}
.security-part3-wrapper .security-part3-block-wrapper .security-part3-block .security-part3-text {
  width: 85%;
  display: inline-block;
  padding-left: 8%;
}
.security-part3-wrapper .security-part3-block-wrapper .security-part3-block.clear-both {
  clear: both;
}
@media screen and (max-width: 767px) {
  .security-part3-wrapper {
    height: auto;
  }
  .security-part3-wrapper .security-part3 .title-wrapper {
    margin-bottom: 24px;
  }
  .security-part3-wrapper .security-part3-block-wrapper {
    height: auto;
  }
  .security-part3-wrapper .security-part3-block-wrapper .security-part3-block {
    padding: 24px;
  }
  .security-part3-wrapper .security-part3-block-wrapper .security-part3-block .security-part3-title {
    font-size: 20px;
  }
  .security-part3-wrapper .security-part3-block-wrapper .security-part3-block.queue-anim-leaving {
    position: relative !important;
  }
}
.security-part4-wrapper {
  height: 566px;
  background: #fff;
}
.security-part4-wrapper .home-page {
  padding: 0;
}
.security-part4-wrapper .security-part4 {
  width: 100%;
  height: 100%;
  position: relative;
  text-align: center;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.security-part4-wrapper .security-part4-image {
  background-color: #fff;
  max-height: 100%;
  width: 55%;
  overflow: hidden;
}
.security-part4-wrapper .security-part4-image div {
  height: 100%;
  width: 100%;
}
.security-part4-wrapper .security-part4-image div img {
  height: 100%;
  width: 100%;
  object-fit: scale-down;
}
.security-part4-wrapper .security-part4-text-wrapper {
  width: 410px;
  text-align: center;
}
.security-part4-wrapper .security-part4-title {
  font-size: 26px;
  color: #000;
}
.security-part4-wrapper .security-part4-content {
  font-size: 42px;
  font-weight: 500;
  line-height: 30px;
  color: #000;
}
.security-part4-wrapper .security-part4-intro {
  margin-top: 16px;
  font-size: 24px;
  text-align: left;
  color: #000;
}
.security-part4-wrapper .security-part4 a {
  display: block;
  margin-top: 24px;
  margin-bottom: 24px;
  background-color: #fff;
  font-size: 20px;
  border: none;
  text-align: center;
  color: #000;
}
@media screen and (max-width: 767px) {
  .security-part4-wrapper {
    height: auto;
  }
  .security-part4-wrapper .home-page {
    padding: 0;
  }
  .security-part4-wrapper .security-part4 {
    overflow: hidden;
    padding: 24px 12px;
    padding-top: 0;
    margin: auto;
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: center;
    align-items: inherit;
  }
  .security-part4-wrapper .security-part4-image {
    height: auto;
    width: 100%;
    padding: 12px 24px;
    margin-top: 12px;
  }
  .security-part4-wrapper .security-part4-text-wrapper {
    margin-top: 24px;
    margin-left: 0px;
  }
  .security-part4-wrapper .security-part4-title {
    font-size: 34px;
    line-height: 60px;
  }
  .security-part4-wrapper .security-part4-explain {
    line-height: 40px;
  }
  .security-part4-wrapper .security-part4-content {
    font-size: 20px;
    line-height: 20px;
  }
  .security-part4-wrapper .security-part4-intro {
    text-align: center;
    font-size: 20px;
  }
}
.security-part5-wrapper {
  height: 566px;
  background: #fff;
}
.security-part5-wrapper .home-page {
  padding: 0;
}
.security-part5-wrapper .security-part5 {
  width: 100%;
  height: 100%;
  position: relative;
  text-align: center;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.security-part5-wrapper .security-part5-image {
  background-color: #fff;
  width: 30%;
  height: 100%;
  overflow: hidden;
}
.security-part5-wrapper .security-part5-image div {
  height: 100%;
  width: 100%;
}
.security-part5-wrapper .security-part5-image div img {
  height: 100%;
  width: 100%;
  object-fit: scale-down;
}
.security-part5-wrapper .security-part5-text-wrapper {
  width: 360px;
  text-align: center;
}
.security-part5-wrapper .security-part5-title {
  color: #000;
}
.security-part5-wrapper .security-part5-content {
  font-size: 42px;
  font-weight: 500;
  line-height: 30px;
  color: #000;
}
.security-part5-wrapper .security-part5-intro {
  margin-top: 16px;
  font-size: 24px;
  text-align: left;
  color: #000;
}
.security-part5-wrapper .security-part5 a {
  display: block;
  margin-top: 24px;
  margin-bottom: 24px;
  background-color: #fff;
  font-size: 20px;
  border: none;
  text-align: center;
  color: #000;
}
@media screen and (max-width: 767px) {
  .security-part5-wrapper {
    height: auto;
  }
  .security-part5-wrapper .home-page {
    padding: 0;
  }
  .security-part5-wrapper .security-part5 {
    overflow: hidden;
    padding: 24px 12px;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .security-part5-wrapper .security-part5-image {
    width: 100%;
    padding: 12px 24px;
    margin-top: 12px;
    height: auto;
  }
  .security-part5-wrapper .security-part5-text-wrapper {
    margin-top: 24px;
    margin-left: 0px;
  }
  .security-part5-wrapper .security-part5-title {
    font-size: 34px;
    line-height: 60px;
  }
  .security-part5-wrapper .security-part5-content {
    font-size: 20px;
    line-height: 20px;
  }
  .security-part5-wrapper .security-part5-intro {
    font-size: 20px;
    text-align: center;
  }
}
.security-part8-wrapper {
  height: 460px;
  background: #fafafa;
}
.security-part8-wrapper .home-page {
  padding: 0;
}
.security-part8-wrapper .security-part8 > p {
  text-align: center;
}
.security-part8-wrapper .security-part8-title-h1 {
  margin-bottom: 12px;
}
.security-part8-wrapper .security-part8 button {
  background-color: #000;
  color: #fff;
}
.security-part8-wrapper .security-part8-table-name-block {
  text-align: center;
  color: #666;
  width: 100%;
}
.security-part8-wrapper .security-part8-table-name {
  font-size: 24px;
}
.security-part8-wrapper .security-part8-table-money {
  font-size: 16px;
  margin: 8px 0 16px;
}
.security-part8-wrapper .security-part8-table-content {
  text-align: center;
  color: #666;
}
.security-part8-wrapper .security-part8-table-content-name {
  color: #666;
  text-align: center;
}
.security-part8-wrapper.home-page-wrapper .security-part8-title-wrapper {
  margin: 64px auto;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .security-part8-wrapper {
    height: auto;
    background-color: #fff;
  }
  .security-part8-wrapper .security-part8 {
    padding: 24px 12px;
    padding-bottom: 64px;
  }
  .security-part8-wrapper .security-part8-title-wrapper {
    margin: auto auto 24px !important;
  }
  .security-part8-wrapper .security-part8-wrapper {
    padding-bottom: 0;
  }
  .security-part8-wrapper .security-part8-table {
    margin-bottom: 24px;
  }
}
.energy-wrapper {
  padding-top: 64px;
}
