body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
textarea,
p,
blockquote,
th,
td,
hr,
button,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  margin: 0;
  padding: 0;
}
ul,
ol {
  list-style: none;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.banner0 {
  width: 100%;
  height: 100vh;
  position: relative;
  text-align: center;
  border-color: #666;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
}
.banner0 video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.banner0 .banner0-text-wrapper {
  display: inline-block;
  position: absolute;
  top: 34%;
  margin: auto;
  left: 0;
  right: 0;
  font-size: 14px;
  color: #fff;
}
.banner0 .banner0-text-wrapper > .queue-anim-leaving {
  position: relative !important;
}
.banner0 .banner0-title {
  width: 350px;
  left: 30px;
  min-height: 60px;
  margin: auto;
  display: inline-block;
  font-size: 40px;
  position: relative;
}
.banner0 .banner0-content {
  margin-bottom: 20px;
  word-wrap: break-word;
  min-height: 24px;
}
.banner0 .banner0-content div h1 {
  color: #fff;
  font-size: 100px;
}
.banner0 .banner0-content div h3 {
  color: #f8f8f8;
  font-size: 30px;
}
.banner0 .banner0-content div span {
  color: red;
}
.banner0 .banner0-button {
  border: 1px solid #fff;
  color: #fff;
  background: transparent;
  box-shadow: 0 0 0 transparent;
  line-height: 40px;
  font-size: 16px;
  height: 40px;
  transition: background 0.45s cubic-bezier(0.215, 0.61, 0.355, 1), box-shadow 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.banner0 .banner0-button:hover {
  color: #fff;
  border-color: #fff;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 10px rgba(50, 250, 255, 0.75);
}
.banner0 .banner0-button:focus {
  color: #fff;
  border-color: #fff;
}
.banner0 .banner0-button.queue-anim-leaving {
  width: auto;
}
.banner0 .banner0-icon {
  bottom: 20px;
  font-size: 24px;
  position: absolute;
  left: 50%;
  margin-left: -12px;
  color: #fff;
}
@media screen and (max-width: 1024px) {
  .banner0 .banner0-content div h1 {
    color: #fff;
    font-size: 70px;
  }
  .banner0 .banner0-content div h3 {
    color: #f8f8f8;
    font-size: 30px;
  }
  .banner0 .banner0-content div span {
    color: red;
  }
}
@media screen and (max-width: 767px) {
  .banner0 {
    background-attachment: inherit;
  }
  .banner0 .banner0-text-wrapper {
    width: 90%;
  }
  .banner0 .banner0-title {
    width: 90%;
    left: 0;
  }
  .banner0 .banner0-content div h1 {
    color: #fff;
    font-size: 34px;
  }
  .banner0 .banner0-content div h3 {
    margin-top: 24px;
    color: #f8f8f8;
    font-size: 18px;
  }
  .banner0 .banner0-content div span {
    color: red;
  }
}
.banner12-wrapper {
  height: 320px;
  width: 100%;
  position: relative;
  background-color: #000;
}
.banner12-wrapper .banner12-title-abs {
  position: absolute;
  top: 10%;
  left: 0;
  right: 0;
  bottom: 0;
  font-size: 50px;
  font-family: cursive, STXihei, "华文细黑", "Microsoft YaHei", "微软雅黑";
  font-weight: bold;
  text-align: center;
  color: #fff;
}
.banner12-wrapper .banner12-img-abs {
  position: absolute;
  bottom: 0;
  height: 220px;
  left: 0;
  right: 0;
}
.banner12-wrapper .banner12-img-abs img {
  object-fit: cover;
  height: 100%;
  width: 100%;
  transform: scale(0.7);
}
.banner12-wrapper .banner12-img {
  height: 100%;
  width: 100%;
}
.banner12-wrapper .banner12-img img {
  opacity: 0.6;
  object-fit: cover;
  height: 100%;
  width: 100%;
}
@media screen and (max-width: 767px) {
  .banner12-wrapper {
    height: 220px;
  }
  .banner12-wrapper .banner12-img-abs {
    bottom: -20px;
  }
  .banner12-wrapper .banner12-img-abs img {
    transform: scale(1);
  }
  .banner12-wrapper .banner12-title-abs {
    font-size: 24px;
    top: 20%;
  }
}
.banner2 {
  padding-top: 100px;
  width: 100%;
  position: relative;
  text-align: center;
  border-color: #666;
}
.banner2 .banner2-text-wrapper {
  display: inline-block;
  position: absolute;
  top: 2%;
  margin: auto;
  left: 0;
  right: 0;
  font-size: 14px;
  color: #fff;
}
.banner2 .banner2-text-wrapper > .queue-anim-leaving {
  position: relative !important;
}
.banner2 .banner2-title {
  width: 350px;
  left: 30px;
  min-height: 60px;
  margin: auto;
  display: inline-block;
  font-size: 40px;
  position: relative;
}
.banner2 .banner2-content {
  margin-bottom: 20px;
  word-wrap: break-word;
  min-height: 24px;
}
.banner2 .banner2-content div h3 {
  color: #f8f8f8;
}
.banner2 .banner2-content div span {
  color: red;
}
.banner2 .banner2-button {
  border: 1px solid #fff;
  color: #fff;
  background: transparent;
  box-shadow: 0 0 0 transparent;
  line-height: 40px;
  font-size: 16px;
  height: 40px;
  transition: background 0.45s cubic-bezier(0.215, 0.61, 0.355, 1), box-shadow 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.banner2 .banner2-button:hover {
  color: #fff;
  border-color: #fff;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 10px rgba(50, 250, 255, 0.75);
}
.banner2 .banner2-button:focus {
  color: #fff;
  border-color: #fff;
}
.banner2 .banner2-button.queue-anim-leaving {
  width: auto;
}
.banner2 .banner2-icon {
  bottom: 20px;
  font-size: 24px;
  position: absolute;
  left: 50%;
  margin-left: -12px;
  color: #fff;
}
@media screen and (max-width: 1024px) {
  .banner2 .banner2-content div h1 {
    color: #fff;
  }
  .banner2 .banner2-content div h3 {
    color: #f8f8f8;
    font-size: 30px;
  }
  .banner2 .banner2-content div span {
    color: red;
  }
}
@media screen and (max-width: 767px) {
  .banner2 {
    background-attachment: inherit;
  }
  .banner2 .banner2-text-wrapper {
    width: 90%;
  }
  .banner2 .banner2-title {
    width: 90%;
    left: 0;
  }
  .banner2 .banner2-content div h1 {
    color: rgba(0, 0, 0, 0.65);
  }
  .banner2 .banner2-content div h3 {
    color: #f8f8f8;
    font-size: 14px;
  }
  .banner2 .banner2-content div span {
    color: red;
  }
}
.content0-wrapper {
  margin-top: 20px;
  height: 682px;
  overflow: hidden;
}
.content0-wrapper .content0 {
  overflow: hidden;
  height: 100%;
  padding: 64px 24px;
}
.content0-wrapper .content0 > .title-wrapper {
  margin: 0 auto 48px;
}
.content0-wrapper .content0 .block-wrapper {
  position: relative;
  height: 100%;
  overflow: hidden;
  top: 25%;
  padding: 20px 0;
}
.content0-wrapper .content0 .block-wrapper .block {
  padding: 0 4%;
  display: inline-block;
  text-align: center;
  height: 300px;
  margin-bottom: 48px;
}
.content0-wrapper .content0 .block-wrapper .block.queue-anim-leaving {
  position: relative !important;
}
.content0-wrapper .content0 .block-wrapper .block .icon {
  width: 160px;
  height: 160px;
  margin: auto;
  display: flex;
  align-items: center;
}
.content0-wrapper .content0 .block-wrapper .block > h3 {
  line-height: 32px;
  font-size: 30px;
  margin: 28px auto 36px;
}
.content0-wrapper .content0 .block-wrapper .block > p {
  padding: 0 16px;
}
@media screen and (max-width: 767px) {
  .content0-wrapper {
    height: 1280px;
  }
}
.teams1-wrapper {
  min-height: 446px;
  overflow: hidden;
}
.teams1-wrapper .box-shadow-d {
  box-shadow: 0px 10px 10px #f3efef;
  padding: 20px 0;
  width: 300px;
  margin: auto;
  height: 410px;
}
.teams1-wrapper .teams1 {
  overflow: hidden;
  height: 100%;
  padding: 64px 24px;
}
.teams1-wrapper .teams1 > .title-wrapper {
  margin: 0 auto 48px;
}
.teams1-wrapper .teams1 .block-wrapper {
  position: relative;
  height: 100%;
  overflow: hidden;
  padding: 0 0;
}
.teams1-wrapper .teams1 .block-wrapper .block {
  display: inline-block;
  text-align: center;
  margin-bottom: 48px;
  position: relative;
}
.teams1-wrapper .teams1 .block-wrapper .block.queue-anim-leaving {
  position: relative !important;
}
.teams1-wrapper .teams1 .block-wrapper .block .inner-title {
  position: absolute;
  top: 90px;
  font-size: 30px;
  font-weight: 500;
  left: 0;
  right: 0;
  color: #fff;
}
.teams1-wrapper .teams1-image,
.teams1-wrapper .teams1-title,
.teams1-wrapper .teams1-job,
.teams1-wrapper .teams1-content {
  width: 300px;
  margin: auto;
  line-height: 1.5;
}
.teams1-wrapper .teams1-image {
  position: relative;
  color: #404040;
  background-color: #000;
}
.teams1-wrapper .teams1-image img {
  width: 100%;
  opacity: 0.86;
}
.teams1-wrapper .teams1-title {
  font-size: 24px;
  margin: 24px 0;
  text-align: left;
  padding-left: 16px;
}
.teams1-wrapper .teams1-job {
  margin: 8px auto;
}
.teams1-wrapper .teams1-job,
.teams1-wrapper .teams1-content {
  padding: 0 16px;
  text-align: left;
  font-size: 14px;
  color: #919191;
}
.teams1-wrapper .teams1-content {
  padding-bottom: 24px;
}
@media screen and (max-width: 767px) {
  .teams1-wrapper {
    min-height: 1440px;
  }
}
.templates-wrapper {
  padding-bottom: 0px;
}
.nav-header-change {
  background-color: #000 !important;
}
