body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
textarea,
p,
blockquote,
th,
td,
hr,
button,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  margin: 0;
  padding: 0;
}
ul,
ol {
  list-style: none;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
body {
  word-wrap: break-word;
}
/* .content-wrapper > .tween-one-leaving,
.queue-anim-leaving {
  // position: absolute !important;
  // width: 100%;
} */
.video {
  max-width: 800px;
}
#react-content {
  min-height: 100%;
}
.pv-part1-wrapper {
  height: 782px;
  margin-top: 64px;
  background: #fff;
}
.pv-part1-wrapper .pv-part1 {
  width: 100%;
  height: 100%;
  position: relative;
  text-align: center;
  overflow: hidden;
}
.pv-part1-wrapper .pv-part1 .title-h1,
.pv-part1-wrapper .pv-part1 .title-h2 {
  line-height: 1.5;
  font-size: 90px;
  color: #396bf6;
  display: inline-block;
}
.pv-part1-wrapper .pv-part1 .title-h2 {
  margin-left: 18px;
}
.pv-part1-wrapper .pv-part1 .title-content {
  font-size: 40px;
  color: #000;
}
.pv-part1-wrapper .pv-part1 button {
  margin-top: 22px;
  background-color: #396bf6;
  color: #fff;
}
.pv-part1-wrapper .pv-part1-image,
.pv-part1-wrapper .pv-part1-image-mobile {
  width: 100%;
  max-height: 500px;
  max-width: 1200px;
  overflow: hidden;
  margin: auto;
}
.pv-part1-wrapper .pv-part1-image div,
.pv-part1-wrapper .pv-part1-image-mobile div,
.pv-part1-wrapper .pv-part1-image img,
.pv-part1-wrapper .pv-part1-image-mobile img {
  height: 100%;
  width: 100%;
  max-height: 500px;
}
.pv-part1-wrapper .pv-part1-icon {
  bottom: 20px;
  font-size: 24px;
  position: absolute;
  left: 50%;
  margin-left: -12px;
  color: #000;
}
@media screen and (max-width: 1124px) {
  .pv-part1-wrapper {
    height: 682px;
  }
  .pv-part1-wrapper .pv-part1 .title-h1 {
    font-size: 48px;
  }
  .pv-part1-wrapper .pv-part1 .title-content {
    font-size: 32px;
  }
  .pv-part1-wrapper .pv-part1 button {
    font-size: 16px;
  }
}
@media screen and (max-width: 767px) {
  .pv-part1-wrapper {
    min-height: 300px;
    height: auto;
    margin-top: 24px;
  }
  .pv-part1-wrapper .pv-part1 {
    overflow: hidden;
    padding: 24px 12px;
    margin: auto;
  }
  .pv-part1-wrapper .pv-part1 .title-h1,
  .pv-part1-wrapper .pv-part1 .title-h2 {
    display: block;
    font-size: 50px;
    line-height: 1;
    margin-bottom: 0;
  }
  .pv-part1-wrapper .pv-part1 .title-h2 {
    margin: 12px auto;
    font-size: 24px;
  }
  .pv-part1-wrapper .pv-part1 .title-content {
    font-size: 1.4em;
    margin-bottom: 28px;
  }
  .pv-part1-wrapper .pv-part1 button {
    margin-top: 12px;
  }
  .pv-part1-wrapper .pv-part1-image {
    margin: 24px 0;
  }
}
.pv-part2-wrapper {
  width: 100%;
  height: 482px;
  position: relative;
  text-align: center;
  background: #fff;
  overflow: hidden;
  background-image: url("https://website-public-resource-for-all-user.oss-cn-shanghai.aliyuncs.com/ai-website/public/energy-7.png");
  background-size: cover;
  background-position: center;
}
.pv-part2-wrapper .part2-page {
  display: inline-block;
  position: absolute;
  top: 16%;
  margin: auto;
  left: 0;
  right: 0;
  font-size: 14px;
  color: #fff;
}
.pv-part2-wrapper .part2-page > .queue-anim-leaving {
  position: relative !important;
}
.pv-part2-wrapper .part2-page h3 {
  font-size: 42px;
  color: #fff;
}
.pv-part2-wrapper .part2-page h1 {
  color: #FFF;
  font-size: 56px;
}
@media screen and (max-width: 1124px) {
  .pv-part2-wrapper {
    height: 400px;
  }
  .pv-part2-wrapper .part2-page h1 {
    font-size: 38px;
  }
  .pv-part2-wrapper .part2-page h3 {
    font-size: 32px;
  }
}
@media screen and (max-width: 767px) {
  .pv-part2-wrapper {
    height: 300px;
  }
  .pv-part2-wrapper .part2-page {
    padding: 0 12px;
  }
  .pv-part2-wrapper .part2-page h3 {
    font-size: 20px;
  }
  .pv-part2-wrapper .part2-page h1 {
    font-size: 18px;
  }
}
.pv-part3-wrapper {
  width: 100%;
  height: auto;
  position: relative;
  text-align: center;
  background: #000;
  overflow: hidden;
}
.pv-part3-wrapper .pv-part3 {
  width: 100%;
  position: relative;
  text-align: center;
  overflow: hidden;
  padding: 5vh 0 5vh 0;
}
.pv-part3-wrapper .pv-part3 > .queue-anim-leaving {
  position: relative !important;
}
.pv-part3-wrapper .pv-part3 h3 {
  font-size: 40px;
  color: #fff;
  margin-bottom: 24px;
}
.pv-part3-wrapper .pv-part3 h1 {
  color: #FFF;
  font-size: 60px;
}
.pv-part3-wrapper .pv-part3-image,
.pv-part3-wrapper .pv-part3-image-mobile {
  position: relative;
  width: 100%;
  max-height: 60%;
  max-width: 1500px;
  overflow: hidden;
  margin: auto;
}
.pv-part3-wrapper .pv-part3-label {
  position: absolute;
  bottom: 24px;
  left: 0;
  right: 0;
  text-align: center;
  color: #fff;
  font-size: 20px !important;
}
@media screen and (max-width: 1124px) {
  .pv-part3-wrapper {
    height: auto;
  }
  .pv-part3-wrapper .pv-part3 {
    height: auto;
  }
  .pv-part3-wrapper .pv-part3 img {
    max-height: 300px;
  }
}
@media screen and (max-width: 767px) {
  .pv-part3-wrapper {
    height: auto;
  }
  .pv-part3-wrapper .pv-part3 {
    padding-bottom: 36px;
  }
  .pv-part3-wrapper .pv-part3 img {
    max-height: 200px;
  }
  .pv-part3-wrapper .pv-part3 h3 {
    font-size: 20px;
    color: #fff;
  }
  .pv-part3-wrapper .pv-part3 h1 {
    color: #FFF;
    font-size: 38px;
  }
  .pv-part3-wrapper .pv-part3-label {
    bottom: 12px;
  }
}
.pv-part5-wrapper {
  height: 664px;
  background: #fafafa;
}
.pv-part5-wrapper .home-page {
  padding: 36px 0;
}
.pv-part5-wrapper .pv-part5 {
  height: 100%;
  overflow: hidden;
}
.pv-part5-wrapper .pv-part5 .title-h1 {
  font-size: 54px;
}
.pv-part5-wrapper .pv-part5 .title-content {
  text-align: center;
  font-size: 38px;
}
.pv-part5-wrapper .pv-part5-block-wrapper {
  position: relative;
}
.pv-part5-wrapper .pv-part5-block-wrapper .pv-part5-block {
  display: inline-block;
  padding: 48px 24px;
  vertical-align: top;
}
.pv-part5-wrapper .pv-part5-block-wrapper .pv-part5-block .pv-part5-icon {
  display: inline-block;
  width: 15%;
  vertical-align: top;
}
.pv-part5-wrapper .pv-part5-block-wrapper .pv-part5-block .pv-part5-text {
  width: 85%;
  display: inline-block;
  padding-left: 8%;
}
.pv-part5-wrapper .pv-part5-block-wrapper .pv-part5-block.clear-both {
  clear: both;
}
@media screen and (max-width: 767px) {
  .pv-part5-wrapper {
    height: auto;
  }
  .pv-part5-wrapper .pv-part5 {
    padding: 24px 12px;
  }
  .pv-part5-wrapper .pv-part5 .title-wrapper {
    margin: 0 auto 24px;
  }
  .pv-part5-wrapper .pv-part5 .title-h1 {
    font-size: 32px;
  }
  .pv-part5-wrapper .pv-part5 .title-content {
    text-align: center;
    font-size: 24px;
  }
  .pv-part5-wrapper .pv-part5-block-wrapper {
    margin: 20px auto;
    height: auto;
  }
  .pv-part5-wrapper .pv-part5-block-wrapper .pv-part5-block {
    padding: 24px;
  }
  .pv-part5-wrapper .pv-part5-block-wrapper .pv-part5-block .pv-part5-title {
    font-size: 20px;
  }
  .pv-part5-wrapper .pv-part5-block-wrapper .pv-part5-block.queue-anim-leaving {
    position: relative !important;
  }
}
.pv-part6-wrapper {
  height: 70vh;
  min-height: 782px;
  background: #fff;
}
.pv-part6-wrapper .pv-part6 {
  width: 100%;
  height: 100%;
  position: relative;
  text-align: center;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5vh 0 5vh 0;
}
.pv-part6-wrapper .pv-part6 .title-h1 {
  font-size: 44px;
  color: #000;
}
.pv-part6-wrapper .pv-part6 .title-content {
  font-size: 24px;
  color: #000;
}
.pv-part6-wrapper .pv-part6 button {
  margin-top: 24px;
  background-color: #396bf6;
  color: #fff;
}
.pv-part6-wrapper .pv-part6-image,
.pv-part6-wrapper .pv-part6-image-mobile {
  width: 100%;
  max-height: 60%;
  max-width: 700px;
  overflow: hidden;
  margin: auto;
}
.pv-part6-wrapper .pv-part6-icon {
  bottom: 20px;
  font-size: 24px;
  position: absolute;
  left: 50%;
  margin-left: -12px;
  color: #000;
}
@media screen and (max-width: 1124px) {
  .pv-part6-wrapper .pv-part6 {
    display: flex;
    flex-wrap: wrap;
  }
  .pv-part6-wrapper .pv-part6 .title-h1 {
    font-size: 32px;
  }
}
@media screen and (max-width: 767px) {
  .pv-part6-wrapper {
    height: auto;
    min-height: 350px;
  }
  .pv-part6-wrapper .pv-part6 {
    overflow: hidden;
    width: 90%;
    margin: auto;
    display: flex;
    flex-wrap: wrap-reverse;
  }
  .pv-part6-wrapper .pv-part6 .title-wrapper {
    margin: 24px auto;
  }
  .pv-part6-wrapper .pv-part6 .title-h1 {
    font-size: 32px;
    line-height: 42px;
    color: #000;
  }
  .pv-part6-wrapper .pv-part6 .content {
    font-size: 20px;
    line-height: 20px;
  }
  .pv-part6-wrapper .pv-part6-intro {
    font-size: 20px;
  }
}
.energy-wrapper {
  padding-top: 64px;
}
