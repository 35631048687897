body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
textarea,
p,
blockquote,
th,
td,
hr,
button,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  margin: 0;
  padding: 0;
}
ul,
ol {
  list-style: none;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.footer2-wrapper {
  background-color: #000;
  height: auto;
  overflow: hidden;
  display: flex;
  justify-content: center;
}
.footer2-wrapper .footer2 {
  height: 100%;
  padding: 0 24px;
  line-height: 80px;
  text-align: center;
  color: #999;
  position: relative;
}
.footer2-wrapper .copyright {
  float: right;
}
.footer2-wrapper .copyright > * {
  display: inline-block;
}
.footer2-wrapper .copyright-logo {
  width: 16px;
  margin-right: 8px;
}
.footer2-wrapper .copyright-logo img {
  width: 100%;
}
.footer2-wrapper .copyright-line {
  padding: 0 12px;
  margin: 0 12px;
}
.footer2-wrapper .links {
  float: left;
  display: flex;
  align-items: center;
  height: 100%;
}
.footer2-wrapper .links a {
  height: 21px;
  display: inline-block;
  margin-right: 32px;
}
.footer2-wrapper .links a img {
  display: block;
}
@media screen and (max-width: 767px) {
  .footer2-wrapper .footer2 {
    font-size: 12px;
  }
  .footer2-wrapper .footer2.home-page {
    padding: 0;
  }
  .footer2-wrapper .footer2 > div {
    width: 100%;
    margin: auto;
  }
}
