body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
textarea,
p,
blockquote,
th,
td,
hr,
button,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  margin: 0;
  padding: 0;
}
ul,
ol {
  list-style: none;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
body {
  word-wrap: break-word;
}
.video {
  max-width: 800px;
}
#react-content {
  min-height: 100%;
}
.csar-part1-wrapper {
  height: 782px;
  margin-top: 64px;
  background: #fff;
}
.csar-part1-wrapper .csar-part1 {
  width: 100%;
  height: 100%;
  position: relative;
  text-align: center;
  overflow: hidden;
}
.csar-part1-wrapper .csar-part1 .title-h1,
.csar-part1-wrapper .csar-part1 .title-h2 {
  line-height: 1.5;
  font-size: 90px;
  color: #000;
  display: inline-block;
}
.csar-part1-wrapper .csar-part1 .title-h2 {
  margin-left: 18px;
}
.csar-part1-wrapper .csar-part1 .title-content {
  font-size: 40px;
  color: #000;
}
.csar-part1-wrapper .csar-part1 button {
  margin-top: 22px;
  background-color: #000;
  color: #fff;
}
.csar-part1-wrapper .csar-part1-image,
.csar-part1-wrapper .csar-part1-image-mobile {
  padding: 12px 24px;
  width: 100%;
  max-height: 60%;
  max-width: 1200px;
  overflow: hidden;
  margin: auto;
}
.csar-part1-wrapper .csar-part1-icon {
  bottom: 20px;
  font-size: 24px;
  position: absolute;
  left: 50%;
  margin-left: -12px;
  color: #000;
}
@media screen and (max-width: 1124px) {
  .csar-part1-wrapper {
    height: 682px;
  }
  .csar-part1-wrapper .csar-part1 .title-h1 {
    font-size: 48px;
  }
  .csar-part1-wrapper .csar-part1 .title-content {
    font-size: 32px;
  }
  .csar-part1-wrapper .csar-part1 button {
    font-size: 16px;
  }
}
@media screen and (max-width: 767px) {
  .csar-part1-wrapper {
    min-height: 300px;
    height: auto;
    margin-top: 24px;
  }
  .csar-part1-wrapper .csar-part1 {
    overflow: hidden;
    padding: 24px 12px;
    margin: auto;
  }
  .csar-part1-wrapper .csar-part1 .title-h1,
  .csar-part1-wrapper .csar-part1 .title-h2 {
    display: block;
    font-size: 50px;
    line-height: 1;
    margin-bottom: 0;
  }
  .csar-part1-wrapper .csar-part1 .title-h2 {
    font-size: 24px;
    margin: 12px auto;
  }
  .csar-part1-wrapper .csar-part1 .title-content {
    font-size: 1.4em;
    margin-bottom: 28px;
  }
  .csar-part1-wrapper .csar-part1 button {
    margin-top: 12px;
  }
  .csar-part1-wrapper .csar-part1-image {
    margin: 24px 0;
    padding: 0;
  }
}
.csar-part2-wrapper {
  width: 100%;
  height: auto;
  background-color: #fdfdfd;
  overflow: hidden;
}
.csar-part2-wrapper .csar-part2 {
  position: relative;
  padding: 64px 24px 24px 24px;
}
.csar-part2-wrapper .csar-part2-title-wrapper {
  width: 45%;
  max-width: 450px;
  position: absolute;
  top: 25%;
  right: 44px;
  font-family: 'PingFang SC', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}
.csar-part2-wrapper .csar-part2-title {
  font-size: 24px;
  line-height: 1.5;
  color: #000;
  margin-bottom: 8px;
}
.csar-part2-wrapper .csar-part2-explain {
  color: #333;
  font-size: 20px;
  margin-top: 24px;
}
.csar-part2-wrapper .csar-part2-image {
  overflow: hidden;
  width: 55%;
  max-width: 710px;
  position: relative;
  top: 0;
  right: 24px;
}
@media screen and (max-width: 1124px) {
  .csar-part2-wrapper {
    height: 880px;
  }
  .csar-part2-wrapper .csar-part2 {
    min-height: 500px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .csar-part2-wrapper .csar-part2.home-page {
    padding-top: 1px;
    padding-bottom: 0;
  }
  .csar-part2-wrapper .csar-part2-title-wrapper {
    text-align: center;
    position: static;
    width: 100%;
    margin-top: 64px;
    left: 0;
    top: 0;
  }
  .csar-part2-wrapper .csar-part2-title {
    font-size: 24px;
  }
  .csar-part2-wrapper .csar-part2-explain {
    font-size: 18px;
  }
  .csar-part2-wrapper .csar-part2-image {
    position: static;
    width: 100%;
    top: 0;
    right: 0;
    margin: 32px 0 24px;
  }
}
@media screen and (max-width: 767px) {
  .csar-part2-wrapper {
    border-top: 1px solid #f5f5f5;
    height: auto;
  }
  .csar-part2-wrapper .csar-part2 {
    display: flex;
    flex-wrap: wrap-reverse;
  }
  .csar-part2-wrapper .csar-part2.home-page {
    padding-top: 1px;
    padding-bottom: 0;
  }
  .csar-part2-wrapper .csar-part2-title-wrapper {
    text-align: left;
    position: static;
    width: 100%;
    margin-top: 24px;
    left: 0;
    top: 0;
  }
  .csar-part2-wrapper .csar-part2-title {
    text-align: center;
    font-size: 20px;
  }
  .csar-part2-wrapper .csar-part2-explain {
    text-indent: 2em;
    font-size: 18px;
  }
  .csar-part2-wrapper .csar-part2-image {
    position: static;
    width: 100%;
    top: 0;
    right: 0;
    margin: 32px 0 24px;
  }
}
.csar-part3-wrapper {
  width: 100%;
  height: 682px;
  position: relative;
  text-align: center;
  background: #fff;
  overflow: hidden;
}
.csar-part3-wrapper .csar-part3 {
  width: 100%;
  position: relative;
  text-align: center;
  overflow: hidden;
  padding-top: 5vh;
}
.csar-part3-wrapper .csar-part3-title {
  font-size: 32px;
  color: #000;
  font-weight: 500;
  text-align: center;
}
.csar-part3-wrapper .csar-part3-image1,
.csar-part3-wrapper .csar-part3-image2 {
  width: 48%;
}
.csar-part3-wrapper .csar-part3-image1 img,
.csar-part3-wrapper .csar-part3-image2 img {
  width: 100%;
  box-shadow: 0px 10px 10px #cfcfcf;
}
.csar-part3-wrapper .csar-part3-image1 {
  margin-right: 4%;
}
.csar-part3-wrapper .csar-part3-image-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 24px;
}
.csar-part3-wrapper .csar-part3-content {
  font-size: 20px;
  text-align: left;
  color: #000;
  margin-top: 24px;
  padding: 24px;
}
@media screen and (max-width: 1124px) {
  .csar-part3-wrapper {
    height: 582px;
  }
  .csar-part3-wrapper .csar-part3 img {
    max-height: 300px;
  }
}
@media screen and (max-width: 767px) {
  .csar-part3-wrapper {
    height: auto;
  }
  .csar-part3-wrapper .csar-part3 {
    padding: 48px 12px;
  }
  .csar-part3-wrapper .csar-part3-content {
    margin-top: 12px;
    padding-bottom: 0;
    font-size: 18px;
  }
}
.csar-part4-wrapper {
  width: 100%;
  height: 642px;
  position: relative;
  text-align: center;
  background: #000;
  overflow: hidden;
}
.csar-part4-wrapper .csar-part4 {
  width: 100%;
  position: relative;
  text-align: center;
  overflow: hidden;
  padding: 24px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.csar-part4-wrapper .csar-part4-title {
  font-size: 32px;
  color: #fff;
  font-weight: 500;
  text-align: center;
}
.csar-part4-wrapper .csar-part4-block-title {
  font-size: 22px;
  color: #fff;
  font-weight: 500;
  text-align: center;
}
.csar-part4-wrapper .csar-part4-image-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 24px;
}
.csar-part4-wrapper .csar-part4 .content-title {
  font-weight: 700;
}
.csar-part4-wrapper .csar-part4-content {
  font-size: 20px;
  text-align: left;
  color: #fff;
  margin-top: 24px;
  padding: 24px;
}
@media screen and (max-width: 1124px) {
  .csar-part4-wrapper .csar-part4 img {
    max-height: 300px;
  }
}
@media screen and (max-width: 767px) {
  .csar-part4-wrapper {
    height: auto;
  }
  .csar-part4-wrapper .csar-part4 {
    padding: 48px 12px;
    padding-bottom: 0;
  }
  .csar-part4-wrapper .csar-part4-content {
    margin-top: 0;
    font-size: 18px;
  }
}
.csar-part5-wrapper {
  height: 566px;
  background: #000;
}
.csar-part5-wrapper .home-page {
  padding: 0;
}
.csar-part5-wrapper .csar-part5 {
  width: 100%;
  height: 100%;
  position: relative;
  text-align: center;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.csar-part5-wrapper .csar-part5-image {
  background-color: #000;
  max-height: 100%;
  width: 55%;
  overflow: hidden;
}
.csar-part5-wrapper .csar-part5-image div {
  height: 100%;
  width: 100%;
}
.csar-part5-wrapper .csar-part5-image div img {
  height: 100%;
  width: 100%;
  object-fit: scale-down;
}
.csar-part5-wrapper .csar-part5-text-wrapper {
  width: 410px;
  text-align: center;
}
.csar-part5-wrapper .csar-part5-title {
  font-size: 26px;
  color: #fff;
}
.csar-part5-wrapper .csar-part5-content {
  font-size: 42px;
  font-weight: 500;
  line-height: 30px;
  color: #fff;
}
.csar-part5-wrapper .csar-part5-intro {
  margin-top: 16px;
  font-size: 24px;
  text-align: left;
  color: #fff;
}
.csar-part5-wrapper .csar-part5 a {
  display: block;
  margin-top: 24px;
  margin-bottom: 24px;
  background-color: #fff;
  font-size: 20px;
  border: none;
  text-align: center;
  color: #000;
}
@media screen and (max-width: 767px) {
  .csar-part5-wrapper {
    height: auto;
  }
  .csar-part5-wrapper .home-page {
    padding: 0;
  }
  .csar-part5-wrapper .csar-part5 {
    overflow: hidden;
    padding: 0 12px 64px 12px;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: inherit;
  }
  .csar-part5-wrapper .csar-part5-image {
    height: auto;
    width: 100%;
  }
  .csar-part5-wrapper .csar-part5-text-wrapper {
    padding: 24px;
    text-align: left;
  }
  .csar-part5-wrapper .csar-part5-title {
    font-size: 24px;
    line-height: 60px;
    margin-bottom: 10px;
  }
  .csar-part5-wrapper .csar-part5-content {
    font-size: 20px;
    line-height: 20px;
  }
  .csar-part5-wrapper .csar-part5-intro {
    text-align: left;
    font-size: 20px;
  }
}
.csar-part6-wrapper {
  width: 100%;
  height: 582px;
  position: relative;
  background: #fff;
  overflow: hidden;
  background-image: url("https://website-public-resource-for-all-user.oss-cn-shanghai.aliyuncs.com/ai-website/public/csar/bg.png");
  background-size: cover;
  background-position: center;
}
.csar-part6-wrapper .csar-part6 {
  width: 100%;
  position: relative;
  text-align: left;
  overflow: hidden;
  padding: 24px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.csar-part6-wrapper .csar-part6-title {
  font-size: 32px;
  color: #fff;
  font-weight: 500;
  width: 100%;
  text-align: center;
}
.csar-part6-wrapper .csar-part6-block {
  padding: 12px 48px;
}
.csar-part6-wrapper .csar-part6-block-wrapper {
  margin-top: 60px;
}
.csar-part6-wrapper .csar-part6-block-title {
  font-size: 22px;
  color: #fff;
  font-weight: 500;
  text-align: left;
}
.csar-part6-wrapper .csar-part6-block-text {
  font-size: 18px;
  margin-bottom: 36px;
}
.csar-part6-wrapper .csar-part6-block-header {
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 12px;
  color: #fff;
}
@media screen and (max-width: 1124px) {
  .csar-part6-wrapper {
    height: 782px;
  }
  .csar-part6-wrapper .csar-part6-block-text {
    font-size: 18px;
  }
}
@media screen and (max-width: 767px) {
  .csar-part6-wrapper {
    height: auto;
  }
  .csar-part6-wrapper .csar-part6 {
    padding-bottom: 36px;
  }
  .csar-part6-wrapper .csar-part6-block-wrapper {
    margin-top: 24px;
  }
  .csar-part6-wrapper .csar-part6-block {
    padding: 12px;
  }
}
.csar-part7-wrapper {
  width: 100%;
  height: auto;
  position: relative;
  text-align: center;
  background: #fff;
  overflow: hidden;
}
.csar-part7-wrapper .csar-part7 {
  width: 100%;
  position: relative;
  text-align: center;
  overflow: hidden;
  height: auto;
  padding: 24px;
}
.csar-part7-wrapper .csar-part7-title {
  font-size: 32px;
  color: #000;
  font-weight: 500;
  text-align: center;
  margin-bottom: 60px;
}
.csar-part7-wrapper .csar-part7-block-title {
  font-size: 22px;
  color: #000;
  font-weight: 500;
  text-align: center;
}
.csar-part7-wrapper .csar-part7-block-scene {
  font-size: 20px;
  margin-bottom: 6px;
}
.csar-part7-wrapper .csar-part7-image-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 24px;
}
@media screen and (max-width: 1124px) {
  .csar-part7-wrapper .csar-part7 img {
    max-height: 300px;
  }
  .csar-part7-wrapper .csar-part7-block-scene {
    font-size: 18px;
  }
}
@media screen and (max-width: 767px) {
  .csar-part7-wrapper .csar-part7 {
    padding-bottom: 36px;
  }
}
.csar-part8-wrapper {
  height: 460px;
  background: #fafafa;
}
.csar-part8-wrapper .home-page {
  padding: 0;
}
.csar-part8-wrapper .csar-part8 > p {
  text-align: center;
}
.csar-part8-wrapper .csar-part8-title-h1 {
  margin-bottom: 12px;
}
.csar-part8-wrapper .csar-part8 button {
  background-color: #000;
  color: #fff;
}
.csar-part8-wrapper .csar-part8-table-name-block {
  text-align: center;
  color: #666;
  width: 100%;
}
.csar-part8-wrapper .csar-part8-table-name {
  font-size: 24px;
}
.csar-part8-wrapper .csar-part8-table-money {
  font-size: 16px;
  margin: 8px 0 16px;
}
.csar-part8-wrapper .csar-part8-table-content {
  text-align: center;
  color: #666;
}
.csar-part8-wrapper .csar-part8-table-content-name {
  color: #666;
  text-align: center;
}
.csar-part8-wrapper.home-page-wrapper .csar-part8-title-wrapper {
  margin: 64px auto;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .csar-part8-wrapper {
    height: auto;
    background-color: #fff;
  }
  .csar-part8-wrapper .csar-part8 {
    padding: 24px 12px;
  }
  .csar-part8-wrapper .csar-part8-title-wrapper {
    margin: auto auto 24px !important;
  }
  .csar-part8-wrapper .csar-part8-wrapper {
    padding-bottom: 0;
  }
  .csar-part8-wrapper .csar-part8-table {
    margin-bottom: 24px;
  }
}
.energy-wrapper {
  padding-top: 64px;
}
