body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
textarea,
p,
blockquote,
th,
td,
hr,
button,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  margin: 0;
  padding: 0;
}
ul,
ol {
  list-style: none;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
body {
  word-wrap: break-word;
}
/* .content-wrapper > .tween-one-leaving,
.queue-anim-leaving {
  // position: absolute !important;
  // width: 100%;
} */
.video {
  max-width: 800px;
}
#react-content {
  min-height: 100%;
}
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
::-webkit-scrollbar-thumb {
  background-color: #c2c2c2;
  background-clip: padding-box;
  min-height: 28px;
}
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
textarea,
p,
blockquote,
th,
td,
hr,
button,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  margin: 0;
  padding: 0;
}
ul,
ol {
  list-style: none;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.phone-icon {
  width: 73px;
  height: 73px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
  color: #1890ff;
}
.phone-icon :hover {
  cursor: pointer;
}
.header0 {
  overflow: initial !important;
  background: rgba(0, 0, 0, 0.95);
  width: 100%;
  z-index: 999;
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.15);
  position: relative;
  top: 0;
}
.header0 .home-page {
  padding: 0 24px;
}
.header0.home-page-wrapper {
  position: fixed;
  background-color: transparent;
}
.header0.home-page-wrapper .home-page {
  max-width: 1800px;
}
.header0-logo {
  display: inline-block;
  position: relative;
  width: 150px;
  line-height: 64px;
}
.header0-logo img {
  vertical-align: middle;
  display: inline-block;
}
.header0-logo a {
  display: block;
}
.header0-menu {
  float: right;
  width: calc(100% - 150px);
}
.header0-menu > .ant-menu {
  display: flex;
  justify-content: right;
  width: 100%;
  line-height: 62px;
  background: transparent;
  color: #fff;
  height: 64px;
  border-bottom-color: transparent;
  position: relative;
}
.header0-menu > .ant-menu a {
  color: #fff;
}
.header0-menu > .ant-menu a:hover {
  color: #1890ff;
}
.header0 .ant-menu-item-selected a {
  color: #1890ff;
}
@media screen and (max-width: 767px) {
  .header0-logo {
    z-index: 101;
  }
  .header0.home-page-wrapper .home-page {
    padding: 0 24px;
  }
  .header0 .header0-menu {
    height: auto;
    float: inherit;
    position: relative;
    left: -24px;
    width: calc(100% + 48px);
    opacity: 0;
    transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), height 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .header0 .header0-menu li {
    padding: 0 24px;
  }
  .header0 .header0-menu li.ant-menu-submenu {
    padding: 0;
  }
  .header0 .header0-menu .ant-menu-submenu .ant-menu-sub {
    padding: 0 24px;
  }
  .header0-mobile-menu {
    width: 16px;
    height: 14px;
    cursor: pointer;
    position: absolute;
    top: 24px;
    right: 24px;
    z-index: 100;
  }
  .header0-mobile-menu em {
    display: block;
    width: 100%;
    height: 2px;
    background: #fff;
    margin-top: 4px;
    transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .header0-mobile-menu :first-child {
    margin-top: 0;
  }
  .header0 .ant-menu {
    height: 0;
    overflow: hidden;
    flex-wrap: wrap;
    background: #000;
  }
  .header0 .ant-menu li {
    width: 100%;
  }
  .header0 .ant-menu .ant-menu-item-selected {
    border: none;
  }
  .header0 .ant-menu a {
    color: #fff;
  }
  .header0 .ant-menu a:hover {
    color: #fff;
  }
  .header0 .ant-menu .ant-menu-item-selected a {
    color: #fff;
  }
  .header0 .open {
    height: auto;
  }
  .header0 .open .ant-menu {
    height: auto;
  }
  .header0 .open .header0-mobile-menu em:nth-child(1) {
    -webkit-transform: translateY(6px) rotate(45deg);
            transform: translateY(6px) rotate(45deg);
  }
  .header0 .open .header0-mobile-menu em:nth-child(2) {
    opacity: 0;
  }
  .header0 .open .header0-mobile-menu em:nth-child(3) {
    -webkit-transform: translateY(-6px) rotate(-45deg);
            transform: translateY(-6px) rotate(-45deg);
  }
  .header0 .open > .header0-menu {
    opacity: 1;
    pointer-events: auto;
  }
}

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
textarea,
p,
blockquote,
th,
td,
hr,
button,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  margin: 0;
  padding: 0;
}
ul,
ol {
  list-style: none;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-popover {
  position: fixed;
}
.ant-popover-title {
  color: rgb(46 46 46);
  font-size: 1rem;
  line-height: 1.5rem;
}
.ant-popover-inner-content {
  color: rgb(46 46 46);
  font-size: 1.125rem;
  line-height: 1.75rem;
  cursor: pointer;
}

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
textarea,
p,
blockquote,
th,
td,
hr,
button,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  margin: 0;
  padding: 0;
}
ul,
ol {
  list-style: none;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.footer1-wrapper {
  background: #000;
  overflow: hidden;
  position: relative;
  min-height: 360px;
  color: #999;
}
.footer1-wrapper .footer1 .home-page {
  padding: 24px;
  max-width: 1800px;
}
.footer1-wrapper .footer1 .backup-num {
  text-align: center;
  font-size: 16px;
  padding-bottom: 24px;
}
.footer1-wrapper .block {
  padding: 0 32px;
}
.footer1-wrapper .block .logo {
  max-width: 300px;
}
.footer1-wrapper .block .slogan {
  text-align: center;
  font-size: 14px;
  margin-top: -20px;
}
.footer1-wrapper .block .qrcode {
  display: flex;
}
.footer1-wrapper .block .qrcode :first-child {
  margin-right: 24px;
}
.footer1-wrapper .block > h2 {
  margin-bottom: 24px;
  color: #ccc;
}
.footer1-wrapper .block a {
  color: #999;
  margin-bottom: 12px;
  float: left;
  clear: both;
}
.footer1-wrapper .block a:hover {
  color: #1890ff;
}
.footer1-wrapper .copyright-wrapper {
  width: 100%;
  border-top: 1px solid rgba(233, 233, 233, 0.1);
}
.footer1-wrapper .copyright-wrapper .home-page {
  padding: 0 24px;
  overflow: hidden;
}
.footer1-wrapper .copyright-wrapper .copyright {
  height: 40px;
  text-align: center;
  line-height: 40px;
}
@media screen and (max-width: 767px) {
  .footer1 {
    min-height: 550px;
  }
  .footer1-wrapper .footer1 .home-page {
    padding: 64px 24px 32px;
  }
  .footer1 .logo {
    margin: 0 auto 24px;
  }
  .footer1 .block {
    text-align: center;
    margin-bottom: 32px;
    padding: 0;
  }
  .footer1 > ul {
    width: 90%;
    margin: 20px auto 0;
    padding: 10px 0;
  }
  .footer1 > ul > li {
    width: 100%;
  }
  .footer1 > ul > li h2 {
    margin-bottom: 10px;
  }
  .footer1 > ul > li li {
    display: inline-block;
    margin-right: 10px;
  }
  .footer1 .copyright-wrapper .home-page {
    padding: 0;
  }
  .footer1 .copyright-wrapper .home-page .copyright {
    font-size: 12px;
  }
  .footer1 .copyright span {
    width: 90%;
  }
}

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
textarea,
p,
blockquote,
th,
td,
hr,
button,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  margin: 0;
  padding: 0;
}
ul,
ol {
  list-style: none;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.footer2-wrapper {
  background-color: #000;
  height: auto;
  overflow: hidden;
  display: flex;
  justify-content: center;
}
.footer2-wrapper .footer2 {
  height: 100%;
  padding: 0 24px;
  line-height: 80px;
  text-align: center;
  color: #999;
  position: relative;
}
.footer2-wrapper .copyright {
  float: right;
}
.footer2-wrapper .copyright > * {
  display: inline-block;
}
.footer2-wrapper .copyright-logo {
  width: 16px;
  margin-right: 8px;
}
.footer2-wrapper .copyright-logo img {
  width: 100%;
}
.footer2-wrapper .copyright-line {
  padding: 0 12px;
  margin: 0 12px;
}
.footer2-wrapper .links {
  float: left;
  display: flex;
  align-items: center;
  height: 100%;
}
.footer2-wrapper .links a {
  height: 21px;
  display: inline-block;
  margin-right: 32px;
}
.footer2-wrapper .links a img {
  display: block;
}
@media screen and (max-width: 767px) {
  .footer2-wrapper .footer2 {
    font-size: 12px;
  }
  .footer2-wrapper .footer2.home-page {
    padding: 0;
  }
  .footer2-wrapper .footer2 > div {
    width: 100%;
    margin: auto;
  }
}

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
textarea,
p,
blockquote,
th,
td,
hr,
button,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  margin: 0;
  padding: 0;
}
ul,
ol {
  list-style: none;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
body {
  word-wrap: break-word;
}
/* .content-wrapper > .tween-one-leaving,
.queue-anim-leaving {
  // position: absolute !important;
  // width: 100%;
} */
.video {
  max-width: 800px;
}
#react-content {
  min-height: 100%;
}
.home-page-wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
}
.home-page-wrapper .home-page {
  height: 100%;
  max-width: 1200px;
  position: relative;
  margin: auto;
  will-change: transform;
}
.home-page-wrapper .title-wrapper > h1,
.home-page-wrapper > h1 {
  font-size: 32px;
  color: rgba(0, 0, 0, 0.65);
  margin-bottom: 16px;
}
.home-page-wrapper .title-wrapper {
  margin: 0 auto 64px;
  text-align: center;
}
.home-page {
  padding: 128px 24px;
}
@media screen and (max-width: 767px) {
  .home-page-wrapper .home-page {
    padding: 56px 24px;
  }
  .home-page-wrapper .home-page > h1 {
    font-size: 24px;
    margin: 0 auto 32px;
  }
  .home-page-wrapper .home-page > h1.title-h1 {
    margin-bottom: 8px;
  }
  .home-page-wrapper .home-page > p {
    margin-bottom: 32px;
  }
}
.form-page-wrapper {
  overflow: hidden;
}
.form-page-wrapper .form-page {
  overflow: hidden;
  padding: 64px 24px;
}
.form-page-wrapper .form-page > .title-wrapper {
  margin: 0 auto 48px;
}
.content10-wrapper {
  height: 480px;
  background: url("https://website-public-resource-for-all-user.oss-cn-shanghai.aliyuncs.com/ai-website/public/location.png") no-repeat 50%;
  background-size: cover;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.content10-wrapper .icon-wrapper {
  text-align: center;
  position: relative;
  cursor: pointer;
}
.content10-wrapper .icon-wrapper img {
  display: block;
}
.content10-wrapper .icon {
  position: relative;
  z-index: 1;
  -webkit-animation: BeatAnim 2s ease-in-out infinite;
          animation: BeatAnim 2s ease-in-out infinite;
}
.content10-wrapper .icon-shadow {
  display: inline-block;
  position: relative;
  top: -12px;
  z-index: 0;
  -webkit-animation: ScaleAnim 2s ease-in-out infinite;
          animation: ScaleAnim 2s ease-in-out infinite;
  -webkit-transform-origin: 50%;
          transform-origin: 50%;
}
.content10-wrapper .map-tip {
  position: absolute;
  width: 330px;
  background: #fff;
  padding: 16px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(13, 26, 38, 0.12);
  left: 50%;
  top: 50%;
  margin-left: 30px;
  margin-top: -60px;
  font-size: 14px;
  z-index: 10;
  -webkit-transform-origin: 0 50%;
          transform-origin: 0 50%;
  text-align: left;
}
.content10-wrapper .map-tip h2 {
  font-size: 16px;
  color: #0d1a26;
  margin-bottom: 8px;
}
@media screen and (max-width: 767px) {
  .content10-wrapper {
    padding-bottom: 0;
  }
}
@-webkit-keyframes BeatAnim {
  0%,
  25%,
  35%,
  45% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  15% {
    -webkit-transform: translateY(-30px);
            transform: translateY(-30px);
  }
  30% {
    -webkit-transform: translateY(-15px);
            transform: translateY(-15px);
  }
  40% {
    -webkit-transform: translateY(-7px);
            transform: translateY(-7px);
  }
}
@keyframes BeatAnim {
  0%,
  25%,
  35%,
  45% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  15% {
    -webkit-transform: translateY(-30px);
            transform: translateY(-30px);
  }
  30% {
    -webkit-transform: translateY(-15px);
            transform: translateY(-15px);
  }
  40% {
    -webkit-transform: translateY(-7px);
            transform: translateY(-7px);
  }
}
@-webkit-keyframes ScaleAnim {
  0%,
  25%,
  35%,
  45% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  15% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  30% {
    -webkit-transform: scale(0.7);
            transform: scale(0.7);
  }
  40% {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
  }
}
@keyframes ScaleAnim {
  0%,
  25%,
  35%,
  45% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  15% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  30% {
    -webkit-transform: scale(0.7);
            transform: scale(0.7);
  }
  40% {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
  }
}
.connect-wrapper {
  padding-top: 64px;
}
.form-page {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.form-page .contact-left,
.form-page .contact-right {
  min-width: 624px;
}
.form-page .contact-left {
  padding-right: 40px;
  width: 50%;
  text-align: left;
}
.form-page .contact-right {
  width: 30%;
  margin-top: 100px;
}
@media screen and (max-width: 767px) {
  .form-page {
    width: 100%;
    padding: 0 20px;
  }
  .form-page .contact-left,
  .form-page .contact-right {
    width: 100%;
    min-width: auto;
  }
}

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
textarea,
p,
blockquote,
th,
td,
hr,
button,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  margin: 0;
  padding: 0;
}
ul,
ol {
  list-style: none;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.banner0 {
  width: 100%;
  height: 100vh;
  position: relative;
  text-align: center;
  border-color: #666;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
}
.banner0 video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.banner0 .banner0-text-wrapper {
  display: inline-block;
  position: absolute;
  top: 34%;
  margin: auto;
  left: 0;
  right: 0;
  font-size: 14px;
  color: #fff;
}
.banner0 .banner0-text-wrapper > .queue-anim-leaving {
  position: relative !important;
}
.banner0 .banner0-title {
  width: 350px;
  left: 30px;
  min-height: 60px;
  margin: auto;
  display: inline-block;
  font-size: 40px;
  position: relative;
}
.banner0 .banner0-content {
  margin-bottom: 20px;
  word-wrap: break-word;
  min-height: 24px;
}
.banner0 .banner0-content div h1 {
  color: #fff;
  font-size: 100px;
}
.banner0 .banner0-content div h3 {
  color: #f8f8f8;
  font-size: 30px;
}
.banner0 .banner0-content div span {
  color: red;
}
.banner0 .banner0-button {
  border: 1px solid #fff;
  color: #fff;
  background: transparent;
  box-shadow: 0 0 0 transparent;
  line-height: 40px;
  font-size: 16px;
  height: 40px;
  transition: background 0.45s cubic-bezier(0.215, 0.61, 0.355, 1), box-shadow 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.banner0 .banner0-button:hover {
  color: #fff;
  border-color: #fff;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 10px rgba(50, 250, 255, 0.75);
}
.banner0 .banner0-button:focus {
  color: #fff;
  border-color: #fff;
}
.banner0 .banner0-button.queue-anim-leaving {
  width: auto;
}
.banner0 .banner0-icon {
  bottom: 20px;
  font-size: 24px;
  position: absolute;
  left: 50%;
  margin-left: -12px;
  color: #fff;
}
@media screen and (max-width: 1024px) {
  .banner0 .banner0-content div h1 {
    color: #fff;
    font-size: 70px;
  }
  .banner0 .banner0-content div h3 {
    color: #f8f8f8;
    font-size: 30px;
  }
  .banner0 .banner0-content div span {
    color: red;
  }
}
@media screen and (max-width: 767px) {
  .banner0 {
    background-attachment: inherit;
  }
  .banner0 .banner0-text-wrapper {
    width: 90%;
  }
  .banner0 .banner0-title {
    width: 90%;
    left: 0;
  }
  .banner0 .banner0-content div h1 {
    color: #fff;
    font-size: 34px;
  }
  .banner0 .banner0-content div h3 {
    margin-top: 24px;
    color: #f8f8f8;
    font-size: 18px;
  }
  .banner0 .banner0-content div span {
    color: red;
  }
}
.banner12-wrapper {
  height: 320px;
  width: 100%;
  position: relative;
  background-color: #000;
}
.banner12-wrapper .banner12-title-abs {
  position: absolute;
  top: 10%;
  left: 0;
  right: 0;
  bottom: 0;
  font-size: 50px;
  font-family: cursive, STXihei, "\534E\6587\7EC6\9ED1", "Microsoft YaHei", "\5FAE\8F6F\96C5\9ED1";
  font-weight: bold;
  text-align: center;
  color: #fff;
}
.banner12-wrapper .banner12-img-abs {
  position: absolute;
  bottom: 0;
  height: 220px;
  left: 0;
  right: 0;
}
.banner12-wrapper .banner12-img-abs img {
  object-fit: cover;
  height: 100%;
  width: 100%;
  -webkit-transform: scale(0.7);
          transform: scale(0.7);
}
.banner12-wrapper .banner12-img {
  height: 100%;
  width: 100%;
}
.banner12-wrapper .banner12-img img {
  opacity: 0.6;
  object-fit: cover;
  height: 100%;
  width: 100%;
}
@media screen and (max-width: 767px) {
  .banner12-wrapper {
    height: 220px;
  }
  .banner12-wrapper .banner12-img-abs {
    bottom: -20px;
  }
  .banner12-wrapper .banner12-img-abs img {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  .banner12-wrapper .banner12-title-abs {
    font-size: 24px;
    top: 20%;
  }
}
.banner2 {
  padding-top: 100px;
  width: 100%;
  position: relative;
  text-align: center;
  border-color: #666;
}
.banner2 .banner2-text-wrapper {
  display: inline-block;
  position: absolute;
  top: 2%;
  margin: auto;
  left: 0;
  right: 0;
  font-size: 14px;
  color: #fff;
}
.banner2 .banner2-text-wrapper > .queue-anim-leaving {
  position: relative !important;
}
.banner2 .banner2-title {
  width: 350px;
  left: 30px;
  min-height: 60px;
  margin: auto;
  display: inline-block;
  font-size: 40px;
  position: relative;
}
.banner2 .banner2-content {
  margin-bottom: 20px;
  word-wrap: break-word;
  min-height: 24px;
}
.banner2 .banner2-content div h3 {
  color: #f8f8f8;
}
.banner2 .banner2-content div span {
  color: red;
}
.banner2 .banner2-button {
  border: 1px solid #fff;
  color: #fff;
  background: transparent;
  box-shadow: 0 0 0 transparent;
  line-height: 40px;
  font-size: 16px;
  height: 40px;
  transition: background 0.45s cubic-bezier(0.215, 0.61, 0.355, 1), box-shadow 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.banner2 .banner2-button:hover {
  color: #fff;
  border-color: #fff;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 10px rgba(50, 250, 255, 0.75);
}
.banner2 .banner2-button:focus {
  color: #fff;
  border-color: #fff;
}
.banner2 .banner2-button.queue-anim-leaving {
  width: auto;
}
.banner2 .banner2-icon {
  bottom: 20px;
  font-size: 24px;
  position: absolute;
  left: 50%;
  margin-left: -12px;
  color: #fff;
}
@media screen and (max-width: 1024px) {
  .banner2 .banner2-content div h1 {
    color: #fff;
  }
  .banner2 .banner2-content div h3 {
    color: #f8f8f8;
    font-size: 30px;
  }
  .banner2 .banner2-content div span {
    color: red;
  }
}
@media screen and (max-width: 767px) {
  .banner2 {
    background-attachment: inherit;
  }
  .banner2 .banner2-text-wrapper {
    width: 90%;
  }
  .banner2 .banner2-title {
    width: 90%;
    left: 0;
  }
  .banner2 .banner2-content div h1 {
    color: rgba(0, 0, 0, 0.65);
  }
  .banner2 .banner2-content div h3 {
    color: #f8f8f8;
    font-size: 14px;
  }
  .banner2 .banner2-content div span {
    color: red;
  }
}
.content0-wrapper {
  margin-top: 20px;
  height: 682px;
  overflow: hidden;
}
.content0-wrapper .content0 {
  overflow: hidden;
  height: 100%;
  padding: 64px 24px;
}
.content0-wrapper .content0 > .title-wrapper {
  margin: 0 auto 48px;
}
.content0-wrapper .content0 .block-wrapper {
  position: relative;
  height: 100%;
  overflow: hidden;
  top: 25%;
  padding: 20px 0;
}
.content0-wrapper .content0 .block-wrapper .block {
  padding: 0 4%;
  display: inline-block;
  text-align: center;
  height: 300px;
  margin-bottom: 48px;
}
.content0-wrapper .content0 .block-wrapper .block.queue-anim-leaving {
  position: relative !important;
}
.content0-wrapper .content0 .block-wrapper .block .icon {
  width: 160px;
  height: 160px;
  margin: auto;
  display: flex;
  align-items: center;
}
.content0-wrapper .content0 .block-wrapper .block > h3 {
  line-height: 32px;
  font-size: 30px;
  margin: 28px auto 36px;
}
.content0-wrapper .content0 .block-wrapper .block > p {
  padding: 0 16px;
}
@media screen and (max-width: 767px) {
  .content0-wrapper {
    height: 1280px;
  }
}
.teams1-wrapper {
  min-height: 446px;
  overflow: hidden;
}
.teams1-wrapper .box-shadow-d {
  box-shadow: 0px 10px 10px #f3efef;
  padding: 20px 0;
  width: 300px;
  margin: auto;
  height: 410px;
}
.teams1-wrapper .teams1 {
  overflow: hidden;
  height: 100%;
  padding: 64px 24px;
}
.teams1-wrapper .teams1 > .title-wrapper {
  margin: 0 auto 48px;
}
.teams1-wrapper .teams1 .block-wrapper {
  position: relative;
  height: 100%;
  overflow: hidden;
  padding: 0 0;
}
.teams1-wrapper .teams1 .block-wrapper .block {
  display: inline-block;
  text-align: center;
  margin-bottom: 48px;
  position: relative;
}
.teams1-wrapper .teams1 .block-wrapper .block.queue-anim-leaving {
  position: relative !important;
}
.teams1-wrapper .teams1 .block-wrapper .block .inner-title {
  position: absolute;
  top: 90px;
  font-size: 30px;
  font-weight: 500;
  left: 0;
  right: 0;
  color: #fff;
}
.teams1-wrapper .teams1-image,
.teams1-wrapper .teams1-title,
.teams1-wrapper .teams1-job,
.teams1-wrapper .teams1-content {
  width: 300px;
  margin: auto;
  line-height: 1.5;
}
.teams1-wrapper .teams1-image {
  position: relative;
  color: #404040;
  background-color: #000;
}
.teams1-wrapper .teams1-image img {
  width: 100%;
  opacity: 0.86;
}
.teams1-wrapper .teams1-title {
  font-size: 24px;
  margin: 24px 0;
  text-align: left;
  padding-left: 16px;
}
.teams1-wrapper .teams1-job {
  margin: 8px auto;
}
.teams1-wrapper .teams1-job,
.teams1-wrapper .teams1-content {
  padding: 0 16px;
  text-align: left;
  font-size: 14px;
  color: #919191;
}
.teams1-wrapper .teams1-content {
  padding-bottom: 24px;
}
@media screen and (max-width: 767px) {
  .teams1-wrapper {
    min-height: 1440px;
  }
}
.templates-wrapper {
  padding-bottom: 0px;
}
.nav-header-change {
  background-color: #000 !important;
}

/* FloatingWindow.css */
.floating-window {
  z-index: 10000;
  position: fixed;
  /*background-color: red;*/
  width: 300px;
  /* 根据需要调整 */
  height: 180px;
  /* 根据需要调整 */
  background-color: white;
  /*border: 1px solid #ccc;*/
  /*box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);*/
  border-radius: 5px;
  display: flex;
  align-items: flex-start;
  /*justify-content: center;*/
  flex-wrap: wrap;
  cursor: pointer;
  /* 鼠标悬停时显示为可点击 */
}
.floating-window-content {
  flex-grow: 1;
  display: flex;
  align-items: center;
}
.close-button {
  position: fixed;
  width: 15px;
  height: 15px;
  border-radius: 5px 0 0 0 ;
  color: #000;
  padding-left: 5px;
  /*background-color: #abafab;*/
  /*margin-left: auto;*/
}
.close-button:hover {
  color: #333;
}

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
textarea,
p,
blockquote,
th,
td,
hr,
button,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  margin: 0;
  padding: 0;
}
ul,
ol {
  list-style: none;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
body {
  word-wrap: break-word;
}
/* .content-wrapper > .tween-one-leaving,
.queue-anim-leaving {
  // position: absolute !important;
  // width: 100%;
} */
.video {
  max-width: 800px;
}
#react-content {
  min-height: 100%;
}
.part0 {
  width: 100%;
  height: 100vh;
  position: relative;
  text-align: center;
  border-color: #666;
  background-color: #000;
}
.part0 .part0-text-wrapper {
  display: inline-block;
  position: absolute;
  top: 34%;
  margin: auto;
  left: 0;
  right: 0;
  font-size: 14px;
  color: #fff;
}
.part0 .part0-text-wrapper > .queue-anim-leaving {
  position: relative !important;
}
.part0 .part0-content {
  margin-bottom: 20px;
  word-wrap: break-word;
  min-height: 24px;
  color: #fff;
  background-image: url('https://website-public-resource-for-all-user.oss-cn-shanghai.aliyuncs.com/ai-website/public/color.jpeg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top center;
  background-clip: text;
  -webkit-background-clip: text;
  height: 520px;
}
.part0 .part0-content h1,
.part0 .part0-content h3 {
  color: transparent;
}
.part0 .part0-content h1 {
  font-size: 80px;
}
.part0 .part0-content h3 {
  font-size: 46px;
}
.part0 .part0-icon {
  bottom: 20px;
  font-size: 24px;
  position: absolute;
  left: 50%;
  margin-left: -12px;
  color: #fff;
}
@media screen and (max-width: 1124px) and (min-width: 767px) {
  .part0 .part0-content {
    height: 340px;
  }
  .part0 .part0-content h1 {
    font-size: 50px;
  }
}
@media screen and (max-width: 767px) {
  .part0 {
    background-attachment: inherit;
  }
  .part0 .part0-text-wrapper {
    width: 350px;
  }
  .part0 .part0-title {
    width: 90%;
    left: 0;
  }
  .part0 .part0-content h1 {
    margin-top: 12px;
    font-size: 46px;
    line-height: 1.2;
  }
  .part0 .part0-content h3 {
    font-size: 30px;
  }
}
.beauty-part1 {
  width: 100%;
  height: 782px;
  position: relative;
  text-align: center;
  background: #fff;
  display: flex;
  align-items: center;
  overflow: hidden;
}
.beauty-part1 .home-page {
  height: auto;
}
.beauty-part1 .beauty-part1-page {
  padding: 36px 0 0;
}
.beauty-part1-title,
.beauty-part1-title-sub {
  font-size: 90px;
  display: inline-block;
  line-height: 1.5;
}
.beauty-part1-title-sub {
  margin-left: 18px;
}
.beauty-part1 .color-0 {
  color: #000;
}
.beauty-part1-content {
  font-size: 40px;
  line-height: 20px;
  color: #000;
  margin: 8px auto 16px;
  margin-bottom: 36px;
}
.beauty-part1-a {
  font-size: 22px;
}
.beauty-part1-image,
.beauty-part1-image-mobile {
  width: 100%;
  overflow: hidden;
  margin: 0 auto;
}
@media screen and (max-width: 1124px) {
  .beauty-part1-title,
  .beauty-part1-title-sub {
    font-size: 48px;
  }
  .beauty-part1-title-sub {
    margin-left: 12px;
  }
  .beauty-part1-a {
    font-size: 16px;
  }
  .beauty-part1-content {
    font-size: 32px;
  }
}
@media screen and (max-width: 767px) {
  .beauty-part1 {
    height: auto;
  }
  .beauty-part1 .beauty-part1-page.home-page {
    padding: 64px 12px 48px;
  }
  .beauty-part1-title {
    font-size: 50px;
    line-height: 60px;
    margin-bottom: 10px;
  }
  .beauty-part1-title-sub {
    font-size: 20px;
    line-height: 20px;
    margin-left: 0px;
  }
  .beauty-part1-title,
  .beauty-part1-title-sub {
    display: block;
  }
  .beauty-part1 .color-0 {
    color: #000;
  }
  .beauty-part1-a {
    margin-top: 24px;
    margin-bottom: 24px;
    background-color: #fff;
    font-size: 20px;
    border: none;
    text-align: center;
  }
  .beauty-part1-content {
    font-size: 20px;
    margin-top: 16px;
  }
  .beauty-part1-image {
    width: 100%;
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    margin-top: 24px;
  }
}
.beauty-part2-wrapper {
  height: 482px;
  background: #fff;
}
.beauty-part2-wrapper .beauty-part2 {
  width: 100%;
  height: 100%;
  position: relative;
  text-align: center;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5vh 0 5vh 0;
}
.beauty-part2-wrapper .beauty-part2-image {
  background-color: #fafafa;
  width: 55%;
  overflow: hidden;
}
.beauty-part2-wrapper .beauty-part2-text-wrapper {
  width: 360px;
  text-align: center;
}
.beauty-part2-wrapper .beauty-part2-title {
  font-size: 70px;
  color: #000;
}
.beauty-part2-wrapper .beauty-part2-content {
  font-size: 42px;
  font-weight: 500;
  line-height: 30px;
  color: #000;
}
.beauty-part2-wrapper .beauty-part2-intro {
  margin-top: 16px;
  font-size: 24px;
  color: #000;
}
.beauty-part2-wrapper .beauty-part2 a {
  display: block;
  margin-top: 24px;
  margin-bottom: 24px;
  background-color: #fff;
  font-size: 20px;
  border: none;
  text-align: center;
  color: #000;
}
@media screen and (max-width: 767px) {
  .beauty-part2-wrapper {
    height: 500px;
  }
  .beauty-part2-wrapper .beauty-part2 {
    overflow: hidden;
    padding: 12px;
    margin: auto;
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: center;
  }
  .beauty-part2-wrapper .beauty-part2-text-wrapper {
    margin-top: 24px;
    margin-left: 0px;
  }
  .beauty-part2-wrapper .beauty-part2-title {
    font-size: 50px;
    line-height: 60px;
    margin-bottom: 10px;
  }
  .beauty-part2-wrapper .beauty-part2-content {
    font-size: 20px;
    line-height: 20px;
  }
  .beauty-part2-wrapper .beauty-part2-intro {
    font-size: 20px;
  }
  .beauty-part2-wrapper .beauty-part2-image {
    width: 100%;
  }
}
.beauty-part3-wrapper {
  height: 482px;
  background: #fff;
}
.beauty-part3-wrapper .beauty-part3 {
  width: 100%;
  height: 100%;
  position: relative;
  text-align: center;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.beauty-part3-wrapper .beauty-part3-image {
  width: 55%;
  overflow: hidden;
}
.beauty-part3-wrapper .beauty-part3-text-wrapper {
  width: 360px;
  text-align: left;
}
.beauty-part3-wrapper .beauty-part3-title {
  font-size: 70px;
  color: #000;
}
.beauty-part3-wrapper .beauty-part3-content {
  font-size: 42px;
  font-weight: 500;
  line-height: 30px;
  color: #000;
}
.beauty-part3-wrapper .beauty-part3-intro {
  margin-top: 16px;
  font-size: 24px;
  color: #000;
}
.beauty-part3-wrapper .beauty-part3 a {
  display: block;
  margin-top: 24px;
  margin-bottom: 24px;
  background-color: #fff;
  font-size: 20px;
  border: none;
  text-align: center;
  color: #000;
}
@media screen and (max-width: 767px) {
  .beauty-part3-wrapper {
    height: 520px;
  }
  .beauty-part3-wrapper .beauty-part3 {
    overflow: hidden;
    padding: 12px;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .beauty-part3-wrapper .beauty-part3-text-wrapper {
    margin-top: 24px;
    text-align: center;
    margin-left: 0px;
  }
  .beauty-part3-wrapper .beauty-part3-title {
    font-size: 50px;
    line-height: 60px;
    margin-bottom: 10px;
  }
  .beauty-part3-wrapper .beauty-part3-content {
    font-size: 20px;
    line-height: 20px;
  }
  .beauty-part3-wrapper .beauty-part3-intro {
    font-size: 20px;
  }
  .beauty-part3-wrapper .beauty-part3-image {
    width: 100%;
    overflow: hidden;
  }
}
.part4 {
  width: 100%;
  height: 400px;
  position: relative;
  text-align: center;
  border-color: #666;
  background-color: #000;
}
.part4 .part4-text-wrapper {
  display: inline-block;
  position: absolute;
  top: 34%;
  margin: auto;
  left: 0;
  right: 0;
  font-size: 14px;
  color: #fff;
}
.part4 .part4-text-wrapper > .queue-anim-leaving {
  position: relative !important;
}
.part4 .part4-content {
  margin-bottom: 20px;
  word-wrap: break-word;
  min-height: 24px;
  color: #fff;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
  background-clip: text;
  -webkit-background-clip: text;
  height: 350px;
}
.part4 .part4-content h1,
.part4 .part4-content h3 {
  color: transparent;
}
.part4 .part4-content h1 {
  font-size: 50px;
}
.part4 .part4-content h3 {
  font-size: 50px;
}
.part4 .part4-icon {
  bottom: 20px;
  font-size: 24px;
  position: absolute;
  left: 50%;
  margin-left: -12px;
  color: #fff;
}
@media screen and (max-width: 1124px) and (min-width: 767px) {
  .part4 .part4-content {
    height: 340px;
  }
  .part4 .part4-content h1 {
    font-size: 50px;
  }
}
@media screen and (max-width: 767px) {
  .part4 {
    height: 260px;
    background-attachment: inherit;
  }
  .part4 .part4-text-wrapper {
    width: 90%;
  }
  .part4 .part4-title {
    width: 90%;
    left: 0;
  }
  .part4 .part4-content {
    height: 200px;
  }
  .part4 .part4-content h1 {
    font-size: 30px;
  }
  .part4 .part4-content h3 {
    font-size: 26px;
  }
}

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
textarea,
p,
blockquote,
th,
td,
hr,
button,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  margin: 0;
  padding: 0;
}
ul,
ol {
  list-style: none;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.case-wrapper-comp-wrapper {
  overflow: hidden;
  position: relative;
  will-change: transform;
  background-color: #fafafa;
}
.case-wrapper-comp-wrapper .box-shadow-d {
  box-shadow: 0px 10px 10px #cfcfcf;
  padding: 20px 0;
  width: 300px;
  margin: auto;
  height: 278px;
}
.case-wrapper-comp-wrapper .center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.case-wrapper-comp-wrapper .center p {
  font-size: 28px;
  color: #000;
}
.case-wrapper-comp-wrapper .case-wrapper-comp {
  max-width: 1200px;
  margin: auto;
  overflow: hidden;
  height: 100%;
  padding: 64px 24px;
}
.case-wrapper-comp-wrapper .case-wrapper-comp > .title-wrapper {
  margin: 0 auto 48px;
  text-align: center;
}
.case-wrapper-comp-wrapper .case-wrapper-comp .block-wrapper {
  position: relative;
  height: 100%;
  overflow: hidden;
  padding: 0 0;
}
.case-wrapper-comp-wrapper .case-wrapper-comp .block-wrapper .block {
  display: inline-block;
  text-align: center;
  margin-bottom: 48px;
  position: relative;
}
.case-wrapper-comp-wrapper .case-wrapper-comp .block-wrapper .block.queue-anim-leaving {
  position: relative !important;
}
.case-wrapper-comp-wrapper .case-wrapper-comp .block-wrapper .block .inner-title {
  position: absolute;
  top: 90px;
  font-size: 30px;
  font-weight: 500;
  left: 0;
  right: 0;
  color: #fff;
}
.case-wrapper-comp-wrapper .case-wrapper-comp-image,
.case-wrapper-comp-wrapper .case-wrapper-comp-title {
  width: 280px;
  margin: auto;
  line-height: 1.5;
}
.case-wrapper-comp-wrapper .case-wrapper-comp-image {
  position: relative;
  color: #404040;
  background-color: #fff;
  height: 170px;
}
.case-wrapper-comp-wrapper .case-wrapper-comp-image img {
  width: 100%;
  height: 100%;
  opacity: 0.86;
}
.case-wrapper-comp-wrapper .case-wrapper-comp-title {
  font-size: 18px;
  margin: 24px 0 0 0;
  text-align: center;
  padding-left: 16px;
  font-weight: 400;
}
.case-wrapper-comp-wrapper .case-wrapper-comp .title-1 {
  font-size: 40px;
  margin-top: 80px;
}
@media screen and (max-width: 767px) {
  .case-wrapper-comp-wrapper {
    min-height: 742px;
  }
}

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
textarea,
p,
blockquote,
th,
td,
hr,
button,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  margin: 0;
  padding: 0;
}
ul,
ol {
  list-style: none;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
body {
  word-wrap: break-word;
}
/* .content-wrapper > .tween-one-leaving,
.queue-anim-leaving {
  // position: absolute !important;
  // width: 100%;
} */
.video {
  max-width: 800px;
}
#react-content {
  min-height: 100%;
}
.lipstick-part0-wrapper {
  height: 782px;
  margin-top: 64px;
  background: #fff;
}
.lipstick-part0-wrapper .lipstick-part0 {
  width: 100%;
  height: 100%;
  position: relative;
  text-align: center;
  overflow: hidden;
}
.lipstick-part0-wrapper .lipstick-part0 .title-h1,
.lipstick-part0-wrapper .lipstick-part0 .title-h2 {
  display: inline-block;
  line-height: 1.5;
  font-size: 90px;
  color: #ae285c;
}
.lipstick-part0-wrapper .lipstick-part0 .title-h2 {
  margin-left: 18px;
}
.lipstick-part0-wrapper .lipstick-part0 .title-content {
  font-size: 40px;
  color: #000;
}
.lipstick-part0-wrapper .lipstick-part0 button {
  margin-top: 22px;
  background-color: #d21975;
  color: #fff;
}
.lipstick-part0-wrapper .lipstick-part0-image,
.lipstick-part0-wrapper .lipstick-part0-image-mobile {
  width: 100%;
  max-height: 60%;
  max-width: 1200px;
  overflow: hidden;
  margin: auto;
}
.lipstick-part0-wrapper .lipstick-part0-icon {
  bottom: 20px;
  font-size: 24px;
  position: absolute;
  left: 50%;
  margin-left: -12px;
  color: #000;
}
@media screen and (max-width: 1124px) {
  .lipstick-part0-wrapper {
    height: 682px;
  }
  .lipstick-part0-wrapper .lipstick-part0 .title-h1,
  .lipstick-part0-wrapper .lipstick-part0 .title-h2 {
    line-height: 1;
    font-size: 48px;
    margin-bottom: 24px;
  }
  .lipstick-part0-wrapper .lipstick-part0 .title-content {
    font-size: 32px;
  }
  .lipstick-part0-wrapper .lipstick-part0 button {
    font-size: 16px;
  }
}
@media screen and (max-width: 767px) {
  .lipstick-part0-wrapper {
    min-height: 300px;
    height: auto;
    margin-top: 24px;
  }
  .lipstick-part0-wrapper .lipstick-part0 {
    overflow: hidden;
    padding: 24px 12px;
    margin: auto;
  }
  .lipstick-part0-wrapper .lipstick-part0 .title-h1,
  .lipstick-part0-wrapper .lipstick-part0 .title-h2 {
    display: block;
    font-size: 50px;
    line-height: 1;
    margin-bottom: 0;
    color: #ae285c;
  }
  .lipstick-part0-wrapper .lipstick-part0 .title-h2 {
    margin: 12px auto;
    font-size: 24px;
  }
  .lipstick-part0-wrapper .lipstick-part0 .title-content {
    font-size: 1.4em;
    margin-bottom: 28px;
  }
  .lipstick-part0-wrapper .lipstick-part0 button {
    margin-top: 12px;
  }
  .lipstick-part0-wrapper .lipstick-part0-image {
    -webkit-transform: scale(1.2) !important;
            transform: scale(1.2) !important;
    margin: 24px 0;
  }
}
.lipstick-part1-wrapper {
  width: 100%;
  height: 782px;
  position: relative;
  text-align: center;
  background: #fff;
  overflow: hidden;
  background-image: url("https://website-public-resource-for-all-user.oss-cn-shanghai.aliyuncs.com/ai-website/public/lipstick-img-1.png");
  background-size: cover;
  background-position: center;
}
.lipstick-part1-wrapper .part1-page {
  display: inline-block;
  position: absolute;
  top: 34%;
  margin: auto;
  left: 0;
  right: 0;
  font-size: 14px;
  color: #fff;
}
.lipstick-part1-wrapper .part1-page > .queue-anim-leaving {
  position: relative !important;
}
.lipstick-part1-wrapper .part1-page h3 {
  font-size: 68px;
  color: #fff;
}
.lipstick-part1-wrapper .part1-page h1 {
  color: #FFF;
  font-size: 82px;
}
@media screen and (max-width: 1124px) {
  .lipstick-part1-wrapper .part1-page h3 {
    font-size: 50px;
  }
  .lipstick-part1-wrapper .part1-page h1 {
    font-size: 60px;
  }
}
@media screen and (max-width: 767px) {
  .lipstick-part1-wrapper {
    height: 400px;
  }
  .lipstick-part1-wrapper .part1-page h3 {
    font-size: 24px;
  }
  .lipstick-part1-wrapper .part1-page h1 {
    font-size: 28px;
  }
}
.lipstick-part2-wrapper {
  width: 100%;
  height: 682px;
  position: relative;
  text-align: center;
  background: #000;
  overflow: hidden;
}
.lipstick-part2-wrapper .lipstick-part2 {
  width: 100%;
  position: relative;
  text-align: center;
  overflow: hidden;
  padding: 5vh 0 5vh 0;
}
.lipstick-part2-wrapper .lipstick-part2 > .queue-anim-leaving {
  position: relative !important;
}
.lipstick-part2-wrapper .lipstick-part2 h3 {
  font-size: 40px;
  color: #fff;
}
.lipstick-part2-wrapper .lipstick-part2 h1 {
  color: #FFF;
  font-size: 60px;
}
.lipstick-part2-wrapper .lipstick-part2-imgs {
  display: flex;
}
.lipstick-part2-wrapper .lipstick-part2-image,
.lipstick-part2-wrapper .lipstick-part2-image-mobile {
  width: 100%;
  max-height: 60%;
  max-width: 1500px;
  overflow: hidden;
  margin: auto;
}
@media screen and (max-width: 1124px) {
  .lipstick-part2-wrapper {
    height: auto;
  }
  .lipstick-part2-wrapper .lipstick-part2-imgs {
    flex-wrap: wrap;
    justify-content: center;
  }
  .lipstick-part2-wrapper .lipstick-part2-imgs img {
    max-height: 300px;
  }
}
@media screen and (max-width: 767px) {
  .lipstick-part2-wrapper {
    height: 600px;
  }
  .lipstick-part2-wrapper .lipstick-part2 {
    padding-bottom: 36px;
  }
  .lipstick-part2-wrapper .lipstick-part2-imgs {
    flex-wrap: wrap;
  }
  .lipstick-part2-wrapper .lipstick-part2-imgs img {
    max-height: 200px;
  }
  .lipstick-part2-wrapper .lipstick-part2 h3 {
    font-size: 22px;
    color: #fff;
  }
  .lipstick-part2-wrapper .lipstick-part2 h1 {
    color: #FFF;
    font-size: 50px;
  }
}
.lipstick-part3-wrapper {
  width: 100%;
  height: auto;
  position: relative;
  text-align: center;
  background: #000;
  overflow: hidden;
}
.lipstick-part3-wrapper .lipstick-part3 {
  width: 100%;
  position: relative;
  text-align: center;
  overflow: hidden;
  padding: 5vh 0 0 0;
}
.lipstick-part3-wrapper .lipstick-part3 > .queue-anim-leaving {
  position: relative !important;
}
.lipstick-part3-wrapper .lipstick-part3 h3 {
  font-size: 40px;
  color: #fff;
}
.lipstick-part3-wrapper .lipstick-part3 h1 {
  color: #FFF;
  font-size: 60px;
}
.lipstick-part3-wrapper .lipstick-part3-imgs {
  display: flex;
  margin-top: 36px;
}
.lipstick-part3-wrapper .lipstick-part3-imgs img {
  object-fit: cover;
}
.lipstick-part3-wrapper .lipstick-part3-image,
.lipstick-part3-wrapper .lipstick-part3-image-mobile {
  width: 100%;
  max-height: 60%;
  max-width: 1500px;
  overflow: hidden;
  margin: auto;
}
@media screen and (max-width: 767px) {
  .lipstick-part3-wrapper {
    padding-bottom: 48px;
  }
  .lipstick-part3-wrapper .lipstick-part3 {
    padding-bottom: 36px;
  }
  .lipstick-part3-wrapper .lipstick-part3 h3 {
    font-size: 22px;
    color: #fff;
  }
  .lipstick-part3-wrapper .lipstick-part3 h1 {
    color: #FFF;
    font-size: 30px;
  }
}
.lipstick-part4-wrapper {
  height: 664px;
  background: #fafafa;
}
.lipstick-part4-wrapper .home-page {
  padding: 36px 0;
}
.lipstick-part4-wrapper .lipstick-part4 {
  height: 100%;
  overflow: hidden;
}
.lipstick-part4-wrapper .lipstick-part4 .title-h1 {
  font-size: 54px;
}
.lipstick-part4-wrapper .lipstick-part4 .title-content {
  text-align: center;
  font-size: 38px;
}
.lipstick-part4-wrapper .lipstick-part4-block-wrapper {
  position: relative;
}
.lipstick-part4-wrapper .lipstick-part4-block-wrapper .lipstick-part4-block {
  display: inline-block;
  padding: 48px 24px;
  vertical-align: top;
}
.lipstick-part4-wrapper .lipstick-part4-block-wrapper .lipstick-part4-block .lipstick-part4-icon {
  display: inline-block;
  width: 15%;
  vertical-align: top;
}
.lipstick-part4-wrapper .lipstick-part4-block-wrapper .lipstick-part4-block .lipstick-part4-text {
  width: 85%;
  display: inline-block;
  padding-left: 8%;
}
.lipstick-part4-wrapper .lipstick-part4-block-wrapper .lipstick-part4-block.clear-both {
  clear: both;
}
@media screen and (max-width: 767px) {
  .lipstick-part4-wrapper {
    height: auto;
  }
  .lipstick-part4-wrapper .lipstick-part4 {
    padding: 24px 12px;
  }
  .lipstick-part4-wrapper .lipstick-part4 .title-wrapper {
    margin: 0 auto 24px;
  }
  .lipstick-part4-wrapper .lipstick-part4 .title-h1 {
    font-size: 32px;
  }
  .lipstick-part4-wrapper .lipstick-part4 .title-content {
    text-align: center;
    font-size: 24px;
  }
  .lipstick-part4-wrapper .lipstick-part4-block-wrapper {
    margin: 20px auto;
    height: auto;
  }
  .lipstick-part4-wrapper .lipstick-part4-block-wrapper .lipstick-part4-block {
    padding: 24px;
  }
  .lipstick-part4-wrapper .lipstick-part4-block-wrapper .lipstick-part4-block .lipstick-part4-title {
    font-size: 20px;
  }
  .lipstick-part4-wrapper .lipstick-part4-block-wrapper .lipstick-part4-block.queue-anim-leaving {
    position: relative !important;
  }
}
.lipstick-part5-wrapper {
  height: 566px;
  background: #fff;
}
.lipstick-part5-wrapper .home-page {
  padding: 24px;
}
.lipstick-part5-wrapper .lipstick-part5 {
  width: 100%;
  height: 100%;
  position: relative;
  text-align: center;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.lipstick-part5-wrapper .lipstick-part5-image {
  background-color: #fff;
  height: 100%;
  width: 45%;
  overflow: hidden;
}
.lipstick-part5-wrapper .lipstick-part5-image div {
  height: 100%;
  width: 100%;
}
.lipstick-part5-wrapper .lipstick-part5-image div img {
  height: 100%;
  width: 100%;
  object-fit: scale-down;
}
.lipstick-part5-wrapper .lipstick-part5-text-wrapper {
  width: 360px;
  text-align: left;
}
.lipstick-part5-wrapper .lipstick-part5-title {
  font-size: 60px;
  color: #000;
}
.lipstick-part5-wrapper .lipstick-part5-content {
  font-size: 42px;
  font-weight: 500;
  line-height: 30px;
  color: #000;
}
.lipstick-part5-wrapper .lipstick-part5-intro {
  margin-top: 16px;
  font-size: 24px;
  text-align: left;
  color: #000;
}
.lipstick-part5-wrapper .lipstick-part5 a {
  display: block;
  margin-top: 24px;
  margin-bottom: 24px;
  background-color: #fff;
  font-size: 20px;
  border: none;
  text-align: center;
  color: #000;
}
@media screen and (max-width: 767px) {
  .lipstick-part5-wrapper {
    height: auto;
  }
  .lipstick-part5-wrapper .home-page {
    padding: 0;
  }
  .lipstick-part5-wrapper .lipstick-part5 {
    overflow: hidden;
    padding: 24px 12px;
    margin: auto;
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: center;
  }
  .lipstick-part5-wrapper .lipstick-part5-image {
    height: auto;
    padding: 12px 24px;
    width: 100%;
  }
  .lipstick-part5-wrapper .lipstick-part5-text-wrapper {
    margin: 0;
    margin-bottom: 24px;
  }
  .lipstick-part5-wrapper .lipstick-part5-title {
    font-size: 50px;
    line-height: 60px;
    text-align: center;
    margin-bottom: 10px;
  }
  .lipstick-part5-wrapper .lipstick-part5-content {
    font-size: 20px;
    line-height: 20px;
  }
  .lipstick-part5-wrapper .lipstick-part5-intro {
    font-size: 20px;
    text-align: center;
  }
}
.lipstick-part6-wrapper {
  height: 566px;
  background: #fff;
}
.lipstick-part6-wrapper .home-page {
  padding: 24px;
}
.lipstick-part6-wrapper .lipstick-part6 {
  width: 100%;
  height: 100%;
  position: relative;
  text-align: center;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.lipstick-part6-wrapper .lipstick-part6-image {
  background-color: #fff;
  width: 30%;
  height: 100%;
  overflow: hidden;
}
.lipstick-part6-wrapper .lipstick-part6-image div {
  height: 100%;
  width: 100%;
}
.lipstick-part6-wrapper .lipstick-part6-image div img {
  height: 100%;
  width: 100%;
  object-fit: scale-down;
}
.lipstick-part6-wrapper .lipstick-part6-text-wrapper {
  width: 600px;
  text-align: center;
}
.lipstick-part6-wrapper .lipstick-part6-title {
  font-size: 70px;
  color: #000;
}
.lipstick-part6-wrapper .lipstick-part6-content {
  font-size: 42px;
  font-weight: 500;
  line-height: 30px;
  color: #000;
}
.lipstick-part6-wrapper .lipstick-part6-intro {
  margin-top: 16px;
  font-size: 50px;
  text-align: left;
  color: #000;
}
.lipstick-part6-wrapper .lipstick-part6 a {
  display: block;
  margin-top: 24px;
  margin-bottom: 24px;
  background-color: #fff;
  font-size: 20px;
  border: none;
  text-align: center;
  color: #000;
}
@media screen and (max-width: 1124px) {
  .lipstick-part6-wrapper .lipstick-part6-intro {
    font-size: 24px;
  }
}
@media screen and (max-width: 767px) {
  .lipstick-part6-wrapper {
    height: auto;
  }
  .lipstick-part6-wrapper .home-page {
    padding: 0;
  }
  .lipstick-part6-wrapper .lipstick-part6 {
    padding: 24px 12px;
    overflow: hidden;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .lipstick-part6-wrapper .lipstick-part6-image {
    height: auto;
    width: 100%;
    padding: 12px 24px;
  }
  .lipstick-part6-wrapper .lipstick-part6-text-wrapper {
    margin: 0;
    width: 100%;
  }
  .lipstick-part6-wrapper .lipstick-part6-title {
    font-size: 50px;
    line-height: 60px;
    margin-bottom: 10px;
  }
  .lipstick-part6-wrapper .lipstick-part6-content {
    font-size: 20px;
    line-height: 20px;
  }
  .lipstick-part6-wrapper .lipstick-part6-intro {
    font-size: 24px;
    text-align: center;
  }
}
.lipstick-part7-wrapper {
  height: 460px;
  background: #fafafa;
}
.lipstick-part7-wrapper .home-page {
  padding: 0;
}
.lipstick-part7-wrapper .lipstick-part7 > p {
  text-align: center;
}
.lipstick-part7-wrapper .lipstick-part7-title-h1 {
  margin-bottom: 12px;
}
.lipstick-part7-wrapper .lipstick-part7 button {
  background-color: #000;
  color: #fff;
}
.lipstick-part7-wrapper .lipstick-part7-table-name-block {
  text-align: center;
  color: #666;
  width: 100%;
}
.lipstick-part7-wrapper .lipstick-part7-table-name {
  font-size: 24px;
}
.lipstick-part7-wrapper .lipstick-part7-table-money {
  font-size: 16px;
  margin: 8px 0 16px;
}
.lipstick-part7-wrapper .lipstick-part7-table-content {
  text-align: center;
  color: #666;
}
.lipstick-part7-wrapper .lipstick-part7-table-content-name {
  color: #666;
  text-align: center;
}
.lipstick-part7-wrapper.home-page-wrapper .lipstick-part7-title-wrapper {
  margin: 64px auto;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .lipstick-part7-wrapper {
    height: auto;
    background-color: #fff;
  }
  .lipstick-part7-wrapper .lipstick-part7 {
    padding: 24px 12px;
  }
  .lipstick-part7-wrapper .lipstick-part7-title-wrapper {
    margin: auto auto 24px !important;
  }
  .lipstick-part7-wrapper .lipstick-part7-wrapper {
    padding-bottom: 0;
  }
  .lipstick-part7-wrapper .lipstick-part7-table {
    margin-bottom: 24px;
  }
}
.lipstick-part8-wrapper {
  height: 782px;
  background: #fff;
}
.lipstick-part8-wrapper .lipstick-part8 {
  width: 100%;
  height: 100%;
  position: relative;
  text-align: center;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5vh 0 5vh 0;
}
.lipstick-part8-wrapper .lipstick-part8 .title-h1 {
  font-size: 50px;
  color: #000;
}
.lipstick-part8-wrapper .lipstick-part8 .title-content {
  font-size: 24px;
  color: #000;
}
.lipstick-part8-wrapper .lipstick-part8 button {
  margin-top: 24px;
  background-color: #d21975;
  color: #fff;
}
.lipstick-part8-wrapper .lipstick-part8-image,
.lipstick-part8-wrapper .lipstick-part8-image-mobile {
  width: 100%;
  height: 100%;
  max-width: 550px;
  overflow: hidden;
}
.lipstick-part8-wrapper .lipstick-part8-image div,
.lipstick-part8-wrapper .lipstick-part8-image-mobile div {
  height: 100%;
  width: 100%;
}
.lipstick-part8-wrapper .lipstick-part8-image div img,
.lipstick-part8-wrapper .lipstick-part8-image-mobile div img {
  height: 100%;
  width: 100%;
  object-fit: scale-down;
}
.lipstick-part8-wrapper .lipstick-part8-icon {
  bottom: 20px;
  font-size: 24px;
  position: absolute;
  left: 50%;
  margin-left: -12px;
  color: #000;
}
@media screen and (max-width: 1124px) {
  .lipstick-part8-wrapper {
    height: 1282px;
  }
  .lipstick-part8-wrapper .lipstick-part8 {
    flex-wrap: wrap;
  }
  .lipstick-part8-wrapper .lipstick-part8-image,
  .lipstick-part8-wrapper .lipstick-part8 .title-wrapper {
    margin: auto;
  }
  .lipstick-part8-wrapper .lipstick-part8-image {
    height: auto;
  }
}
@media screen and (max-width: 767px) {
  .lipstick-part8-wrapper {
    height: auto;
  }
  .lipstick-part8-wrapper .lipstick-part8 {
    padding: 24px 12px;
    overflow: hidden;
    margin: auto;
    display: flex;
    flex-wrap: wrap-reverse;
  }
  .lipstick-part8-wrapper .lipstick-part8-image {
    height: auto;
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
    width: 100%;
    margin-top: 24px;
  }
  .lipstick-part8-wrapper .lipstick-part8-text-wrapper {
    margin-top: 24px;
    margin-left: 0px;
  }
  .lipstick-part8-wrapper .lipstick-part8 .title-h1 {
    font-size: 32px;
    line-height: 42px;
    color: #000;
  }
  .lipstick-part8-wrapper .lipstick-part8 .content {
    font-size: 20px;
    line-height: 20px;
  }
  .lipstick-part8-wrapper .lipstick-part8-intro {
    font-size: 20px;
  }
}
.lipstick-part9-wrapper {
  overflow: hidden;
  position: relative;
  will-change: transform;
  background-color: #fafafa;
}
.lipstick-part9-wrapper .box-shadow-d {
  box-shadow: 0px 10px 10px #cfcfcf;
  padding: 20px 0;
  width: 300px;
  margin: auto;
  height: 278px;
}
.lipstick-part9-wrapper .center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.lipstick-part9-wrapper .center p {
  font-size: 28px;
  color: #000;
}
.lipstick-part9-wrapper .lipstick-part9 {
  max-width: 1200px;
  margin: auto;
  overflow: hidden;
  height: 100%;
  padding: 64px 24px;
}
.lipstick-part9-wrapper .lipstick-part9 > .title-wrapper {
  margin: 0 auto 48px;
  text-align: center;
}
.lipstick-part9-wrapper .lipstick-part9 .block-wrapper {
  position: relative;
  height: 100%;
  overflow: hidden;
  padding: 0 0;
}
.lipstick-part9-wrapper .lipstick-part9 .block-wrapper .block {
  display: inline-block;
  text-align: center;
  margin-bottom: 48px;
  position: relative;
}
.lipstick-part9-wrapper .lipstick-part9 .block-wrapper .block.queue-anim-leaving {
  position: relative !important;
}
.lipstick-part9-wrapper .lipstick-part9 .block-wrapper .block .inner-title {
  position: absolute;
  top: 90px;
  font-size: 30px;
  font-weight: 500;
  left: 0;
  right: 0;
  color: #fff;
}
.lipstick-part9-wrapper .lipstick-part9-image,
.lipstick-part9-wrapper .lipstick-part9-title {
  width: 280px;
  margin: auto;
  line-height: 1.5;
}
.lipstick-part9-wrapper .lipstick-part9-image {
  position: relative;
  color: #404040;
  background-color: #fff;
}
.lipstick-part9-wrapper .lipstick-part9-image img {
  width: 100%;
  opacity: 0.86;
}
.lipstick-part9-wrapper .lipstick-part9-title {
  font-size: 18px;
  margin: 24px 0 0 0;
  text-align: center;
  padding-left: 16px;
  font-weight: 400;
}
.lipstick-part9-wrapper .lipstick-part9 .title-1 {
  font-size: 40px;
  margin-top: 80px;
}
@media screen and (max-width: 767px) {
  .lipstick-part9-wrapper {
    min-height: 742px;
  }
}
.lipstick-wrapper {
  padding-top: 64px;
}

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
textarea,
p,
blockquote,
th,
td,
hr,
button,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  margin: 0;
  padding: 0;
}
ul,
ol {
  list-style: none;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
body {
  word-wrap: break-word;
}
/* .content-wrapper > .tween-one-leaving,
.queue-anim-leaving {
  // position: absolute !important;
  // width: 100%;
} */
.video {
  max-width: 800px;
}
#react-content {
  min-height: 100%;
}
.text-wrapper-comp {
  width: 100%;
  height: calc(100vh - 64px);
  position: relative;
  text-align: center;
  overflow: hidden;
  background-size: cover;
  background-position: center;
}
.text-wrapper-comp-page {
  display: inline-block;
  position: absolute;
  top: 34%;
  margin: auto;
  left: 0;
  right: 0;
  font-size: 14px;
  color: #fff;
}
.text-wrapper-comp-page > .queue-anim-leaving {
  position: relative !important;
}
.text-wrapper-comp-page h3 {
  font-size: 52px;
  color: #fff;
}
.text-wrapper-comp-page h1 {
  color: #FFF;
  font-size: 68px;
}
.text-wrapper-comp .text-wrapper-comp-icon {
  bottom: 20px;
  font-size: 24px;
  position: absolute;
  left: 50%;
  margin-left: -12px;
  color: #fff;
}
@media screen and (max-width: 767px) {
  .text-wrapper-comp-page h3 {
    font-size: 32px;
    color: #fff;
  }
  .text-wrapper-comp-page h1 {
    color: #FFF;
    font-size: 40px;
  }
}

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
textarea,
p,
blockquote,
th,
td,
hr,
button,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  margin: 0;
  padding: 0;
}
ul,
ol {
  list-style: none;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.banner-wrapper-comp {
  width: 100%;
  height: 782px;
  position: relative;
  text-align: center;
  background: #fff;
  display: flex;
  align-items: center;
  overflow: hidden;
}
.banner-wrapper-comp .home-page {
  height: auto;
}
.banner-wrapper-comp .banner-wrapper-comp-page {
  padding: 36px 0 0;
}
.banner-wrapper-comp-title,
.banner-wrapper-comp-title-2 {
  font-size: 90px;
  line-height: 1.5;
  display: inline-block;
}
.banner-wrapper-comp-title-2 {
  margin-left: 18px;
}
.banner-wrapper-comp .color-0 {
  color: #000;
}
.banner-wrapper-comp-content {
  font-size: 40px;
  line-height: 20px;
  color: #000;
  margin: 8px auto 16px;
  margin-bottom: 36px;
}
.banner-wrapper-comp-a {
  font-size: 22px;
}
.banner-wrapper-comp-image,
.banner-wrapper-comp-image-mobile {
  width: 100%;
  overflow: hidden;
  margin: 0 auto;
}
@media screen and (max-width: 1124px) {
  .banner-wrapper-comp-title {
    font-size: 48px;
  }
  .banner-wrapper-comp-a {
    font-size: 16px;
  }
  .banner-wrapper-comp-content {
    font-size: 32px;
  }
}
@media screen and (max-width: 767px) {
  .banner-wrapper-comp {
    height: auto;
  }
  .banner-wrapper-comp .color-0 {
    color: #000 !important;
  }
  .banner-wrapper-comp .banner-wrapper-comp-page.home-page {
    padding: 64px 12px 48px;
  }
  .banner-wrapper-comp-title {
    font-size: 50px;
    line-height: 60px;
    margin-bottom: 10px;
  }
  .banner-wrapper-comp-title,
  .banner-wrapper-comp-title-2 {
    display: block;
  }
  .banner-wrapper-comp-title-2 {
    font-size: 20px;
    line-height: 20px;
    margin-left: 0px;
  }
  .banner-wrapper-comp-a {
    margin-top: 24px;
    margin-bottom: 24px;
    background-color: #fff;
    font-size: 20px;
    border: none;
    text-align: center;
  }
  .banner-wrapper-comp-content {
    font-size: 20px;
    margin-top: 16px;
  }
  .banner-wrapper-comp-image {
    width: 100%;
    margin: 24px 0;
  }
}

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
textarea,
p,
blockquote,
th,
td,
hr,
button,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  margin: 0;
  padding: 0;
}
ul,
ol {
  list-style: none;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
body {
  word-wrap: break-word;
}
/* .content-wrapper > .tween-one-leaving,
.queue-anim-leaving {
  // position: absolute !important;
  // width: 100%;
} */
.video {
  max-width: 800px;
}
#react-content {
  min-height: 100%;
}
.energy-part3-wrapper {
  height: 482px;
  background: #fff;
}
.energy-part3-wrapper .energy-part3 {
  width: 100%;
  height: 100%;
  position: relative;
  text-align: center;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5vh 0 5vh 0;
}
.energy-part3-wrapper .energy-part3-image {
  background-color: #fff;
  width: 55%;
  overflow: hidden;
  height: 100%;
}
.energy-part3-wrapper .energy-part3-image div,
.energy-part3-wrapper .energy-part3-image img {
  width: 100%;
  height: 100%;
}
.energy-part3-wrapper .energy-part3-text-wrapper {
  width: 420px;
  text-align: center;
}
.energy-part3-wrapper .energy-part3-title {
  font-size: 70px;
  color: #000;
}
.energy-part3-wrapper .energy-part3-content {
  font-size: 32px;
  font-weight: 500;
  line-height: 30px;
  color: #000;
}
.energy-part3-wrapper .energy-part3-intro {
  margin-top: 16px;
  font-size: 24px;
  color: #000;
}
.energy-part3-wrapper .energy-part3 a {
  display: block;
  margin-top: 24px;
  margin-bottom: 24px;
  background-color: #fff;
  font-size: 20px;
  border: none;
  text-align: center;
  color: #000;
}
@media screen and (max-width: 767px) {
  .energy-part3-wrapper {
    height: auto;
  }
  .energy-part3-wrapper .energy-part3 {
    overflow: hidden;
    padding: 0px 12px;
    margin: auto;
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: center;
  }
  .energy-part3-wrapper .energy-part3-text-wrapper {
    margin-top: 24px;
    margin-left: 0px;
  }
  .energy-part3-wrapper .energy-part3-title {
    font-size: 50px;
    line-height: 60px;
    margin-bottom: 10px;
  }
  .energy-part3-wrapper .energy-part3-content {
    font-size: 20px;
    line-height: 20px;
  }
  .energy-part3-wrapper .energy-part3-intro {
    font-size: 20px;
  }
  .energy-part3-wrapper .energy-part3-image {
    width: 100%;
  }
}
.energy-part4-wrapper {
  height: 482px;
  background: #fff;
}
.energy-part4-wrapper .energy-part4 {
  width: 100%;
  height: 100%;
  position: relative;
  text-align: center;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.energy-part4-wrapper .energy-part4-image {
  width: 55%;
  overflow: hidden;
}
.energy-part4-wrapper .energy-part4-text-wrapper {
  width: 360px;
  text-align: left;
}
.energy-part4-wrapper .energy-part4-title {
  font-size: 70px;
  color: #000;
}
.energy-part4-wrapper .energy-part4-content {
  font-size: 42px;
  font-weight: 500;
  line-height: 30px;
  color: #000;
}
.energy-part4-wrapper .energy-part4-intro {
  margin-top: 16px;
  font-size: 24px;
  color: #000;
}
.energy-part4-wrapper .energy-part4 a {
  display: block;
  margin-top: 24px;
  margin-bottom: 24px;
  background-color: #fff;
  font-size: 20px;
  border: none;
  text-align: center;
  color: #000;
}
@media screen and (max-width: 767px) {
  .energy-part4-wrapper {
    height: auto;
  }
  .energy-part4-wrapper .energy-part4 {
    overflow: hidden;
    padding: 24px 12px 64px;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .energy-part4-wrapper .energy-part4-text-wrapper {
    margin-top: 24px;
    text-align: center;
    margin-left: 0px;
  }
  .energy-part4-wrapper .energy-part4-title {
    font-size: 50px;
    line-height: 60px;
    margin-bottom: 10px;
  }
  .energy-part4-wrapper .energy-part4-content {
    font-size: 20px;
    line-height: 20px;
  }
  .energy-part4-wrapper .energy-part4-intro {
    font-size: 20px;
  }
  .energy-part4-wrapper .energy-part4-image {
    width: 100%;
  }
}
.energy-part5-wrapper {
  min-height: 446px;
  overflow: hidden;
}
.energy-part5-wrapper .box-shadow-d {
  box-shadow: 0px 10px 10px #cfcfcf;
  padding: 16px 16px;
  width: 332px;
  margin: auto;
  height: 370px;
}
.energy-part5-wrapper .energy-part5 {
  overflow: hidden;
  height: 100%;
  padding: 64px 24px;
}
.energy-part5-wrapper .energy-part5 > .title-wrapper {
  margin: 0 auto 48px;
}
.energy-part5-wrapper .energy-part5 .block-wrapper {
  position: relative;
  height: 100%;
  overflow: hidden;
  padding: 0 0;
  display: flex;
  justify-content: center;
}
.energy-part5-wrapper .energy-part5 .block-wrapper .block {
  display: inline-block;
  text-align: center;
  margin-bottom: 48px;
  position: relative;
}
.energy-part5-wrapper .energy-part5 .block-wrapper .block.queue-anim-leaving {
  position: relative !important;
}
.energy-part5-wrapper .energy-part5-inner-title {
  top: 70px;
  font-size: 40px;
}
.energy-part5-wrapper .energy-part5-inner-title,
.energy-part5-wrapper .energy-part5-inner-title-1,
.energy-part5-wrapper .energy-part5-inner-title-2 {
  position: absolute;
  font-weight: 500;
  left: 0;
  right: 0;
  color: #fff;
}
.energy-part5-wrapper .energy-part5-inner-title-1 {
  top: 116px;
  font-size: 28px;
}
.energy-part5-wrapper .energy-part5-inner-title-2 {
  padding: 0 32px;
  top: 126px;
  font-size: 16px;
  line-height: 16px;
}
.energy-part5-wrapper .energy-part5-image,
.energy-part5-wrapper .energy-part5-title,
.energy-part5-wrapper .energy-part5-content {
  width: 300px;
  margin: auto;
  line-height: 1.5;
  text-align: center;
}
.energy-part5-wrapper .energy-part5-image {
  position: relative;
  color: #404040;
  background-color: #000;
}
.energy-part5-wrapper .energy-part5-image img {
  width: 100%;
  height: 200px;
  opacity: 0.86;
}
.energy-part5-wrapper .energy-part5-title {
  font-size: 18px;
  margin: 24px 0;
}
.energy-part5-wrapper .energy-part5-content {
  padding: 0 16px;
  font-size: 16px;
  color: #000;
  padding-bottom: 24px;
}
@media screen and (max-width: 767px) {
  .energy-part5-wrapper .block-wrapper {
    display: flex;
    flex-wrap: wrap;
  }
}
.energy-part6-wrapper {
  width: 100%;
  height: 700px;
  position: relative;
  text-align: center;
  overflow: hidden;
  background-size: cover;
  background-position: center;
}
.energy-part6-wrapper .energy-part6-page {
  display: inline-block;
  position: absolute;
  top: 14%;
  margin: auto;
  left: 0;
  right: 0;
  font-size: 14px;
  color: #fff;
}
.energy-part6-wrapper .energy-part6-page > .queue-anim-leaving {
  position: relative !important;
}
.energy-part6-wrapper .energy-part6-page h1 {
  color: #FFF;
  font-size: 60px;
}
@media screen and (max-width: 1124px) {
  .energy-part6-wrapper {
    height: 200px;
  }
  .energy-part6-wrapper .energy-part6-page h1 {
    color: #FFF;
    font-size: 20px;
  }
}
.energy-part7-wrapper {
  width: 100%;
  height: 100vh;
  position: relative;
  text-align: center;
  overflow: hidden;
  background-image: url("https://website-public-resource-for-all-user.oss-cn-shanghai.aliyuncs.com/ai-website/public/energy-1.png");
  background-size: cover;
  background-position: center;
}
.energy-part7-wrapper .energy-part7 {
  width: 100%;
  height: 100%;
  position: relative;
  text-align: center;
  overflow: hidden;
  padding: 5vh 0;
}
.energy-part7-wrapper .energy-part7 .title {
  margin-top: 5%;
  font-size: 60px;
  color: #fff;
  line-height: 60px;
}
.energy-part7-wrapper .energy-part7 .sub-title {
  font-size: 40px;
  color: #fff;
  font-weight: 500;
}
.energy-part7-wrapper .energy-part7 .title-content {
  font-size: 34px;
  color: #fff;
}
.energy-part7-wrapper .energy-part7 button {
  margin-top: 16px;
  background-color: #000;
  border: none;
  color: #fff;
}
.energy-part7-wrapper .energy-part7-image,
.energy-part7-wrapper .energy-part7-image-mobile {
  width: 100%;
  max-height: 60%;
  max-width: 1200px;
  overflow: hidden;
  margin: auto;
}
.energy-part7-wrapper .energy-part7-icon {
  bottom: 20px;
  font-size: 24px;
  position: absolute;
  left: 50%;
  margin-left: -12px;
  color: #000;
}
@media screen and (max-width: 1124px) {
  .energy-part7-wrapper {
    height: 500px;
  }
  .energy-part7-wrapper .energy-part7 {
    overflow: hidden;
    width: 90%;
    margin: auto;
  }
  .energy-part7-wrapper .energy-part7 .title-h1 {
    font-size: 60px;
    line-height: 60px;
    color: #ae285c;
    margin-bottom: 40px;
  }
  .energy-part7-wrapper .energy-part7 .title-content {
    font-size: 20px;
  }
  .energy-part7-wrapper .energy-part7 .sub-title {
    font-size: 24px;
    margin-bottom: 16px;
  }
}
.energy-part8-wrapper {
  background: #fff;
}
.energy-part8-wrapper .title-wrapper {
  margin: 64px auto;
  text-align: center;
}
.energy-part8-wrapper .title-wrapper h1 {
  color: #000;
  font-size: 48px;
}
.energy-part8-wrapper .energy-part8 {
  width: 100%;
  height: 80%;
  max-width: 1200px;
  position: relative;
  text-align: center;
  overflow: hidden;
  display: flex;
  justify-content: center;
  padding: 0 0 10vh 0;
}
.energy-part8-wrapper .energy-part8-text-wrapper {
  text-align: left;
  padding-left: 48px;
}
.energy-part8-wrapper .energy-part8-title {
  font-size: 22px;
  color: #000;
}
.energy-part8-wrapper .energy-part8-title-bottom {
  padding-top: 48px;
}
.energy-part8-wrapper .energy-part8-content {
  margin-bottom: 24px;
}
.energy-part8-wrapper .energy-part8-content::before {
  content: '';
  display: inline-block;
  width: 10px;
  height: 10px;
  background: #000;
  border-radius: 50%;
  margin-bottom: 4px;
  margin-right: 6px;
}
.energy-part8-wrapper .energy-part8-content,
.energy-part8-wrapper .energy-part8-intro {
  font-size: 20px;
  color: #000;
}
.energy-part8-wrapper .energy-part8-intro {
  line-height: 36px;
}
.energy-part8-wrapper .energy-part8-image,
.energy-part8-wrapper .energy-part8-image-mobile {
  width: 100%;
  max-height: 50%;
  max-width: 800px;
  overflow: hidden;
  margin: auto;
}
@media screen and (max-width: 767px) {
  .energy-part8-wrapper {
    min-height: 350px;
  }
  .energy-part8-wrapper .energy-part8 {
    overflow: hidden;
    width: 90%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
  }
  .energy-part8-wrapper .energy-part8-text-wrapper {
    margin: auto;
    margin-top: 48px;
  }
  .energy-part8-wrapper .energy-part8-title-bottom {
    padding-top: 12px;
  }
}
.energy-part9-wrapper {
  width: 100%;
  height: 882px;
  position: relative;
  text-align: center;
  background: #FFF;
  overflow: hidden;
}
.energy-part9-wrapper .energy-part9 {
  width: 100%;
  position: relative;
  text-align: center;
  overflow: hidden;
  padding: 5vh 0 5vh 0;
}
.energy-part9-wrapper .energy-part9 > .queue-anim-leaving {
  position: relative !important;
}
.energy-part9-wrapper .energy-part9 h1 {
  color: #000;
  font-size: 48px;
}
.energy-part9-wrapper .energy-part9-image,
.energy-part9-wrapper .energy-part9-image-mobile {
  width: 100%;
  max-height: 60%;
  max-width: 1500px;
  overflow: hidden;
  margin: auto;
}
@media screen and (max-width: 767px) {
  .energy-part9-wrapper {
    height: 500px;
  }
  .energy-part9-wrapper .energy-part9 h1 {
    font-size: 32px;
  }
}
.energy-wrapper {
  padding-top: 64px;
}

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
textarea,
p,
blockquote,
th,
td,
hr,
button,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  margin: 0;
  padding: 0;
}
ul,
ol {
  list-style: none;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.text-wrapper-comp-wrapper {
  height: 460px;
  background: #fafafa;
}
.text-wrapper-comp-wrapper .home-page {
  padding: 0;
}
.text-wrapper-comp-wrapper .text-wrapper-comp > p {
  text-align: center;
}
.text-wrapper-comp-wrapper .text-wrapper-comp-title-h1 {
  margin-bottom: 12px;
}
.text-wrapper-comp-wrapper .text-wrapper-comp button {
  background-color: #000;
  color: #fff;
}
.text-wrapper-comp-wrapper .text-wrapper-comp-table-name-block {
  text-align: center;
  color: #666;
  width: 100%;
}
.text-wrapper-comp-wrapper .text-wrapper-comp-table-name {
  font-size: 24px;
}
.text-wrapper-comp-wrapper .text-wrapper-comp-table-money {
  font-size: 16px;
  margin: 8px 0 16px;
}
.text-wrapper-comp-wrapper .text-wrapper-comp-table-content {
  text-align: center;
  color: #666;
}
.text-wrapper-comp-wrapper .text-wrapper-comp-table-content-name {
  color: #666;
  text-align: center;
}
.text-wrapper-comp-wrapper.home-page-wrapper .text-wrapper-comp-title-wrapper {
  margin: 64px auto;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .text-wrapper-comp-wrapper {
    height: auto;
    background-color: #fff;
  }
  .text-wrapper-comp-wrapper .text-wrapper-comp {
    padding: 24px 12px;
    padding-bottom: 64px;
  }
  .text-wrapper-comp-wrapper .text-wrapper-comp-title-wrapper {
    margin: auto auto 24px !important;
  }
  .text-wrapper-comp-wrapper .text-wrapper-comp-wrapper {
    padding-bottom: 0;
  }
  .text-wrapper-comp-wrapper .text-wrapper-comp-table {
    margin-bottom: 24px;
  }
}

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
textarea,
p,
blockquote,
th,
td,
hr,
button,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  margin: 0;
  padding: 0;
}
ul,
ol {
  list-style: none;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
body {
  word-wrap: break-word;
}
/* .content-wrapper > .tween-one-leaving,
.queue-anim-leaving {
  // position: absolute !important;
  // width: 100%;
} */
.video {
  max-width: 800px;
}
#react-content {
  min-height: 100%;
}
.pv-part1-wrapper {
  height: 782px;
  margin-top: 64px;
  background: #fff;
}
.pv-part1-wrapper .pv-part1 {
  width: 100%;
  height: 100%;
  position: relative;
  text-align: center;
  overflow: hidden;
}
.pv-part1-wrapper .pv-part1 .title-h1,
.pv-part1-wrapper .pv-part1 .title-h2 {
  line-height: 1.5;
  font-size: 90px;
  color: #396bf6;
  display: inline-block;
}
.pv-part1-wrapper .pv-part1 .title-h2 {
  margin-left: 18px;
}
.pv-part1-wrapper .pv-part1 .title-content {
  font-size: 40px;
  color: #000;
}
.pv-part1-wrapper .pv-part1 button {
  margin-top: 22px;
  background-color: #396bf6;
  color: #fff;
}
.pv-part1-wrapper .pv-part1-image,
.pv-part1-wrapper .pv-part1-image-mobile {
  width: 100%;
  max-height: 500px;
  max-width: 1200px;
  overflow: hidden;
  margin: auto;
}
.pv-part1-wrapper .pv-part1-image div,
.pv-part1-wrapper .pv-part1-image-mobile div,
.pv-part1-wrapper .pv-part1-image img,
.pv-part1-wrapper .pv-part1-image-mobile img {
  height: 100%;
  width: 100%;
  max-height: 500px;
}
.pv-part1-wrapper .pv-part1-icon {
  bottom: 20px;
  font-size: 24px;
  position: absolute;
  left: 50%;
  margin-left: -12px;
  color: #000;
}
@media screen and (max-width: 1124px) {
  .pv-part1-wrapper {
    height: 682px;
  }
  .pv-part1-wrapper .pv-part1 .title-h1 {
    font-size: 48px;
  }
  .pv-part1-wrapper .pv-part1 .title-content {
    font-size: 32px;
  }
  .pv-part1-wrapper .pv-part1 button {
    font-size: 16px;
  }
}
@media screen and (max-width: 767px) {
  .pv-part1-wrapper {
    min-height: 300px;
    height: auto;
    margin-top: 24px;
  }
  .pv-part1-wrapper .pv-part1 {
    overflow: hidden;
    padding: 24px 12px;
    margin: auto;
  }
  .pv-part1-wrapper .pv-part1 .title-h1,
  .pv-part1-wrapper .pv-part1 .title-h2 {
    display: block;
    font-size: 50px;
    line-height: 1;
    margin-bottom: 0;
  }
  .pv-part1-wrapper .pv-part1 .title-h2 {
    margin: 12px auto;
    font-size: 24px;
  }
  .pv-part1-wrapper .pv-part1 .title-content {
    font-size: 1.4em;
    margin-bottom: 28px;
  }
  .pv-part1-wrapper .pv-part1 button {
    margin-top: 12px;
  }
  .pv-part1-wrapper .pv-part1-image {
    margin: 24px 0;
  }
}
.pv-part2-wrapper {
  width: 100%;
  height: 482px;
  position: relative;
  text-align: center;
  background: #fff;
  overflow: hidden;
  background-image: url("https://website-public-resource-for-all-user.oss-cn-shanghai.aliyuncs.com/ai-website/public/energy-7.png");
  background-size: cover;
  background-position: center;
}
.pv-part2-wrapper .part2-page {
  display: inline-block;
  position: absolute;
  top: 16%;
  margin: auto;
  left: 0;
  right: 0;
  font-size: 14px;
  color: #fff;
}
.pv-part2-wrapper .part2-page > .queue-anim-leaving {
  position: relative !important;
}
.pv-part2-wrapper .part2-page h3 {
  font-size: 42px;
  color: #fff;
}
.pv-part2-wrapper .part2-page h1 {
  color: #FFF;
  font-size: 56px;
}
@media screen and (max-width: 1124px) {
  .pv-part2-wrapper {
    height: 400px;
  }
  .pv-part2-wrapper .part2-page h1 {
    font-size: 38px;
  }
  .pv-part2-wrapper .part2-page h3 {
    font-size: 32px;
  }
}
@media screen and (max-width: 767px) {
  .pv-part2-wrapper {
    height: 300px;
  }
  .pv-part2-wrapper .part2-page {
    padding: 0 12px;
  }
  .pv-part2-wrapper .part2-page h3 {
    font-size: 20px;
  }
  .pv-part2-wrapper .part2-page h1 {
    font-size: 18px;
  }
}
.pv-part3-wrapper {
  width: 100%;
  height: auto;
  position: relative;
  text-align: center;
  background: #000;
  overflow: hidden;
}
.pv-part3-wrapper .pv-part3 {
  width: 100%;
  position: relative;
  text-align: center;
  overflow: hidden;
  padding: 5vh 0 5vh 0;
}
.pv-part3-wrapper .pv-part3 > .queue-anim-leaving {
  position: relative !important;
}
.pv-part3-wrapper .pv-part3 h3 {
  font-size: 40px;
  color: #fff;
  margin-bottom: 24px;
}
.pv-part3-wrapper .pv-part3 h1 {
  color: #FFF;
  font-size: 60px;
}
.pv-part3-wrapper .pv-part3-image,
.pv-part3-wrapper .pv-part3-image-mobile {
  position: relative;
  width: 100%;
  max-height: 60%;
  max-width: 1500px;
  overflow: hidden;
  margin: auto;
}
.pv-part3-wrapper .pv-part3-label {
  position: absolute;
  bottom: 24px;
  left: 0;
  right: 0;
  text-align: center;
  color: #fff;
  font-size: 20px !important;
}
@media screen and (max-width: 1124px) {
  .pv-part3-wrapper {
    height: auto;
  }
  .pv-part3-wrapper .pv-part3 {
    height: auto;
  }
  .pv-part3-wrapper .pv-part3 img {
    max-height: 300px;
  }
}
@media screen and (max-width: 767px) {
  .pv-part3-wrapper {
    height: auto;
  }
  .pv-part3-wrapper .pv-part3 {
    padding-bottom: 36px;
  }
  .pv-part3-wrapper .pv-part3 img {
    max-height: 200px;
  }
  .pv-part3-wrapper .pv-part3 h3 {
    font-size: 20px;
    color: #fff;
  }
  .pv-part3-wrapper .pv-part3 h1 {
    color: #FFF;
    font-size: 38px;
  }
  .pv-part3-wrapper .pv-part3-label {
    bottom: 12px;
  }
}
.pv-part5-wrapper {
  height: 664px;
  background: #fafafa;
}
.pv-part5-wrapper .home-page {
  padding: 36px 0;
}
.pv-part5-wrapper .pv-part5 {
  height: 100%;
  overflow: hidden;
}
.pv-part5-wrapper .pv-part5 .title-h1 {
  font-size: 54px;
}
.pv-part5-wrapper .pv-part5 .title-content {
  text-align: center;
  font-size: 38px;
}
.pv-part5-wrapper .pv-part5-block-wrapper {
  position: relative;
}
.pv-part5-wrapper .pv-part5-block-wrapper .pv-part5-block {
  display: inline-block;
  padding: 48px 24px;
  vertical-align: top;
}
.pv-part5-wrapper .pv-part5-block-wrapper .pv-part5-block .pv-part5-icon {
  display: inline-block;
  width: 15%;
  vertical-align: top;
}
.pv-part5-wrapper .pv-part5-block-wrapper .pv-part5-block .pv-part5-text {
  width: 85%;
  display: inline-block;
  padding-left: 8%;
}
.pv-part5-wrapper .pv-part5-block-wrapper .pv-part5-block.clear-both {
  clear: both;
}
@media screen and (max-width: 767px) {
  .pv-part5-wrapper {
    height: auto;
  }
  .pv-part5-wrapper .pv-part5 {
    padding: 24px 12px;
  }
  .pv-part5-wrapper .pv-part5 .title-wrapper {
    margin: 0 auto 24px;
  }
  .pv-part5-wrapper .pv-part5 .title-h1 {
    font-size: 32px;
  }
  .pv-part5-wrapper .pv-part5 .title-content {
    text-align: center;
    font-size: 24px;
  }
  .pv-part5-wrapper .pv-part5-block-wrapper {
    margin: 20px auto;
    height: auto;
  }
  .pv-part5-wrapper .pv-part5-block-wrapper .pv-part5-block {
    padding: 24px;
  }
  .pv-part5-wrapper .pv-part5-block-wrapper .pv-part5-block .pv-part5-title {
    font-size: 20px;
  }
  .pv-part5-wrapper .pv-part5-block-wrapper .pv-part5-block.queue-anim-leaving {
    position: relative !important;
  }
}
.pv-part6-wrapper {
  height: 70vh;
  min-height: 782px;
  background: #fff;
}
.pv-part6-wrapper .pv-part6 {
  width: 100%;
  height: 100%;
  position: relative;
  text-align: center;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5vh 0 5vh 0;
}
.pv-part6-wrapper .pv-part6 .title-h1 {
  font-size: 44px;
  color: #000;
}
.pv-part6-wrapper .pv-part6 .title-content {
  font-size: 24px;
  color: #000;
}
.pv-part6-wrapper .pv-part6 button {
  margin-top: 24px;
  background-color: #396bf6;
  color: #fff;
}
.pv-part6-wrapper .pv-part6-image,
.pv-part6-wrapper .pv-part6-image-mobile {
  width: 100%;
  max-height: 60%;
  max-width: 700px;
  overflow: hidden;
  margin: auto;
}
.pv-part6-wrapper .pv-part6-icon {
  bottom: 20px;
  font-size: 24px;
  position: absolute;
  left: 50%;
  margin-left: -12px;
  color: #000;
}
@media screen and (max-width: 1124px) {
  .pv-part6-wrapper .pv-part6 {
    display: flex;
    flex-wrap: wrap;
  }
  .pv-part6-wrapper .pv-part6 .title-h1 {
    font-size: 32px;
  }
}
@media screen and (max-width: 767px) {
  .pv-part6-wrapper {
    height: auto;
    min-height: 350px;
  }
  .pv-part6-wrapper .pv-part6 {
    overflow: hidden;
    width: 90%;
    margin: auto;
    display: flex;
    flex-wrap: wrap-reverse;
  }
  .pv-part6-wrapper .pv-part6 .title-wrapper {
    margin: 24px auto;
  }
  .pv-part6-wrapper .pv-part6 .title-h1 {
    font-size: 32px;
    line-height: 42px;
    color: #000;
  }
  .pv-part6-wrapper .pv-part6 .content {
    font-size: 20px;
    line-height: 20px;
  }
  .pv-part6-wrapper .pv-part6-intro {
    font-size: 20px;
  }
}
.energy-wrapper {
  padding-top: 64px;
}

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
textarea,
p,
blockquote,
th,
td,
hr,
button,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  margin: 0;
  padding: 0;
}
ul,
ol {
  list-style: none;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
body {
  word-wrap: break-word;
}
.video {
  max-width: 800px;
}
#react-content {
  min-height: 100%;
}
.jetcode-part1-wrapper {
  height: 782px;
  margin-top: 64px;
  background: #fff;
}
.jetcode-part1-wrapper .jetcode-part1 {
  width: 100%;
  height: 100%;
  position: relative;
  text-align: center;
  overflow: hidden;
}
.jetcode-part1-wrapper .jetcode-part1 .title-h1,
.jetcode-part1-wrapper .jetcode-part1 .title-h2 {
  line-height: 1.5;
  font-size: 90px;
  color: #000;
  display: inline-block;
}
.jetcode-part1-wrapper .jetcode-part1 .title-h2 {
  margin-left: 18px;
}
.jetcode-part1-wrapper .jetcode-part1 .title-content {
  font-size: 40px;
  color: #000;
}
.jetcode-part1-wrapper .jetcode-part1 button {
  margin-top: 22px;
  background-color: #000;
  color: #fff;
}
.jetcode-part1-wrapper .jetcode-part1-image,
.jetcode-part1-wrapper .jetcode-part1-image-mobile {
  width: 100%;
  max-height: 60%;
  max-width: 1200px;
  overflow: hidden;
  margin: auto;
}
.jetcode-part1-wrapper .jetcode-part1-icon {
  bottom: 20px;
  font-size: 24px;
  position: absolute;
  left: 50%;
  margin-left: -12px;
  color: #000;
}
@media screen and (max-width: 1124px) {
  .jetcode-part1-wrapper {
    height: 682px;
  }
  .jetcode-part1-wrapper .jetcode-part1 .title-h1 {
    font-size: 48px;
  }
  .jetcode-part1-wrapper .jetcode-part1 .title-content {
    font-size: 32px;
  }
  .jetcode-part1-wrapper .jetcode-part1 button {
    font-size: 16px;
  }
}
@media screen and (max-width: 767px) {
  .jetcode-part1-wrapper {
    min-height: 300px;
    height: auto;
    margin-top: 24px;
  }
  .jetcode-part1-wrapper .jetcode-part1 {
    overflow: hidden;
    padding: 24px 12px;
    margin: auto;
  }
  .jetcode-part1-wrapper .jetcode-part1 .title-h1,
  .jetcode-part1-wrapper .jetcode-part1 .title-h2 {
    display: block;
    font-size: 50px;
    line-height: 1;
    margin-bottom: 0;
  }
  .jetcode-part1-wrapper .jetcode-part1 .title-h2 {
    font-size: 24px;
    margin: 12px auto;
  }
  .jetcode-part1-wrapper .jetcode-part1 .title-content {
    font-size: 1.4em;
    margin-bottom: 28px;
  }
  .jetcode-part1-wrapper .jetcode-part1 button {
    margin-top: 12px;
  }
  .jetcode-part1-wrapper .jetcode-part1-image {
    margin: 24px 0;
  }
}
.jetcode-part2-wrapper {
  width: 100%;
  height: 720px;
  position: relative;
  text-align: center;
  background: #000;
  overflow: hidden;
}
.jetcode-part2-wrapper .home-page {
  padding: 0;
}
.jetcode-part2-wrapper .jetcode-part2 {
  width: 100%;
  position: relative;
  text-align: center;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.jetcode-part2-wrapper .jetcode-part2 > .queue-anim-leaving {
  position: relative !important;
}
.jetcode-part2-wrapper .jetcode-part2 h3 {
  font-size: 40px;
  color: #fff;
  margin-bottom: 24px;
}
.jetcode-part2-wrapper .jetcode-part2 h1 {
  color: #fff;
  font-size: 60px;
}
.jetcode-part2-wrapper .jetcode-part2-image,
.jetcode-part2-wrapper .jetcode-part2-image-mobile {
  position: relative;
  width: 100%;
  max-height: 60%;
  max-width: 1500px;
  overflow: hidden;
  margin: auto;
}
.jetcode-part2-wrapper .jetcode-part2-label {
  position: absolute;
  bottom: 24px;
  left: 0;
  right: 0;
  text-align: center;
  color: #fff;
  font-size: 12px !important;
}
@media screen and (max-width: 1124px) {
  .jetcode-part2-wrapper .jetcode-part2 img {
    max-height: 300px;
  }
}
@media screen and (max-width: 767px) {
  .jetcode-part2-wrapper {
    height: auto;
  }
  .jetcode-part2-wrapper .jetcode-part2 {
    padding: 24px 12px;
  }
  .jetcode-part2-wrapper .jetcode-part2 img {
    max-height: 200px;
  }
  .jetcode-part2-wrapper .jetcode-part2 h3 {
    font-size: 18px;
    color: #fff;
  }
  .jetcode-part2-wrapper .jetcode-part2 h1 {
    color: #FFF;
    font-size: 38px;
  }
  .jetcode-part2-wrapper .jetcode-part2-label {
    bottom: 12px;
  }
}
.jetcode-part3-wrapper {
  width: 100%;
  height: 882px;
  position: relative;
  text-align: center;
  background: #fff;
  overflow: hidden;
}
.jetcode-part3-wrapper .jetcode-part3 {
  width: 100%;
  position: relative;
  text-align: center;
  overflow: hidden;
  padding: 5vh 0 5vh 0;
}
.jetcode-part3-wrapper .jetcode-part3 > .queue-anim-leaving {
  position: relative !important;
}
.jetcode-part3-wrapper .jetcode-part3 h3 {
  font-size: 40px;
  color: #000;
  margin-bottom: 24px;
}
.jetcode-part3-wrapper .jetcode-part3 h1 {
  color: #000;
  font-size: 60px;
}
.jetcode-part3-wrapper .jetcode-part3-image,
.jetcode-part3-wrapper .jetcode-part3-image-mobile {
  position: relative;
  width: 100%;
  max-height: 60%;
  max-width: 1500px;
  overflow: hidden;
  margin: auto;
}
.jetcode-part3-wrapper .jetcode-part3-label {
  position: absolute;
  bottom: 24px;
  left: 0;
  right: 0;
  text-align: center;
  color: #fff;
  font-size: 12px !important;
}
@media screen and (max-width: 1124px) {
  .jetcode-part3-wrapper {
    height: 582px;
  }
  .jetcode-part3-wrapper .jetcode-part3-image,
  .jetcode-part3-wrapper .jetcode-part3-image-mobile {
    max-width: 600px;
  }
  .jetcode-part3-wrapper .jetcode-part3 img {
    max-height: 300px;
  }
}
@media screen and (max-width: 767px) {
  .jetcode-part3-wrapper {
    height: auto;
  }
  .jetcode-part3-wrapper .jetcode-part3 {
    padding: 24px 12px;
  }
  .jetcode-part3-wrapper .jetcode-part3 h3 {
    font-size: 20px;
    margin-bottom: 0;
  }
  .jetcode-part3-wrapper .jetcode-part3 h1 {
    font-size: 28px;
  }
  .jetcode-part3-wrapper .jetcode-part3-label {
    bottom: 12px;
  }
}
.jetcode-part4-wrapper {
  width: 100%;
  height: auto;
  position: relative;
  text-align: center;
  background: #fff;
  overflow: hidden;
}
.jetcode-part4-wrapper .jetcode-part4 {
  width: 100%;
  position: relative;
  text-align: center;
  overflow: hidden;
  padding: 5vh 0 5vh 0;
}
.jetcode-part4-wrapper .jetcode-part4 > .queue-anim-leaving {
  position: relative !important;
}
.jetcode-part4-wrapper .jetcode-part4 h3 {
  font-size: 40px;
  color: #000;
  margin-bottom: 24px;
}
.jetcode-part4-wrapper .jetcode-part4 h1 {
  color: #000;
  font-size: 60px;
}
.jetcode-part4-wrapper .jetcode-part4-image,
.jetcode-part4-wrapper .jetcode-part4-image-mobile {
  position: relative;
  width: 100%;
  max-height: 60%;
  max-width: 1500px;
  overflow: hidden;
  margin: auto;
}
.jetcode-part4-wrapper .jetcode-part4-label {
  position: absolute;
  bottom: 24px;
  left: 0;
  right: 0;
  text-align: center;
  color: #fff;
  font-size: 12px !important;
}
@media screen and (max-width: 1124px) {
  .jetcode-part4-wrapper {
    height: 582px;
  }
  .jetcode-part4-wrapper .jetcode-part4-image,
  .jetcode-part4-wrapper .jetcode-part4-image-mobile {
    max-width: 600px;
  }
  .jetcode-part4-wrapper .jetcode-part4 img {
    max-height: 300px;
  }
}
@media screen and (max-width: 767px) {
  .jetcode-part4-wrapper {
    height: auto;
    background: #fff;
  }
  .jetcode-part4-wrapper .jetcode-part4 {
    padding: 24px 12px;
  }
  .jetcode-part4-wrapper .jetcode-part4 h3 {
    font-size: 20px;
    color: #000;
    margin-bottom: 0;
  }
  .jetcode-part4-wrapper .jetcode-part4 h1 {
    font-size: 28px;
    color: #000;
  }
  .jetcode-part4-wrapper .jetcode-part4-label {
    bottom: 12px;
  }
}
.jetcode-part5-wrapper {
  height: 664px;
  background: #fafafa;
}
.jetcode-part5-wrapper .home-page {
  padding: 36px 0;
}
.jetcode-part5-wrapper .jetcode-part5 {
  height: 100%;
  overflow: hidden;
}
.jetcode-part5-wrapper .jetcode-part5 .title-h1 {
  font-size: 54px;
}
.jetcode-part5-wrapper .jetcode-part5 .title-content {
  text-align: center;
  font-size: 38px;
}
.jetcode-part5-wrapper .jetcode-part5-block-wrapper {
  position: relative;
}
.jetcode-part5-wrapper .jetcode-part5-block-wrapper .jetcode-part5-block {
  display: inline-block;
  padding: 48px 24px;
  vertical-align: top;
}
.jetcode-part5-wrapper .jetcode-part5-block-wrapper .jetcode-part5-block .jetcode-part5-icon {
  display: inline-block;
  width: 15%;
  vertical-align: top;
}
.jetcode-part5-wrapper .jetcode-part5-block-wrapper .jetcode-part5-block .jetcode-part5-text {
  width: 85%;
  display: inline-block;
  padding-left: 8%;
}
.jetcode-part5-wrapper .jetcode-part5-block-wrapper .jetcode-part5-block.clear-both {
  clear: both;
}
@media screen and (max-width: 767px) {
  .jetcode-part5-wrapper {
    height: auto;
  }
  .jetcode-part5-wrapper .jetcode-part5 {
    padding: 24px 12px;
  }
  .jetcode-part5-wrapper .jetcode-part5 .title-wrapper {
    margin: 0 auto 24px;
  }
  .jetcode-part5-wrapper .jetcode-part5 .title-h1 {
    font-size: 32px;
  }
  .jetcode-part5-wrapper .jetcode-part5 .title-content {
    text-align: center;
    font-size: 24px;
  }
  .jetcode-part5-wrapper .jetcode-part5-block-wrapper {
    margin: 20px auto;
    height: auto;
  }
  .jetcode-part5-wrapper .jetcode-part5-block-wrapper .jetcode-part5-block {
    padding: 24px;
  }
  .jetcode-part5-wrapper .jetcode-part5-block-wrapper .jetcode-part5-block .jetcode-part5-title {
    font-size: 20px;
  }
  .jetcode-part5-wrapper .jetcode-part5-block-wrapper .jetcode-part5-block.queue-anim-leaving {
    position: relative !important;
  }
}
.jetcode-part6-wrapper {
  height: 566px;
  background: #fff;
}
.jetcode-part6-wrapper .home-page {
  padding: 24px;
}
.jetcode-part6-wrapper .jetcode-part6 {
  width: 100%;
  height: 100%;
  position: relative;
  text-align: center;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.jetcode-part6-wrapper .jetcode-part6-image {
  background-color: #fff;
  height: 100%;
  width: 45%;
  overflow: hidden;
}
.jetcode-part6-wrapper .jetcode-part6-image div {
  height: 100%;
  width: 100%;
}
.jetcode-part6-wrapper .jetcode-part6-image div img {
  height: 100%;
  width: 100%;
  object-fit: scale-down;
}
.jetcode-part6-wrapper .jetcode-part6-text-wrapper {
  width: 410px;
  text-align: center;
}
.jetcode-part6-wrapper .jetcode-part6-title {
  font-size: 26px;
  color: #000;
}
.jetcode-part6-wrapper .jetcode-part6-content {
  font-size: 42px;
  font-weight: 500;
  line-height: 30px;
  color: #000;
}
.jetcode-part6-wrapper .jetcode-part6-intro {
  margin-top: 16px;
  font-size: 24px;
  text-align: left;
  color: #000;
}
.jetcode-part6-wrapper .jetcode-part6 a {
  display: block;
  margin-top: 24px;
  margin-bottom: 24px;
  background-color: #fff;
  font-size: 20px;
  border: none;
  text-align: center;
  color: #000;
}
@media screen and (max-width: 767px) {
  .jetcode-part6-wrapper {
    height: auto;
  }
  .jetcode-part6-wrapper .home-page {
    padding: 0;
  }
  .jetcode-part6-wrapper .jetcode-part6 {
    padding: 24px 12px;
    overflow: hidden;
    margin: auto;
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: center;
  }
  .jetcode-part6-wrapper .jetcode-part6-image {
    height: auto;
    padding: 12px 24px;
    width: 100%;
    margin-top: 24px;
  }
  .jetcode-part6-wrapper .jetcode-part6-text-wrapper {
    margin: 0;
  }
  .jetcode-part6-wrapper .jetcode-part6-title {
    font-size: 24px;
    line-height: 60px;
  }
  .jetcode-part6-wrapper .jetcode-part6-intro {
    text-align: center;
    font-size: 20px;
    margin-top: 0;
  }
}
.jetcode-part7-wrapper {
  height: 566px;
  background: #fff;
}
.jetcode-part7-wrapper .home-page {
  padding: 24px;
}
.jetcode-part7-wrapper .jetcode-part7 {
  width: 100%;
  height: 100%;
  position: relative;
  text-align: center;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.jetcode-part7-wrapper .jetcode-part7-image {
  background-color: #fff;
  width: 30%;
  height: 100%;
  overflow: hidden;
}
.jetcode-part7-wrapper .jetcode-part7-image div {
  height: 100%;
  width: 100%;
}
.jetcode-part7-wrapper .jetcode-part7-image div img {
  height: 100%;
  width: 100%;
  object-fit: scale-down;
}
.jetcode-part7-wrapper .jetcode-part7-text-wrapper {
  width: 610px;
  text-align: center;
}
.jetcode-part7-wrapper .jetcode-part7-title {
  font-size: 70px;
  color: #000;
}
.jetcode-part7-wrapper .jetcode-part7-content {
  font-size: 42px;
  font-weight: 500;
  line-height: 30px;
  color: #000;
}
.jetcode-part7-wrapper .jetcode-part7-intro {
  margin-top: 16px;
  font-size: 40px;
  text-align: left;
  color: #000;
}
.jetcode-part7-wrapper .jetcode-part7 a {
  display: block;
  margin-top: 24px;
  margin-bottom: 24px;
  background-color: #fff;
  font-size: 20px;
  border: none;
  text-align: center;
  color: #000;
}
@media screen and (max-width: 767px) {
  .jetcode-part7-wrapper {
    height: auto;
  }
  .jetcode-part7-wrapper .home-page {
    padding: 0;
  }
  .jetcode-part7-wrapper .jetcode-part7 {
    overflow: hidden;
    padding: 24px 12px;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .jetcode-part7-wrapper .jetcode-part7-image {
    height: auto;
    width: 100%;
    padding: 12px 24px;
  }
  .jetcode-part7-wrapper .jetcode-part7-title {
    font-size: 50px;
    line-height: 60px;
    margin-bottom: 10px;
  }
  .jetcode-part7-wrapper .jetcode-part7-content {
    font-size: 20px;
    line-height: 20px;
  }
  .jetcode-part7-wrapper .jetcode-part7-intro {
    margin: 12px 0;
    font-size: 24px;
    text-align: center;
  }
}
.jetcode-part8-wrapper {
  height: 460px;
  background: #fafafa;
}
.jetcode-part8-wrapper .home-page {
  padding: 0;
}
.jetcode-part8-wrapper .jetcode-part8 > p {
  text-align: center;
}
.jetcode-part8-wrapper .jetcode-part8-title-h1 {
  margin-bottom: 12px;
}
.jetcode-part8-wrapper .jetcode-part8 button {
  background-color: #000;
  color: #fff;
}
.jetcode-part8-wrapper .jetcode-part8-table-name-block {
  text-align: center;
  color: #666;
  width: 100%;
}
.jetcode-part8-wrapper .jetcode-part8-table-name {
  font-size: 24px;
}
.jetcode-part8-wrapper .jetcode-part8-table-money {
  font-size: 16px;
  margin: 8px 0 16px;
}
.jetcode-part8-wrapper .jetcode-part8-table-content {
  text-align: center;
  color: #666;
}
.jetcode-part8-wrapper .jetcode-part8-table-content-name {
  color: #666;
  text-align: center;
}
.jetcode-part8-wrapper.home-page-wrapper .jetcode-part8-title-wrapper {
  margin: 64px auto;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .jetcode-part8-wrapper {
    height: auto;
    background-color: #fff;
  }
  .jetcode-part8-wrapper .jetcode-part8 {
    padding: 24px 12px;
  }
  .jetcode-part8-wrapper .jetcode-part8-title-wrapper {
    margin: auto auto 24px !important;
  }
  .jetcode-part8-wrapper .jetcode-part8-wrapper {
    padding-bottom: 0;
  }
  .jetcode-part8-wrapper .jetcode-part8-table {
    margin-bottom: 24px;
  }
}
.energy-wrapper {
  padding-top: 64px;
}

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
textarea,
p,
blockquote,
th,
td,
hr,
button,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  margin: 0;
  padding: 0;
}
ul,
ol {
  list-style: none;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
body {
  word-wrap: break-word;
}
.video {
  max-width: 800px;
}
#react-content {
  min-height: 100%;
}
.csar-part1-wrapper {
  height: 782px;
  margin-top: 64px;
  background: #fff;
}
.csar-part1-wrapper .csar-part1 {
  width: 100%;
  height: 100%;
  position: relative;
  text-align: center;
  overflow: hidden;
}
.csar-part1-wrapper .csar-part1 .title-h1,
.csar-part1-wrapper .csar-part1 .title-h2 {
  line-height: 1.5;
  font-size: 90px;
  color: #000;
  display: inline-block;
}
.csar-part1-wrapper .csar-part1 .title-h2 {
  margin-left: 18px;
}
.csar-part1-wrapper .csar-part1 .title-content {
  font-size: 40px;
  color: #000;
}
.csar-part1-wrapper .csar-part1 button {
  margin-top: 22px;
  background-color: #000;
  color: #fff;
}
.csar-part1-wrapper .csar-part1-image,
.csar-part1-wrapper .csar-part1-image-mobile {
  padding: 12px 24px;
  width: 100%;
  max-height: 60%;
  max-width: 1200px;
  overflow: hidden;
  margin: auto;
}
.csar-part1-wrapper .csar-part1-icon {
  bottom: 20px;
  font-size: 24px;
  position: absolute;
  left: 50%;
  margin-left: -12px;
  color: #000;
}
@media screen and (max-width: 1124px) {
  .csar-part1-wrapper {
    height: 682px;
  }
  .csar-part1-wrapper .csar-part1 .title-h1 {
    font-size: 48px;
  }
  .csar-part1-wrapper .csar-part1 .title-content {
    font-size: 32px;
  }
  .csar-part1-wrapper .csar-part1 button {
    font-size: 16px;
  }
}
@media screen and (max-width: 767px) {
  .csar-part1-wrapper {
    min-height: 300px;
    height: auto;
    margin-top: 24px;
  }
  .csar-part1-wrapper .csar-part1 {
    overflow: hidden;
    padding: 24px 12px;
    margin: auto;
  }
  .csar-part1-wrapper .csar-part1 .title-h1,
  .csar-part1-wrapper .csar-part1 .title-h2 {
    display: block;
    font-size: 50px;
    line-height: 1;
    margin-bottom: 0;
  }
  .csar-part1-wrapper .csar-part1 .title-h2 {
    font-size: 24px;
    margin: 12px auto;
  }
  .csar-part1-wrapper .csar-part1 .title-content {
    font-size: 1.4em;
    margin-bottom: 28px;
  }
  .csar-part1-wrapper .csar-part1 button {
    margin-top: 12px;
  }
  .csar-part1-wrapper .csar-part1-image {
    margin: 24px 0;
    padding: 0;
  }
}
.csar-part2-wrapper {
  width: 100%;
  height: auto;
  background-color: #fdfdfd;
  overflow: hidden;
}
.csar-part2-wrapper .csar-part2 {
  position: relative;
  padding: 64px 24px 24px 24px;
}
.csar-part2-wrapper .csar-part2-title-wrapper {
  width: 45%;
  max-width: 450px;
  position: absolute;
  top: 25%;
  right: 44px;
  font-family: 'PingFang SC', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}
.csar-part2-wrapper .csar-part2-title {
  font-size: 24px;
  line-height: 1.5;
  color: #000;
  margin-bottom: 8px;
}
.csar-part2-wrapper .csar-part2-explain {
  color: #333;
  font-size: 20px;
  margin-top: 24px;
}
.csar-part2-wrapper .csar-part2-image {
  overflow: hidden;
  width: 55%;
  max-width: 710px;
  position: relative;
  top: 0;
  right: 24px;
}
@media screen and (max-width: 1124px) {
  .csar-part2-wrapper {
    height: 880px;
  }
  .csar-part2-wrapper .csar-part2 {
    min-height: 500px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .csar-part2-wrapper .csar-part2.home-page {
    padding-top: 1px;
    padding-bottom: 0;
  }
  .csar-part2-wrapper .csar-part2-title-wrapper {
    text-align: center;
    position: static;
    width: 100%;
    margin-top: 64px;
    left: 0;
    top: 0;
  }
  .csar-part2-wrapper .csar-part2-title {
    font-size: 24px;
  }
  .csar-part2-wrapper .csar-part2-explain {
    font-size: 18px;
  }
  .csar-part2-wrapper .csar-part2-image {
    position: static;
    width: 100%;
    top: 0;
    right: 0;
    margin: 32px 0 24px;
  }
}
@media screen and (max-width: 767px) {
  .csar-part2-wrapper {
    border-top: 1px solid #f5f5f5;
    height: auto;
  }
  .csar-part2-wrapper .csar-part2 {
    display: flex;
    flex-wrap: wrap-reverse;
  }
  .csar-part2-wrapper .csar-part2.home-page {
    padding-top: 1px;
    padding-bottom: 0;
  }
  .csar-part2-wrapper .csar-part2-title-wrapper {
    text-align: left;
    position: static;
    width: 100%;
    margin-top: 24px;
    left: 0;
    top: 0;
  }
  .csar-part2-wrapper .csar-part2-title {
    text-align: center;
    font-size: 20px;
  }
  .csar-part2-wrapper .csar-part2-explain {
    text-indent: 2em;
    font-size: 18px;
  }
  .csar-part2-wrapper .csar-part2-image {
    position: static;
    width: 100%;
    top: 0;
    right: 0;
    margin: 32px 0 24px;
  }
}
.csar-part3-wrapper {
  width: 100%;
  height: 682px;
  position: relative;
  text-align: center;
  background: #fff;
  overflow: hidden;
}
.csar-part3-wrapper .csar-part3 {
  width: 100%;
  position: relative;
  text-align: center;
  overflow: hidden;
  padding-top: 5vh;
}
.csar-part3-wrapper .csar-part3-title {
  font-size: 32px;
  color: #000;
  font-weight: 500;
  text-align: center;
}
.csar-part3-wrapper .csar-part3-image1,
.csar-part3-wrapper .csar-part3-image2 {
  width: 48%;
}
.csar-part3-wrapper .csar-part3-image1 img,
.csar-part3-wrapper .csar-part3-image2 img {
  width: 100%;
  box-shadow: 0px 10px 10px #cfcfcf;
}
.csar-part3-wrapper .csar-part3-image1 {
  margin-right: 4%;
}
.csar-part3-wrapper .csar-part3-image-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 24px;
}
.csar-part3-wrapper .csar-part3-content {
  font-size: 20px;
  text-align: left;
  color: #000;
  margin-top: 24px;
  padding: 24px;
}
@media screen and (max-width: 1124px) {
  .csar-part3-wrapper {
    height: 582px;
  }
  .csar-part3-wrapper .csar-part3 img {
    max-height: 300px;
  }
}
@media screen and (max-width: 767px) {
  .csar-part3-wrapper {
    height: auto;
  }
  .csar-part3-wrapper .csar-part3 {
    padding: 48px 12px;
  }
  .csar-part3-wrapper .csar-part3-content {
    margin-top: 12px;
    padding-bottom: 0;
    font-size: 18px;
  }
}
.csar-part4-wrapper {
  width: 100%;
  height: 642px;
  position: relative;
  text-align: center;
  background: #000;
  overflow: hidden;
}
.csar-part4-wrapper .csar-part4 {
  width: 100%;
  position: relative;
  text-align: center;
  overflow: hidden;
  padding: 24px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.csar-part4-wrapper .csar-part4-title {
  font-size: 32px;
  color: #fff;
  font-weight: 500;
  text-align: center;
}
.csar-part4-wrapper .csar-part4-block-title {
  font-size: 22px;
  color: #fff;
  font-weight: 500;
  text-align: center;
}
.csar-part4-wrapper .csar-part4-image-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 24px;
}
.csar-part4-wrapper .csar-part4 .content-title {
  font-weight: 700;
}
.csar-part4-wrapper .csar-part4-content {
  font-size: 20px;
  text-align: left;
  color: #fff;
  margin-top: 24px;
  padding: 24px;
}
@media screen and (max-width: 1124px) {
  .csar-part4-wrapper .csar-part4 img {
    max-height: 300px;
  }
}
@media screen and (max-width: 767px) {
  .csar-part4-wrapper {
    height: auto;
  }
  .csar-part4-wrapper .csar-part4 {
    padding: 48px 12px;
    padding-bottom: 0;
  }
  .csar-part4-wrapper .csar-part4-content {
    margin-top: 0;
    font-size: 18px;
  }
}
.csar-part5-wrapper {
  height: 566px;
  background: #000;
}
.csar-part5-wrapper .home-page {
  padding: 0;
}
.csar-part5-wrapper .csar-part5 {
  width: 100%;
  height: 100%;
  position: relative;
  text-align: center;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.csar-part5-wrapper .csar-part5-image {
  background-color: #000;
  max-height: 100%;
  width: 55%;
  overflow: hidden;
}
.csar-part5-wrapper .csar-part5-image div {
  height: 100%;
  width: 100%;
}
.csar-part5-wrapper .csar-part5-image div img {
  height: 100%;
  width: 100%;
  object-fit: scale-down;
}
.csar-part5-wrapper .csar-part5-text-wrapper {
  width: 410px;
  text-align: center;
}
.csar-part5-wrapper .csar-part5-title {
  font-size: 26px;
  color: #fff;
}
.csar-part5-wrapper .csar-part5-content {
  font-size: 42px;
  font-weight: 500;
  line-height: 30px;
  color: #fff;
}
.csar-part5-wrapper .csar-part5-intro {
  margin-top: 16px;
  font-size: 24px;
  text-align: left;
  color: #fff;
}
.csar-part5-wrapper .csar-part5 a {
  display: block;
  margin-top: 24px;
  margin-bottom: 24px;
  background-color: #fff;
  font-size: 20px;
  border: none;
  text-align: center;
  color: #000;
}
@media screen and (max-width: 767px) {
  .csar-part5-wrapper {
    height: auto;
  }
  .csar-part5-wrapper .home-page {
    padding: 0;
  }
  .csar-part5-wrapper .csar-part5 {
    overflow: hidden;
    padding: 0 12px 64px 12px;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: inherit;
  }
  .csar-part5-wrapper .csar-part5-image {
    height: auto;
    width: 100%;
  }
  .csar-part5-wrapper .csar-part5-text-wrapper {
    padding: 24px;
    text-align: left;
  }
  .csar-part5-wrapper .csar-part5-title {
    font-size: 24px;
    line-height: 60px;
    margin-bottom: 10px;
  }
  .csar-part5-wrapper .csar-part5-content {
    font-size: 20px;
    line-height: 20px;
  }
  .csar-part5-wrapper .csar-part5-intro {
    text-align: left;
    font-size: 20px;
  }
}
.csar-part6-wrapper {
  width: 100%;
  height: 582px;
  position: relative;
  background: #fff;
  overflow: hidden;
  background-image: url("https://website-public-resource-for-all-user.oss-cn-shanghai.aliyuncs.com/ai-website/public/csar/bg.png");
  background-size: cover;
  background-position: center;
}
.csar-part6-wrapper .csar-part6 {
  width: 100%;
  position: relative;
  text-align: left;
  overflow: hidden;
  padding: 24px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.csar-part6-wrapper .csar-part6-title {
  font-size: 32px;
  color: #fff;
  font-weight: 500;
  width: 100%;
  text-align: center;
}
.csar-part6-wrapper .csar-part6-block {
  padding: 12px 48px;
}
.csar-part6-wrapper .csar-part6-block-wrapper {
  margin-top: 60px;
}
.csar-part6-wrapper .csar-part6-block-title {
  font-size: 22px;
  color: #fff;
  font-weight: 500;
  text-align: left;
}
.csar-part6-wrapper .csar-part6-block-text {
  font-size: 18px;
  margin-bottom: 36px;
}
.csar-part6-wrapper .csar-part6-block-header {
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 12px;
  color: #fff;
}
@media screen and (max-width: 1124px) {
  .csar-part6-wrapper {
    height: 782px;
  }
  .csar-part6-wrapper .csar-part6-block-text {
    font-size: 18px;
  }
}
@media screen and (max-width: 767px) {
  .csar-part6-wrapper {
    height: auto;
  }
  .csar-part6-wrapper .csar-part6 {
    padding-bottom: 36px;
  }
  .csar-part6-wrapper .csar-part6-block-wrapper {
    margin-top: 24px;
  }
  .csar-part6-wrapper .csar-part6-block {
    padding: 12px;
  }
}
.csar-part7-wrapper {
  width: 100%;
  height: auto;
  position: relative;
  text-align: center;
  background: #fff;
  overflow: hidden;
}
.csar-part7-wrapper .csar-part7 {
  width: 100%;
  position: relative;
  text-align: center;
  overflow: hidden;
  height: auto;
  padding: 24px;
}
.csar-part7-wrapper .csar-part7-title {
  font-size: 32px;
  color: #000;
  font-weight: 500;
  text-align: center;
  margin-bottom: 60px;
}
.csar-part7-wrapper .csar-part7-block-title {
  font-size: 22px;
  color: #000;
  font-weight: 500;
  text-align: center;
}
.csar-part7-wrapper .csar-part7-block-scene {
  font-size: 20px;
  margin-bottom: 6px;
}
.csar-part7-wrapper .csar-part7-image-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 24px;
}
@media screen and (max-width: 1124px) {
  .csar-part7-wrapper .csar-part7 img {
    max-height: 300px;
  }
  .csar-part7-wrapper .csar-part7-block-scene {
    font-size: 18px;
  }
}
@media screen and (max-width: 767px) {
  .csar-part7-wrapper .csar-part7 {
    padding-bottom: 36px;
  }
}
.csar-part8-wrapper {
  height: 460px;
  background: #fafafa;
}
.csar-part8-wrapper .home-page {
  padding: 0;
}
.csar-part8-wrapper .csar-part8 > p {
  text-align: center;
}
.csar-part8-wrapper .csar-part8-title-h1 {
  margin-bottom: 12px;
}
.csar-part8-wrapper .csar-part8 button {
  background-color: #000;
  color: #fff;
}
.csar-part8-wrapper .csar-part8-table-name-block {
  text-align: center;
  color: #666;
  width: 100%;
}
.csar-part8-wrapper .csar-part8-table-name {
  font-size: 24px;
}
.csar-part8-wrapper .csar-part8-table-money {
  font-size: 16px;
  margin: 8px 0 16px;
}
.csar-part8-wrapper .csar-part8-table-content {
  text-align: center;
  color: #666;
}
.csar-part8-wrapper .csar-part8-table-content-name {
  color: #666;
  text-align: center;
}
.csar-part8-wrapper.home-page-wrapper .csar-part8-title-wrapper {
  margin: 64px auto;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .csar-part8-wrapper {
    height: auto;
    background-color: #fff;
  }
  .csar-part8-wrapper .csar-part8 {
    padding: 24px 12px;
  }
  .csar-part8-wrapper .csar-part8-title-wrapper {
    margin: auto auto 24px !important;
  }
  .csar-part8-wrapper .csar-part8-wrapper {
    padding-bottom: 0;
  }
  .csar-part8-wrapper .csar-part8-table {
    margin-bottom: 24px;
  }
}
.energy-wrapper {
  padding-top: 64px;
}

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
textarea,
p,
blockquote,
th,
td,
hr,
button,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  margin: 0;
  padding: 0;
}
ul,
ol {
  list-style: none;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
body {
  word-wrap: break-word;
}
.video {
  max-width: 800px;
}
#react-content {
  min-height: 100%;
}
.security-part1-wrapper {
  height: 782px;
  margin-top: 64px;
  background: #fff;
}
.security-part1-wrapper .security-part1 {
  width: 100%;
  height: 100%;
  position: relative;
  text-align: center;
  overflow: hidden;
}
.security-part1-wrapper .security-part1 .title-h1 {
  line-height: 1.5;
  font-size: 90px;
  color: #000;
}
.security-part1-wrapper .security-part1 .title-content {
  font-size: 40px;
  color: #000;
}
.security-part1-wrapper .security-part1 button {
  margin-top: 22px;
  background-color: #000;
  color: #fff;
}
.security-part1-wrapper .security-part1-image,
.security-part1-wrapper .security-part1-image-mobile {
  width: 100%;
  max-height: 500px;
  max-width: 1200px;
  overflow: hidden;
  margin: auto;
}
.security-part1-wrapper .security-part1-image div,
.security-part1-wrapper .security-part1-image-mobile div,
.security-part1-wrapper .security-part1-image img,
.security-part1-wrapper .security-part1-image-mobile img {
  height: 100%;
  width: 100%;
  max-height: 500px;
}
.security-part1-wrapper .security-part1-icon {
  bottom: 20px;
  font-size: 24px;
  position: absolute;
  left: 50%;
  margin-left: -12px;
  color: #000;
}
@media screen and (max-width: 1124px) {
  .security-part1-wrapper {
    height: 682px;
  }
  .security-part1-wrapper .security-part1 .title-h1 {
    font-size: 48px;
  }
  .security-part1-wrapper .security-part1 .title-content {
    font-size: 32px;
  }
  .security-part1-wrapper .security-part1 button {
    font-size: 16px;
  }
}
@media screen and (max-width: 767px) {
  .security-part1-wrapper {
    min-height: 300px;
    height: auto;
  }
  .security-part1-wrapper .security-part1 {
    overflow: hidden;
    width: 90%;
    margin: auto;
  }
  .security-part1-wrapper .security-part1 .title-h1 {
    font-size: 2em;
    line-height: 1.2;
  }
  .security-part1-wrapper .security-part1 .title-content {
    font-size: 20px;
    margin: 12px auto;
  }
  .security-part1-wrapper .security-part1 button {
    margin-top: 12px;
  }
}
.security-part2-wrapper {
  width: 100%;
  height: auto;
  position: relative;
  text-align: center;
  background: #fff;
  overflow: hidden;
}
.security-part2-wrapper .security-part2 {
  width: 100%;
  position: relative;
  text-align: center;
  overflow: hidden;
  padding: 24px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.security-part2-wrapper .security-part2-title {
  font-size: 32px;
  color: #000;
  font-weight: 500;
  text-align: center;
}
.security-part2-wrapper .security-part2-block-wrapper {
  width: 100%;
}
.security-part2-wrapper .security-part2-block-title {
  font-size: 22px;
  color: #000;
  font-weight: 500;
  text-align: center;
}
.security-part2-wrapper .security-part2-block-content {
  padding: 12px;
  margin-top: 24px;
  margin-bottom: 24px;
  font-size: 16px;
  color: #000;
  text-align: center;
  height: 96px;
}
.security-part2-wrapper .security-part2-image-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 24px;
}
.security-part2-wrapper .security-part2 .content-title {
  font-weight: 700;
}
.security-part2-wrapper .security-part2-content {
  font-size: 20px;
  text-align: left;
  color: #000;
  margin-top: 24px;
  padding: 24px;
}
@media screen and (max-width: 1124px) {
  .security-part2-wrapper .security-part2 img {
    max-height: 300px;
  }
}
@media screen and (max-width: 767px) {
  .security-part2-wrapper {
    height: auto;
  }
  .security-part2-wrapper .security-part2 {
    padding: 48px 12px;
    padding-bottom: 12px;
  }
  .security-part2-wrapper .security-part2-block-content {
    margin: 8px 0;
    padding: 0 6px;
  }
}
.security-part3-wrapper {
  height: auto;
  background: #fff;
}
.security-part3-wrapper .title-wrapper > h1,
.security-part3-wrapper .home-page-wrapper > h1 {
  color: #000;
}
.security-part3-wrapper .security-part3 {
  height: 100%;
  overflow: hidden;
  padding: 24px;
}
.security-part3-wrapper .security-part3 .title-content {
  text-align: center;
}
.security-part3-wrapper .security-part3-block-wrapper {
  position: relative;
}
.security-part3-wrapper .security-part3-block-wrapper .security-part3-block {
  display: inline-block;
  padding: 48px 24px;
  vertical-align: top;
}
.security-part3-wrapper .security-part3-block-wrapper .security-part3-block .security-part3-icon {
  display: inline-block;
  width: 15%;
  vertical-align: top;
}
.security-part3-wrapper .security-part3-block-wrapper .security-part3-block .security-part3-text {
  width: 85%;
  display: inline-block;
  padding-left: 8%;
}
.security-part3-wrapper .security-part3-block-wrapper .security-part3-block.clear-both {
  clear: both;
}
@media screen and (max-width: 767px) {
  .security-part3-wrapper {
    height: auto;
  }
  .security-part3-wrapper .security-part3 .title-wrapper {
    margin-bottom: 24px;
  }
  .security-part3-wrapper .security-part3-block-wrapper {
    height: auto;
  }
  .security-part3-wrapper .security-part3-block-wrapper .security-part3-block {
    padding: 24px;
  }
  .security-part3-wrapper .security-part3-block-wrapper .security-part3-block .security-part3-title {
    font-size: 20px;
  }
  .security-part3-wrapper .security-part3-block-wrapper .security-part3-block.queue-anim-leaving {
    position: relative !important;
  }
}
.security-part4-wrapper {
  height: 566px;
  background: #fff;
}
.security-part4-wrapper .home-page {
  padding: 0;
}
.security-part4-wrapper .security-part4 {
  width: 100%;
  height: 100%;
  position: relative;
  text-align: center;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.security-part4-wrapper .security-part4-image {
  background-color: #fff;
  max-height: 100%;
  width: 55%;
  overflow: hidden;
}
.security-part4-wrapper .security-part4-image div {
  height: 100%;
  width: 100%;
}
.security-part4-wrapper .security-part4-image div img {
  height: 100%;
  width: 100%;
  object-fit: scale-down;
}
.security-part4-wrapper .security-part4-text-wrapper {
  width: 410px;
  text-align: center;
}
.security-part4-wrapper .security-part4-title {
  font-size: 26px;
  color: #000;
}
.security-part4-wrapper .security-part4-content {
  font-size: 42px;
  font-weight: 500;
  line-height: 30px;
  color: #000;
}
.security-part4-wrapper .security-part4-intro {
  margin-top: 16px;
  font-size: 24px;
  text-align: left;
  color: #000;
}
.security-part4-wrapper .security-part4 a {
  display: block;
  margin-top: 24px;
  margin-bottom: 24px;
  background-color: #fff;
  font-size: 20px;
  border: none;
  text-align: center;
  color: #000;
}
@media screen and (max-width: 767px) {
  .security-part4-wrapper {
    height: auto;
  }
  .security-part4-wrapper .home-page {
    padding: 0;
  }
  .security-part4-wrapper .security-part4 {
    overflow: hidden;
    padding: 24px 12px;
    padding-top: 0;
    margin: auto;
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: center;
    align-items: inherit;
  }
  .security-part4-wrapper .security-part4-image {
    height: auto;
    width: 100%;
    padding: 12px 24px;
    margin-top: 12px;
  }
  .security-part4-wrapper .security-part4-text-wrapper {
    margin-top: 24px;
    margin-left: 0px;
  }
  .security-part4-wrapper .security-part4-title {
    font-size: 34px;
    line-height: 60px;
  }
  .security-part4-wrapper .security-part4-explain {
    line-height: 40px;
  }
  .security-part4-wrapper .security-part4-content {
    font-size: 20px;
    line-height: 20px;
  }
  .security-part4-wrapper .security-part4-intro {
    text-align: center;
    font-size: 20px;
  }
}
.security-part5-wrapper {
  height: 566px;
  background: #fff;
}
.security-part5-wrapper .home-page {
  padding: 0;
}
.security-part5-wrapper .security-part5 {
  width: 100%;
  height: 100%;
  position: relative;
  text-align: center;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.security-part5-wrapper .security-part5-image {
  background-color: #fff;
  width: 30%;
  height: 100%;
  overflow: hidden;
}
.security-part5-wrapper .security-part5-image div {
  height: 100%;
  width: 100%;
}
.security-part5-wrapper .security-part5-image div img {
  height: 100%;
  width: 100%;
  object-fit: scale-down;
}
.security-part5-wrapper .security-part5-text-wrapper {
  width: 360px;
  text-align: center;
}
.security-part5-wrapper .security-part5-title {
  color: #000;
}
.security-part5-wrapper .security-part5-content {
  font-size: 42px;
  font-weight: 500;
  line-height: 30px;
  color: #000;
}
.security-part5-wrapper .security-part5-intro {
  margin-top: 16px;
  font-size: 24px;
  text-align: left;
  color: #000;
}
.security-part5-wrapper .security-part5 a {
  display: block;
  margin-top: 24px;
  margin-bottom: 24px;
  background-color: #fff;
  font-size: 20px;
  border: none;
  text-align: center;
  color: #000;
}
@media screen and (max-width: 767px) {
  .security-part5-wrapper {
    height: auto;
  }
  .security-part5-wrapper .home-page {
    padding: 0;
  }
  .security-part5-wrapper .security-part5 {
    overflow: hidden;
    padding: 24px 12px;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .security-part5-wrapper .security-part5-image {
    width: 100%;
    padding: 12px 24px;
    margin-top: 12px;
    height: auto;
  }
  .security-part5-wrapper .security-part5-text-wrapper {
    margin-top: 24px;
    margin-left: 0px;
  }
  .security-part5-wrapper .security-part5-title {
    font-size: 34px;
    line-height: 60px;
  }
  .security-part5-wrapper .security-part5-content {
    font-size: 20px;
    line-height: 20px;
  }
  .security-part5-wrapper .security-part5-intro {
    font-size: 20px;
    text-align: center;
  }
}
.security-part8-wrapper {
  height: 460px;
  background: #fafafa;
}
.security-part8-wrapper .home-page {
  padding: 0;
}
.security-part8-wrapper .security-part8 > p {
  text-align: center;
}
.security-part8-wrapper .security-part8-title-h1 {
  margin-bottom: 12px;
}
.security-part8-wrapper .security-part8 button {
  background-color: #000;
  color: #fff;
}
.security-part8-wrapper .security-part8-table-name-block {
  text-align: center;
  color: #666;
  width: 100%;
}
.security-part8-wrapper .security-part8-table-name {
  font-size: 24px;
}
.security-part8-wrapper .security-part8-table-money {
  font-size: 16px;
  margin: 8px 0 16px;
}
.security-part8-wrapper .security-part8-table-content {
  text-align: center;
  color: #666;
}
.security-part8-wrapper .security-part8-table-content-name {
  color: #666;
  text-align: center;
}
.security-part8-wrapper.home-page-wrapper .security-part8-title-wrapper {
  margin: 64px auto;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .security-part8-wrapper {
    height: auto;
    background-color: #fff;
  }
  .security-part8-wrapper .security-part8 {
    padding: 24px 12px;
    padding-bottom: 64px;
  }
  .security-part8-wrapper .security-part8-title-wrapper {
    margin: auto auto 24px !important;
  }
  .security-part8-wrapper .security-part8-wrapper {
    padding-bottom: 0;
  }
  .security-part8-wrapper .security-part8-table {
    margin-bottom: 24px;
  }
}
.energy-wrapper {
  padding-top: 64px;
}

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
textarea,
p,
blockquote,
th,
td,
hr,
button,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  margin: 0;
  padding: 0;
}
ul,
ol {
  list-style: none;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.nuclear-part1-wrapper {
  width: 100%;
  height: 100vh;
  position: relative;
  text-align: center;
  overflow: hidden;
  background-image: url("https://website-public-resource-for-all-user.oss-cn-shanghai.aliyuncs.com/ai-website/public/energy-1.png");
  background-size: cover;
  background-position: center;
}
.nuclear-part1-wrapper .nuclear-part1 {
  width: 100%;
  height: 100%;
  position: relative;
  text-align: center;
  overflow: hidden;
  padding: 5vh 0;
}
.nuclear-part1-wrapper .nuclear-part1 .title {
  margin-top: 5%;
  font-size: 90px;
  color: #fff;
  line-height: 1;
  margin-bottom: 12px;
}
.nuclear-part1-wrapper .nuclear-part1 .sub-title {
  font-size: 60px;
  line-height: 58px;
  color: #fff;
  font-weight: 500;
}
.nuclear-part1-wrapper .nuclear-part1 .title-content {
  font-size: 34px;
  color: #fff;
}
.nuclear-part1-wrapper .nuclear-part1 button {
  margin-top: 16px;
  background-color: #000;
  border: none;
  color: #fff;
}
.nuclear-part1-wrapper .nuclear-part1-image,
.nuclear-part1-wrapper .nuclear-part1-image-mobile {
  width: 100%;
  max-height: 60%;
  max-width: 1200px;
  overflow: hidden;
  margin: auto;
}
.nuclear-part1-wrapper .nuclear-part1-icon {
  bottom: 20px;
  font-size: 24px;
  position: absolute;
  left: 50%;
  margin-left: -12px;
  color: #000;
}
@media screen and (max-width: 1124px) {
  .nuclear-part1-wrapper {
    height: 500px;
  }
  .nuclear-part1-wrapper .nuclear-part1 {
    overflow: hidden;
    width: 90%;
    margin: auto;
  }
  .nuclear-part1-wrapper .nuclear-part1 .title-h1 {
    font-size: 60px;
    line-height: 60px;
    color: #ae285c;
    margin-bottom: 40px;
  }
  .nuclear-part1-wrapper .nuclear-part1 .title-content {
    font-size: 20px;
  }
  .nuclear-part1-wrapper .nuclear-part1 .sub-title {
    font-size: 24px;
    margin-bottom: 16px;
  }
}
@media screen and (max-width: 735px) {
  .nuclear-part1-wrapper .nuclear-part1 .title {
    margin-top: 64px;
    font-size: 60px;
    line-height: 60px;
  }
  .nuclear-part1-wrapper .nuclear-part1 .title-content {
    font-size: 20px;
  }
  .nuclear-part1-wrapper .nuclear-part1 .sub-title {
    font-size: 24px;
    margin-bottom: 16px;
    line-height: 1;
  }
}
.nuclear-part2-wrapper {
  background: #fff;
}
.nuclear-part2-wrapper .title-wrapper {
  margin: 64px auto;
  text-align: center;
}
.nuclear-part2-wrapper .title-wrapper h1 {
  color: #000;
  font-size: 48px;
}
.nuclear-part2-wrapper .nuclear-part2 {
  width: 100%;
  height: 80%;
  max-width: 1200px;
  position: relative;
  text-align: center;
  overflow: hidden;
  display: flex;
  justify-content: center;
  padding: 0 0 10vh 0;
}
.nuclear-part2-wrapper .nuclear-part2-text-wrapper {
  text-align: left;
  padding-left: 48px;
}
.nuclear-part2-wrapper .nuclear-part2-title {
  font-size: 22px;
  color: #000;
}
.nuclear-part2-wrapper .nuclear-part2-title-bottom {
  padding-top: 48px;
}
.nuclear-part2-wrapper .nuclear-part2-content {
  margin-bottom: 24px;
}
.nuclear-part2-wrapper .nuclear-part2-content::before {
  content: '';
  display: inline-block;
  width: 10px;
  height: 10px;
  background: #000;
  border-radius: 50%;
  margin-bottom: 4px;
  margin-right: 6px;
}
.nuclear-part2-wrapper .nuclear-part2-content,
.nuclear-part2-wrapper .nuclear-part2-intro {
  font-size: 20px;
  color: #000;
}
.nuclear-part2-wrapper .nuclear-part2-intro {
  line-height: 36px;
}
.nuclear-part2-wrapper .nuclear-part2-image,
.nuclear-part2-wrapper .nuclear-part2-image-mobile {
  width: 100%;
  max-height: 50%;
  max-width: 800px;
  overflow: hidden;
  margin: auto;
}
@media screen and (max-width: 767px) {
  .nuclear-part2-wrapper {
    min-height: 350px;
    height: auto;
  }
  .nuclear-part2-wrapper .nuclear-part2 {
    overflow: hidden;
    width: 90%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
  }
  .nuclear-part2-wrapper .nuclear-part2-text-wrapper {
    margin: auto;
    margin-top: 48px;
  }
  .nuclear-part2-wrapper .nuclear-part2-title-bottom {
    padding-top: 12px;
  }
}
.nuclear-part3-wrapper {
  width: 100%;
  position: relative;
  text-align: center;
  background: #FFF;
  overflow: hidden;
}
.nuclear-part3-wrapper .nuclear-part3 {
  width: 100%;
  position: relative;
  text-align: center;
  overflow: hidden;
  padding: 5vh 0 5vh 0;
}
.nuclear-part3-wrapper .nuclear-part3 > .queue-anim-leaving {
  position: relative !important;
}
.nuclear-part3-wrapper .nuclear-part3 h1 {
  color: #000;
  font-size: 48px;
}
.nuclear-part3-wrapper .nuclear-part3-image,
.nuclear-part3-wrapper .nuclear-part3-image-mobile {
  width: 100%;
  max-height: 60%;
  max-width: 1500px;
  overflow: hidden;
  margin: auto;
}
@media screen and (max-width: 767px) {
  .nuclear-part3-wrapper {
    height: auto;
  }
  .nuclear-part3-wrapper .nuclear-part3 h1 {
    font-size: 32px;
  }
}

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
textarea,
p,
blockquote,
th,
td,
hr,
button,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  margin: 0;
  padding: 0;
}
ul,
ol {
  list-style: none;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
body {
  word-wrap: break-word;
}
/* .content-wrapper > .tween-one-leaving,
.queue-anim-leaving {
  // position: absolute !important;
  // width: 100%;
} */
.video {
  max-width: 800px;
}
#react-content {
  min-height: 100%;
}
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
::-webkit-scrollbar-thumb {
  background-color: #c2c2c2;
  background-clip: padding-box;
  min-height: 28px;
}

